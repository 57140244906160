import { Divider, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { paletteObject } from "../../../theme/foundations/palette";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { formatCurrency } from "../../../infrastructure/utils/FormatUtils";
import { i18n } from "../../../translate/i18n";

type Props = { order: OrderModel; isFullWidth?: boolean };

export default function TotalSummary({ order, isFullWidth = false }: Props) {
	const renderAmountPair = (
		label:
			| "SubTotalAmount"
			| "PromotionCode"
			| "DiscountAmount"
			| "FeeAmount"
			| "FreightAmount",
		value: string
	): JSX.Element => {
		return (
			<Stack direction="row" justifyContent="space-between">
				<CustomTypography variant="default" color="blueish20">
					{i18n.t(`orderTotal.${label}`).toString()}
				</CustomTypography>
				<CustomTypography variant="default" color="blueish20" isBold>
					{label === "DiscountAmount" && "-"}
					{value}
				</CustomTypography>
			</Stack>
		);
	};

	return (
		<Stack
			direction="column"
			spacing={1}
			sx={{ width: "100%", ...(!isFullWidth && { maxWidth: "320px" }) }}
		>
			{order.subTotalAmount > 0 &&
				renderAmountPair(
					"SubTotalAmount",
					formatCurrency(order.subTotalAmount)
				)}
			{order.promotion?.code &&
				renderAmountPair("PromotionCode", order.promotion.code)}
			{order.discountAmount > 0 &&
				renderAmountPair(
					"DiscountAmount",
					formatCurrency(order.discountAmount)
				)}
			{order.feeAmount > 0 &&
				renderAmountPair("FeeAmount", formatCurrency(order.feeAmount))}
			{order.freightAmount > 0 &&
				renderAmountPair("FreightAmount", formatCurrency(order.freightAmount))}
			<Divider
				sx={{
					borderColor: paletteObject.light.main,
					borderStyle: "dashed",
				}}
			/>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<CustomTypography
					variant="default"
					color="blueish80"
					sx={{ fontSize: "16px", fontWeight: 500 }}
				>
					{i18n.t(`orderTotal.Total`).toString()}
				</CustomTypography>
				<CustomTypography variant="secondaryTitle" color="blueish80" isBold>
					{formatCurrency(order.totalAmount)}
				</CustomTypography>
			</Stack>
		</Stack>
	);
}
