import React, { Dispatch, SetStateAction } from "react";
import SellerAutocompleteService from "./SellerAutocompleteService";
import CustomAutocomplete from "../../MUICustomComponents/CustomAutocomplete";
import { i18n } from "../../translate/i18n";
import { AutocompleteSellerView } from "../../domain/views/dealers/AutocompleteSellerView";

type Props = {
	sellerCode: string | null;
	currentSeller: AutocompleteSellerView | null;
	setCurrentSeller: Dispatch<SetStateAction<AutocompleteSellerView | null>>;
	handleAutocompleteChange: (seller: AutocompleteSellerView | null) => void;
	id?: string;
	error?: boolean;
	helperText?: string[];
	defaultOption?: AutocompleteSellerView;
	hideLabel?: boolean;
	onlyActiveSellers?: boolean;
};

export default function SellerAutocomplete({
	sellerCode,
	currentSeller,
	setCurrentSeller,
	handleAutocompleteChange,
	id = "seller",
	error = false,
	helperText = [],
	defaultOption,
	hideLabel = false,
	onlyActiveSellers = false,
}: Props) {
	const {
		sellerList,
		currentSellerInput,
		setCurrentSellerInput,
		// Functions
		filterOptions,
		getOptionLabel,
		isOptionEqualToValue,
	} = SellerAutocompleteService(
		sellerCode,
		setCurrentSeller,
		onlyActiveSellers,
		defaultOption
	);

	return (
		<CustomAutocomplete
			id={id}
			{...(!hideLabel && { renderInputLabel: i18n.t("orderList.SellerLabel") })}
			placeholder={i18n.t("orderList.SellerLabel")}
			options={sellerList}
			value={currentSeller}
			onSelectChange={handleAutocompleteChange}
			inputValue={currentSellerInput}
			onInputChange={setCurrentSellerInput}
			filterOptions={filterOptions}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={isOptionEqualToValue}
			error={error}
			helperText={helperText}
			sxProps={{ flex: 1, marginBottom: "-3px" }}
		/>
	);
}
