import React, { ChangeEvent, useEffect, useState } from "react";
import GeneralCatalog from "./GeneralCatalog";
import { SelectChangeEvent, Stack } from "@mui/material";
import GeneralOrder from "./GeneralOrder";
import { RetailerConfiguration } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/RetailerConfiguration";
import RetailerUpdateButton from "../components/RetailerUpdateButton";
import { GeneralConfig } from "../types/GeneralConfig";
import { LoriOrderCodeType } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/LoriOrderCodeType";
import { handleGenericChange } from "../../../../infrastructure/utils/handleGenericChange";
import { ShowOrderCodeType } from "../../../../domain/enums/ShowOrderCodeType";
import { CancellationReason } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/CancellationReason";
import { getDefaultCancellationReasons } from "./utils/getDefaultCancellationReasons";

type Props = {
	retailerConfigurations: RetailerConfiguration;
	isLoadingUpdate: boolean;
	updateRetailerConfig: (configPart: GeneralConfig) => Promise<void>;
};

export default function GeneralConfigurations({
	retailerConfigurations,
	isLoadingUpdate,
	updateRetailerConfig,
}: Props) {
	const [isDefaultReasons, setIsDefaultReasons] = useState<boolean>(false);
	const [generalConfig, setGeneralConfig] = useState<GeneralConfig>({
		// General - Catalog
		minStock: retailerConfigurations.minStock || 0,
		isSinglePrice: retailerConfigurations.isSinglePrice,
		enableWholesalePrice: retailerConfigurations.enableWholesalePrice,
		// General - Order
		loriOrderCodeType:
			retailerConfigurations.loriOrderCodeType ||
			LoriOrderCodeType.AlphaNumeric,
		orderPrefix: retailerConfigurations.orderPrefix || "PD",
		loriOrderCodeOffset: retailerConfigurations.loriOrderCodeOffset || 0,
		showOrderCodeType:
			retailerConfigurations.showOrderCodeType || ShowOrderCodeType.ExternalId,
		returnExchangedSystem: retailerConfigurations.returnExchangedSystem,
		enableChangeSeller: retailerConfigurations.enableChangeSeller,
		enableOrderPicking: retailerConfigurations.enableOrderPicking,
		cancellationReasons:
			retailerConfigurations.cancellationReasons &&
			retailerConfigurations.cancellationReasons.length > 0
				? retailerConfigurations.cancellationReasons
				: getDefaultCancellationReasons(),
	});

	useEffect(() => {
		if (
			!retailerConfigurations.cancellationReasons ||
			retailerConfigurations.cancellationReasons.length <= 0
		)
			setIsDefaultReasons(true);
	}, []);

	const handleChange = (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => {
		handleGenericChange<GeneralConfig>(setGeneralConfig, event, checked);
	};

	const resetCancellationReasons = () => {
		setIsDefaultReasons(true);
		const defaultReasons = getDefaultCancellationReasons();
		setGeneralConfig((prevState) => ({
			...prevState,
			cancellationReasons: defaultReasons,
		}));
	};

	const sortCancellationReasons = (reasons: CancellationReason[]) => {
		setIsDefaultReasons(false);
		const sortedReasons = reasons.map((reason, index) => ({
			...reason,
			sortOrder: index + 1,
		}));
		setGeneralConfig((prevState) => ({
			...prevState,
			cancellationReasons: sortedReasons,
		}));
	};

	const addNewReason = (inputValue: string, index: number): void => {
		setIsDefaultReasons(false);
		const updatedReasons: CancellationReason[] = [
			...generalConfig.cancellationReasons,
			{ reason: inputValue, sortOrder: index + 1 },
		];
		setGeneralConfig((prevState) => ({
			...prevState,
			cancellationReasons: updatedReasons,
		}));
	};

	const updateReason = (value: string, indexToUpdate: number): void => {
		setIsDefaultReasons(false);
		const updatedReasons: CancellationReason[] =
			generalConfig.cancellationReasons.map((reason, index) =>
				index === indexToUpdate
					? { reason: value, sortOrder: indexToUpdate + 1 }
					: reason
			);
		setGeneralConfig((prevState) => ({
			...prevState,
			cancellationReasons: updatedReasons,
		}));
	};

	const removeReason = (reasonToRemove: CancellationReason): void => {
		const filteredData = generalConfig.cancellationReasons.filter(
			(reason) => reason.reason !== reasonToRemove.reason
		);
		sortCancellationReasons(filteredData);
	};

	const handleSubmit = (): void => {
		const newConfig: GeneralConfig = {
			...generalConfig,
			minStock: Number(generalConfig.minStock) || 0,
			orderPrefix: generalConfig.orderPrefix?.trim() || "PD",
			loriOrderCodeOffset: Number(generalConfig.loriOrderCodeOffset) || 0,
		};
		setGeneralConfig(newConfig);
		updateRetailerConfig(newConfig);
	};

	return (
		<>
			<Stack gap={2}>
				<GeneralCatalog generalConfig={generalConfig} onChange={handleChange} />
				<GeneralOrder
					generalConfig={generalConfig}
					onChange={handleChange}
					isDefaultReasons={isDefaultReasons}
					addNewReason={addNewReason}
					updateReason={updateReason}
					removeReason={removeReason}
					resetCancellationReasons={resetCancellationReasons}
					sortCancellationReasons={sortCancellationReasons}
				/>
			</Stack>
			<RetailerUpdateButton
				isLoadingUpdate={isLoadingUpdate}
				onSubmit={handleSubmit}
			/>
		</>
	);
}
