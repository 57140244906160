export enum PaymentStatus {
	None = 0,
	WaitingPreApproval = 1,
	WaitingCapture = 2,
	WaitingDirect = 3,
	WaitingRefund = 4,
	PreApproved = 5,
	Pay = 6,
	Refunded = 7,
	PreApprovalError = 8,
	CaptureError = 9,
	PaymentError = 10,
	RefundError = 11,
	PayOnOrderCreate = 12,
	PaymentCodeCreated = 13,
	WaitingRetry = 14,
	RetryExpired = 15,
	PickupStorePayment = 16,
	PickupStorePreApproved = 17,
	PickupStoreApproved = 18,
	WaitingNewAmountDirect = 19,
}
