import React, { useState, useEffect } from "react";
import Modal from "../../../../components/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API, APIV2 } from "../../../../API";

export default function DropzoneInvoice({
	id,
	onChange,
	formDropFile,
	updateCallback,
}) {
	const [fileType, setFileType] = useState("");
	const [fileUrl, setFileUrl] = useState("");
	const [xmlUrl, setXmlUrl] = useState("");
	const [isUploadFile, setIsUploadFile] = useState(false);

	useEffect(() => {
		formDropFile.fileUrl && setFileUrl(formDropFile.fileUrl);
		formDropFile.xmlUrl && setXmlUrl(formDropFile.xmlUrl);
	}, []);

	const onDrop = async (files) => {
		if (
			files[0].path.indexOf("xml") != -1 ||
			files[0].path.indexOf("pdf") != -1
		) {
			const xml = files[0].type.indexOf("xml");
			const formData = new FormData();
			formData.append("file", files[0]);

			if (xml === -1) {
				setFileType("PDF");
				setIsUploadFile(true);
				API.post("api/images", formData, {
					headers: { ContentType: "multipart/form-data" },
				})
					.then(async (resp) => {
						APIV2.put(
							`management/commerce/orders/${id}/invoiceFile?invoiceUrl=${resp.data.absoluteUrl}`
						)
							.then(() => {
								setFileUrl(resp.data.absoluteUrl);
								onChange({ fileUrl: resp.data.absoluteUrl });
								updateCallback();
								setIsUploadFile(false);
							})
							.catch(() => {
								setIsUploadFile(false);
								toast.error(
									"Parece que tivemos um erro... Tente novamente mais tarde."
								);
							});
					})
					.catch(() => {
						setIsUploadFile(false);
						toast.error(
							"Parece que tivemos um erro... Tente novamente mais tarde."
						);
					});
			} else {
				setFileType("XML");
				API.post("api/images", formData, {
					headers: { ContentType: "multipart/form-data" },
				})
					.then(async (resp) => {
						APIV2.put(
							`management/commerce/orders/${id}/invoiceXml?invoiceUrl=${resp.data.absoluteUrl}`
						)
							.then(() => {
								APIV2.get(`management/commerce/orders/${id}`)
									.then(() => {
										setXmlUrl(resp.data.absoluteUrl);
										onChange({ xmlUrl: resp.data.absoluteUrl });
										updateCallback();
									})
									.catch(() => {
										setIsUploadFile(false);
										toast.error(
											"Parece que tivemos um erro... Tente novamente mais tarde."
										);
									});
							})
							.catch(() => {
								setIsUploadFile(false);
								toast.error(
									"Parece que tivemos um erro... Tente novamente mais tarde."
								);
							});
					})
					.catch(() => {
						setIsUploadFile(false);
						toast.error(
							"Parece que tivemos um erro... Tente novamente mais tarde."
						);
					});
			}
		} else {
			toast.error("São suportados arquivos no formato XML e PDF!");
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	const chooseFile = (event) => {
		event.preventDefault();
		setFileType(event.currentTarget.name);
	};

	const deleteInvoiceUrl = () => {
		APIV2.delete(
			`management/commerce/orders/${id}/invoiceUrl?typeUrl=${fileType}`
		)
			.then(() => {
				if (fileType === "xml") {
					setFileType("");
					setXmlUrl("");
					updateCallback();
				} else {
					setFileType("");
					setFileUrl("");
					updateCallback();
				}
			})
			.catch(() => {
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	return (
		<>
			<div className=" p-0">
				<label htmlFor="numberKey">
					<strong>Arquivo da Nota</strong>
				</label>
				<div {...getRootProps()} className={"download-pdf-xml"}>
					<input {...getInputProps()} />
					<div className="success" style={{ width: "100%" }}>
						<div className="d-flex flex-column justify-content-center align-items-center">
							{isUploadFile ? (
								<i className="fas fa-spinner fa-spin"></i>
							) : (
								<>
									<i className="material-icons"> note_add </i>
									{isDragActive ? (
										<span>pode arrastar aqui...</span>
									) : (
										<span>
											Arraste ATÉ AQUI ou CLIQUE PARA ESCOLHER um arquivo
										</span>
									)}
								</>
							)}
						</div>
					</div>
				</div>

				<label
					style={{
						color: "#A1A3A5",
						fontWeight: "500",
						fontSize: "12px",
					}}
				>
					<em>São suportados arquivos no formato XML e PDF</em>
				</label>
				<div className="d-flex justify-content-center flex-column m-0 p-0">
					{xmlUrl && (
						<div className="download-group m-0 p-0 mb-2">
							<div className="download-group-label">
								<i className="material-icons">insert_drive_file</i>
								<span>&nbsp;Arquivo XML da Nota Fiscal</span>
							</div>
							<div className="download-group-icons">
								<button
									onClick={chooseFile}
									name="xml"
									data-toggle="modal"
									data-target="#deleteNF"
								>
									<i
										className="material-icons"
										style={{ color: "#FF5B5C", paddingRight: "10px" }}
									>
										delete_outline
									</i>
								</button>
								<a href={xmlUrl} target="_blank">
									<i className="material-icons">get_app</i>
								</a>
							</div>
						</div>
					)}
					{fileUrl && (
						<div className="download-group m-0 p-0">
							<div className="download-group-label">
								<i className="material-icons">insert_drive_file</i>
								<span>&nbsp;Arquivo PDF da Nota Fiscal</span>
							</div>
							<div className="download-group-icons">
								<button
									onClick={chooseFile}
									name="pdf"
									data-toggle="modal"
									data-target="#deleteNF"
								>
									<i
										className="material-icons"
										style={{ color: "#FF5B5C", paddingRight: "10px" }}
									>
										delete_outline
									</i>
								</button>
								<a href={fileUrl} target="_blank">
									<i className="material-icons">get_app</i>
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
			<Modal
				id={"deleteNF"}
				title={`"Tem certeza que deseja excluir o arquivo ${fileType} ?" `}
				delete={true}
				actionDelete={deleteInvoiceUrl}
			/>
		</>
	);
}
