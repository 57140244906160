import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import OrdersList from "./OrdersList";
import { useConfig } from "../../providers/ConfigProvider";
import LoadDashboard from "../Dashboard/components/LoadDashboard";

export default function Index(props) {
	const { mainLoading } = useConfig();
	const [loading, setLoading] = useState(false);

	let params = new URL(document.location).searchParams;

	useEffect(() => {
		if (!props.match.params.page) {
			window.location.href = "/orders/1";
		}
		//será necessário colocar em um objeto
		localStorage.removeItem("transRowsPerPage");
		localStorage.removeItem("transType");
		localStorage.removeItem("transStatus");
		localStorage.removeItem("transPaymentMethod");
		localStorage.removeItem("transStartDate");
		localStorage.removeItem("transEndDate");
		localStorage.removeItem("transSearchName");
	}, []);

	const renderScreen = () => {
		return (
			<div className="container dash-content">
				<OrdersList
					page={props.match.params.page}
					since={params.get("since")}
					until={params.get("until")}
					status={params.get("status")}
					isDelivery={params.get("isDelivery")}
					channel={params.get("channel")}
					query={params.get("query")}
					sellerCode={params.get("sellerCode")}
				/>
			</div>
		);
	};
	return (
		<>
			{loading ? (
				<div className="container dash-content">
					<div className="card">
						<Loading />
					</div>
				</div>
			) : mainLoading ? (
				<LoadDashboard />
			) : (
				renderScreen()
			)}
		</>
	);
}
