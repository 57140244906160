import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function Invoice(information, items) {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const renderHeaderPDF = [
		{
			columns: [
				information.image && {
					image: "snow",
					alignment: "left",
					maxWidth: 120,
					mexHeight: 100,
				},

				{
					text: [
						{
							text: [
								{
									alignment: "right",
									text: {
										alignment: "right",
										text: `Pedido nº ${information?.order?.friendlyCode}\n`,
										bold: true,
										color: "#4B5F79",
										fontSize: 13,
									},
								},
								{
									alignment: "right",
									text: {
										alignment: "right",
										text: `Data do pedido: ${information?.order?.createDate}\n\n\n`,
										bold: true,
										color: "#4B5F79",
										fontSize: 13,
									},
								},
							],
						},
					],
				},
			],
		},
	];

	const renderInformationInvoicePDF = [
		{
			columns: [
				{
					text: [
						{
							text: [
								{
									alignment: "left",
									text: `Data:  ${information?.order?.createDate}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
								{
									alignment: "left",
									text: `Total:  ${information?.amount?.totalAmount.toLocaleString(
										"pt-br",
										{ style: "currency", currency: "BRL" }
									)}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
								{
									alignment: "left",
									text: `${
										localStorage.getItem("role") === "8"
											? "Tipo de envio:"
											: "Modalidade:"
									} ${information?.order?.modality}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
							],
						},
					],
				},
				{
					text: [
						{
							text: [
								{
									alignment: "left",
									text: `Pagamento: ${
										information?.paymentInfo?.paymentStatus === 0
											? "NÃO INICIADO"
											: information?.paymentInfo?.paymentStatus === 1
											? "EM ANÁLISE" //Aguardando pré-aprovação
											: information?.paymentInfo?.paymentStatus === 2
											? "EM ANÁLISE" //Aguardando captura
											: information?.paymentInfo?.paymentStatus === 3
											? "EM ANÁLISE" //Aguardando pagamento direto
											: information?.paymentInfo?.paymentStatus === 4
											? "EM ANÁLISE" //Aguardando estorno
											: information?.paymentInfo?.paymentStatus === 5
											? "PRÉ-APROVADO"
											: information?.paymentInfo?.paymentStatus === 6
											? "PAGO"
											: information?.paymentInfo?.paymentStatus === 7
											? "ESTORNADO"
											: information?.paymentInfo?.paymentStatus === 8
											? "ERRO" //Erro de pré-aprovação
											: information?.paymentInfo?.paymentStatus === 9
											? "ERRO" //Erro de captura
											: information?.paymentInfo?.paymentStatus === 10
											? "ERRO" //Erro de pagamento
											: information?.paymentInfo?.paymentStatus === 11
											? "ERRO" //Erro de estorno
											: information?.paymentInfo?.paymentStatus === 12
											? "Pagamento na criação do pedido"
											: information?.paymentInfo?.paymentStatus === 13
											? "BOLETO CRIADO"
											: information?.paymentInfo?.paymentStatus === 14
											? "EM ANÁLISE" //Aguardando nova tentativa
											: information?.paymentInfo?.paymentStatus === 15
											? "Retentativas expiradas"
											: information?.paymentInfo?.paymentStatus === 16
											? "AGUARDANDO PAGAMENTO"
											: information?.paymentInfo?.paymentStatus === 17
											? "PRÉ-APROVADO"
											: information?.paymentInfo?.paymentStatus === 18
											? "APROVADO"
											: ""
									}\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
								},
							],
						},
					],
				},
			],
		},
	];

	const renderPaymentData = [
		{
			alignment: "left",
			columns: [
				{
					text: [
						{
							text: [
								{
									text: `Método de pagamento: `,
									bold: true,
									color: "#747E8B",
									fontSize: 12,
								},
								{
									text: `${information?.paymentInfo?.info}\n`,
									color: "#747E8B",
									fontSize: 11,
								},
							],
						},
						{
							text: [
								{
									text: `Parcelas: `,
									bold: true,
									color: "#747E8B",
									fontSize: 12,
								},
								{
									text: `${information?.paymentInfo?.quantityInstallments}\n`,
									color: "#747E8B",
									fontSize: 11,
								},
							],
						},
						{
							text: [
								{
									text: `Mensagem do adquirente: `,
									bold: true,
									color: "#747E8B",
									fontSize: 12,
								},
								{
									text: `${
										information.paymentInfo.acquirerMessage &&
										information.paymentInfo.acquirerMessage
									}\n`,
									color: "#747E8B",
									fontSize: 11,
								},
							],
						},
						{
							text: [
								{
									text: `Status anti-fraude: `,
									bold: true,
									color: "#747E8B",
									fontSize: 12,
								},
								{
									text: `${information?.paymentInfo?.fraudStatus}\n`,
									color: "#747E8B",
									fontSize: 11,
								},
							],
						},
						{
							text: [
								{
									text: `Código de aprovação: `,
									bold: true,
									color: "#747E8B",
									fontSize: 12,
								},
								{
									text: `${
										information?.paymentInfo?.acquirerApprovalCode &&
										information?.paymentInfo?.acquirerApprovalCode
									}\n`,
									color: "#747E8B",
									fontSize: 11,
								},
							],
						},
						{
							text: [
								{
									text: `NSU: `,
									bold: true,
									color: "#747E8B",
									fontSize: 12,
								},
								{
									text: `${
										information?.paymentInfo?.nsu &&
										information?.paymentInfo?.nsu
									}\n`,
									color: "#747E8B",
									fontSize: 11,
								},
							],
						},
					],
				},
			],
		},
	];

	const renderInformationsPDF = [
		{
			alignment: "left",
			columns: [
				{
					text: [
						{
							text: `PEDIDO PARA: \n\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						information?.buyer?.fullName && {
							text: `${information?.buyer?.fullName}\n`,
							bold: true,
							color: "#4B5F79",
							fontSize: 14,
						},
						information?.buyer?.cpf && {
							text: `${information?.buyer?.cpf}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						information?.buyer?.email && {
							text: `${information?.buyer?.email}\n`,
							bold: true,
							color: "#7A9FEB",
							fontSize: 10,
						},
						information?.buyer?.address?.street && {
							text: `${information?.buyer?.address?.street}  ${information?.buyer?.address?.number}  ${information?.buyer?.address?.complement}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						{
							text: `${information?.buyer?.address?.neighborhood}  ${information?.buyer?.address?.city}  ${information?.buyer?.address?.state}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},

						information?.buyer?.address?.cep && {
							text: `${information?.buyer?.address?.cep}\n\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
					],
				},
				{
					text: [
						{
							text: `PEDIDO DE: \n\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						information?.seller?.fullName && {
							text: `${information?.seller?.fullName}\n`,
							bold: true,
							color: "#4B5F79",
							fontSize: 14,
						},
						information?.seller?.cnpj && {
							text: `${information?.seller?.cnpj}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						information?.seller?.email && {
							text: `${information?.seller?.email}\n`,
							bold: true,
							color: "#7A9FEB",
							fontSize: 10,
						},

						information?.seller?.address?.street && {
							text: `${information?.seller?.address?.street}  ${information?.seller?.address?.number}  ${information?.seller?.address?.complement}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						{
							text: `	${information?.seller?.address?.neighborhood}  ${information?.seller?.address?.city}  ${information?.seller?.address?.state}\n`,
							bold: true,
							color: "#747E8B",
							fontSize: 10,
						},
						information?.seller?.address?.cep
							? ""
							: {
									text: `${information?.seller?.address?.cep}\n\n`,
									bold: true,
									color: "#747E8B",
									fontSize: 10,
							  },
					],
				},
			],
		},
	];

	const products = items.map((product, index) => {
		return [
			{
				text: `\n${index + 1}\n\n`,
				color: "#BCBEC0",
				fontSize: 8,
				alignment: "center",
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
			{
				text: `\n${product.sku.productSku}\n ${
					information?.productDisplayCodeName === "Code"
						? product?.sku?.product?.code
							? "Cód Produto: " + product?.sku?.product?.code
							: ""
						: information?.productDisplayCodeName === "ReferenceCode"
						? product?.sku?.product?.referenceCode
							? "Cód Produto :" + product?.sku?.product?.referenceCode
							: ""
						: information?.productDisplayCodeName === "AuxCode"
						? product?.sku?.product?.auxCode
							? "Cód Produto :" + product?.sku?.product?.auxCode
							: ""
						: ""
				}${product?.sku?.code ? "   Cód sku: " + product?.sku?.code : ""}${
					product?.sku?.barcode ? "   EAN: " + product?.sku?.barcode : ""
				}\n`,
				color: "#747E8B",
				alignment: "left",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 0, 0, 5],
			},
			{
				text: `\n${product?.quantity}\n`,
				color: "#747E8B",
				bold: true,
				alignment: "center",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
			{
				text: `\n${product?.unitPrice.toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				})}\n`,
				alignment: "center",
				color: "#BCBEC0",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
			{
				text: `\n${product?.totalPrice.toLocaleString("pt-br", {
					style: "currency",
					currency: "BRL",
				})}\n`,
				alignment: "right",
				color: "#747E8B",
				fontSize: 8,
				//border: [false, true, false, true],
				margin: [0, 5, 0, 0],
			},
		];
	});

	const renderTablePDF = [
		{
			text: `Itens do Pedido (${items.length})\n\n`,
			bold: true,
			color: "#4B5F79",
			fontSize: 12,
		},
		{
			table: {
				headerRows: 1,
				widths: [20, 320, 30, "*", "*"],
				body: [
					[
						{
							text: "Nº",
							fontSize: 10,
							alignment: "center",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "ITEM",
							fontSize: 10,
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "QTD",
							fontSize: 10,
							alignment: "center",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "PREÇO UNIT",
							fontSize: 10,
							alignment: "center",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
						{
							text: "TOTAL",
							fontSize: 10,
							alignment: "right",
							color: "#747E8B",
							border: [false, true, false, false],
							margin: [0, 4, 0, 4],
						},
					],
					...products,
				],
			},
			layout: {
				hLineColor: function (i, node) {
					return i === 0 || i === node.table.body.length
						? "#E0E3E7"
						: "#E0E3E7";
				},
				vLineColor: function (i, node) {
					return i === 0 || i === node.table.widths.length
						? "#ffffff"
						: "#ffffff";
				},
			},
		},
	];

	const renderTotalInvoicePDF = [
		{
			defaultStyle: { alignment: "right" },
			layout: "noBorders",
			alignment: "right",
			table: {
				widths: ["*", 50],
				body: [
					[
						{
							text: `Frete:`,
							fontSize: 10,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${information?.amount?.freightAmount.toLocaleString(
								"pt-br",
								{
									style: "currency",
									currency: "BRL",
								}
							)}`,
							fontSize: 10,
							color: "#747E8B",
							noWrap: true,
						},
					],
					[
						{
							text: `Desconto:`,
							fontSize: 10,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${information?.amount?.discountAmount.toLocaleString(
								"pt-br",
								{
									style: "currency",
									currency: "BRL",
								}
							)}`,
							fontSize: 10,
							color: "#747E8B",
							noWrap: true,
						},
					],
					[
						{
							text: `Taxas:`,
							fontSize: 10,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${information?.amount?.feeAmount.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}`,
							fontSize: 10,
							color: "#747E8B",
							noWrap: true,
						},
					],
					[
						{
							text: `------------------`,
							fontSize: 10,
							bold: true,
							color: "#E0E3E7",
							alignment: "right",
						},
						{
							text: `------------------`,
							fontSize: 10,
							bold: true,
							color: "#E0E3E7",
							noWrap: true,
						},
					],
					[
						{
							text: `Valor Total:`,
							fontSize: 12,
							bold: true,
							color: "#747E8B",
							alignment: "right",
						},
						{
							text: `${information?.amount?.totalAmount.toLocaleString(
								"pt-br",
								{
									style: "currency",
									currency: "BRL",
								}
							)}`,
							fontSize: 12,
							color: "#747E8B",
							noWrap: true,
						},
					],
				],
			},
		},
	];

	function renderFooterPDF(currentPage, pageCount) {
		return [
			{
				text: currentPage,
				alignment: "right",
				bold: true,
			},
		];
	}

	const invoiceOrders = {
		pageSize: "A4",
		pageMargins: [30, 30, 30, 2],
		images: {
			snow: `${information.image}`,
		},
		content: [
			renderHeaderPDF,
			{
				text: "___________________________________________________________________________________________________\n\n",
				bold: true,
				color: "#E0E3E7",
				fontSize: 12,
			},
			renderInformationInvoicePDF,
			{
				text: "___________________________________________________________________________________________________\n\n",
				bold: true,
				color: "#E0E3E7",
				fontSize: 12,
			},
			{
				text: "\n",
				color: "#E0E3E7",
				fontSize: 2,
			},
			renderInformationsPDF,
			renderTablePDF,
			{
				text: "\n",
				color: "#E0E3E7",
				fontSize: 15,
			},
			renderTotalInvoicePDF,
			{ text: `\n\nPagamento\n`, bold: true, color: "#4B5F79", fontSize: 12 },
			{
				text: "___________________________________________________________________________________________________\n\n",
				bold: true,
				color: "#E0E3E7",
				fontSize: 12,
			},
			renderPaymentData,
		],
		footer: [renderFooterPDF],
	};

	pdfMake.createPdf(invoiceOrders).open({}, window.open("", "_blank"));
}

export default Invoice;
