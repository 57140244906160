import React, { ChangeEvent } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { i18n } from "../../../../translate/i18n";
import CustomTypography from "../../../../MUICustomComponents/CustomTypography";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import CustomSelect, {
	SelectOptions,
} from "../../../../MUICustomComponents/CustomSelect";
import RetailerCheckbox from "../components/RetailerCheckbox";
import RetailerCard from "../components/RetailerCard";
import { LoriOrderCodeType } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/LoriOrderCodeType";
import { GeneralConfig } from "../types/GeneralConfig";
import { ShowOrderCodeType } from "../../../../domain/enums/ShowOrderCodeType";
import { ReturnExchangedSystem } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/ReturnExchangedSystem";
import { CancellationReason } from "../../../../domain/dtos/retailerManagement/retailerConfigurations/CancellationReason";
import AccordionTable from "../../../../components/AccordionTable/AccordionTable";

type Props = {
	generalConfig: GeneralConfig;
	onChange: (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		checked?: boolean
	) => void;
	isDefaultReasons: boolean;
	addNewReason: (inputValue: string, index: number) => void;
	updateReason: (value: string, indexToUpdate: number) => void;
	removeReason: (reasonToRemove: CancellationReason) => void;
	resetCancellationReasons: () => void;
	sortCancellationReasons: (reasons: CancellationReason[]) => void;
};

export default function GeneralOrder({
	generalConfig,
	onChange,
	isDefaultReasons,
	addNewReason,
	updateReason,
	removeReason,
	resetCancellationReasons,
	sortCancellationReasons,
}: Props) {
	const loriOrderCodeTypeOptions: SelectOptions = [
		{
			label: i18n.t("retailerConfig.LoriOrderCodeType.AlphaNumeric"),
			value: LoriOrderCodeType.AlphaNumeric,
		},
		{
			label: i18n.t("retailerConfig.LoriOrderCodeType.NumericSequential"),
			value: LoriOrderCodeType.NumericSequential,
		},
		{
			label: i18n.t("retailerConfig.LoriOrderCodeType.UseExternalId"),
			value: LoriOrderCodeType.UseExternalId,
		},
	];

	const showOrderCodeTypeOptions: SelectOptions = [
		{
			label: i18n.t("retailerConfig.ShowOrderCodeType.ExternalId"),
			value: ShowOrderCodeType.ExternalId,
		},
		{
			label: i18n.t("retailerConfig.ShowOrderCodeType.FriendlyCode"),
			value: ShowOrderCodeType.FriendlyCode,
		},
		{
			label: i18n.t("retailerConfig.ShowOrderCodeType.SellerOrderId"),
			value: ShowOrderCodeType.SellerOrderId,
		},
	];

	const returnExchangedSystem: SelectOptions = [
		{
			label: i18n.t("retailerConfig.ReturnExchangedSystem.None"),
			value: ReturnExchangedSystem.None,
		},
		{
			label: i18n.t("retailerConfig.ReturnExchangedSystem.TroqueCommerce"),
			value: ReturnExchangedSystem.TroqueCommerce,
		},
	];

	return (
		<RetailerCard>
			<CustomTypography variant="secondaryTitle" marginBottom={2}>
				{i18n.t("retailerConfig.GeneralOrder").toString()}
			</CustomTypography>
			<Grid container spacing={2} mt={-1}>
				<Grid item xs={6}>
					<CustomSelect
						label={i18n.t(`retailerConfig.${"loriOrderCodeType"}`)}
						id="loriOrderCodeType"
						onChange={onChange}
						value={generalConfig.loriOrderCodeType}
						options={loriOrderCodeTypeOptions}
						helpTooltip={{
							title: i18n.t(`retailerConfig.LoriOrderCodeTypeTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"orderPrefix"}`)}
						name="orderPrefix"
						value={generalConfig.orderPrefix}
						onChange={onChange}
						type="text"
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomInput
						title={i18n.t(`retailerConfig.${"loriOrderCodeOffset"}`)}
						name="loriOrderCodeOffset"
						value={generalConfig.loriOrderCodeOffset}
						onChange={onChange}
						type="number"
					/>
				</Grid>
				<Grid item xs={6}>
					<CustomSelect
						label={i18n.t(`retailerConfig.${"showOrderCodeType"}`)}
						id="showOrderCodeType"
						onChange={onChange}
						value={generalConfig.showOrderCodeType}
						options={showOrderCodeTypeOptions}
						helpTooltip={{
							title: i18n.t(`retailerConfig.ShowOrderCodeTypeTooltip`),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomSelect
						label={i18n.t("retailerConfig.returnExchangedSystem")}
						id="returnExchangedSystem"
						onChange={onChange}
						value={generalConfig.returnExchangedSystem}
						options={returnExchangedSystem}
					/>
				</Grid>
			</Grid>
			<Grid container columnSpacing={2}>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="enableChangeSeller"
						isChecked={generalConfig.enableChangeSeller}
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<RetailerCheckbox
						id="enableOrderPicking"
						isChecked={generalConfig.enableOrderPicking}
						onChange={onChange}
					/>
				</Grid>
			</Grid>
			<AccordionTable
				title={i18n.t("retailerConfig.CancellationReason.Title")}
				dataArray={generalConfig.cancellationReasons}
				tableDisplayKey={"reason"}
				componentTexts={{
					addInputTitle: i18n.t(
						"retailerConfig.CancellationReason.AddNewReason"
					),
					addButtonText: i18n.t("retailerConfig.CancellationReason.AddReason"),
					editInputTitle: i18n.t("retailerConfig.CancellationReason.Reason"),
				}}
				addNewData={addNewReason}
				updateData={updateReason}
				removeData={removeReason}
				findEqual={(
					data: CancellationReason,
					inputValue: string,
					editIndex?: number
				): boolean =>
					data.reason.trim().toLowerCase() === inputValue.toLowerCase() &&
					(editIndex === undefined || editIndex !== data.sortOrder - 1)
				}
				resetConfig={{
					buttonText: i18n.t(
						"retailerConfig.CancellationReason.RestoreDefault"
					),
					attentionDialogText: i18n.t(
						"retailerConfig.CancellationReason.ConfirmRestoreDefault"
					),
					resetToDefaultData: resetCancellationReasons,
					isDefaultData: isDefaultReasons,
					isDefaultAlertText: i18n.t(
						"retailerConfig.CancellationReason.SuggestedDefaultReasons"
					),
				}}
				dragDropConfig={{
					dataKeyAsDraggableId: "reason",
					sortData: sortCancellationReasons,
				}}
			/>
		</RetailerCard>
	);
}
