import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { OrderModel } from "../../../../domain/models/commerce/OrderModel";
import { InvoiceModel } from "../../../../domain/models/commerce/InvoiceModel";
import { getValueOrDefault } from "../../../../infrastructure/utils/getValueOrDefault";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../../../infrastructure/utils/FormUtils";
import { InvoiceInputs } from "./InvoiceInputs";
import dayjs, { Dayjs } from "dayjs";
import { cpCommerceManagementApi } from "../../../../infrastructure/api/CPCommerceManagement";
import {
	getSafeStringValue,
	normalizeDigitsOnly,
} from "../../../../infrastructure/utils/StringUtils";
import NewToastComponent from "../../../../components/NewToastComponent";
import { i18n } from "../../../../translate/i18n";

type SimplifiedInvoiceModel = Omit<
	InvoiceModel,
	"totalAmount" | "serieNumber"
> & {
	serieNumber: string;
};

export default function InvoiceDialogService(
	orderModel: OrderModel,
	updateCallback: Function,
	setIsDialogOpen: Dispatch<SetStateAction<boolean>>
) {
	const defaultEstimateDate = "0001-01-01T00:00:00";
	const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
	const [invoiceData, setInvoiceData] = useState<SimplifiedInvoiceModel>({
		number: getValueOrDefault(orderModel.invoice?.number, ""),
		serieNumber: getSafeStringValue(orderModel.invoice?.serieNumber.toString()),
		key: getValueOrDefault(orderModel.invoice?.key, ""),
		issuanceDate:
			orderModel.invoice?.issuanceDate === defaultEstimateDate
				? ""
				: getValueOrDefault(orderModel.invoice?.issuanceDate, ""),
		fileUrl: getValueOrDefault(orderModel.invoice?.fileUrl, null),
		xmlUrl: getValueOrDefault(orderModel.invoice?.xmlUrl, null),
	});
	const [dateValue, setDateValue] = useState<Dayjs | null>(
		orderModel.invoice?.issuanceDate === defaultEstimateDate
			? null
			: dayjs(orderModel.invoice?.issuanceDate)
	);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const formValidationMapper: FormValidations = {
		[InvoiceInputs.Number]: InputValidator.isRequired,
		[InvoiceInputs.SerieNumber]: InputValidator.isRequired,
		[InvoiceInputs.IssuanceDate]: InputValidator.isRequired,
	};

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;

		setFormErrors((prevState) => ({
			...prevState,
			[name]: FormValidator.validateField(name, value, formValidationMapper),
		}));
		setInvoiceData((prevState: any) => ({ ...prevState, [name]: value }));
	};

	const onChangeDate = (date: Dayjs | null): void => {
		setDateValue(date);
		const newDate = date && date.isValid() ? date.toISOString() : "";
		const errMessages: string[] =
			formValidationMapper[InvoiceInputs.IssuanceDate](newDate);
		setFormErrors((prevState) => ({
			...prevState,
			[InvoiceInputs.IssuanceDate]: errMessages,
		}));
		setInvoiceData((prevState) => ({
			...prevState,
			[InvoiceInputs.IssuanceDate]: newDate,
		}));
	};

	const handleFileDrop = (formDropFileData: {
		fileUrl?: string;
		xmlUrl?: string;
	}) => {
		setInvoiceData((prevState) => ({ ...prevState, ...formDropFileData }));
	};

	const onCloseCallback = () => {
		const originalInvoiceData: SimplifiedInvoiceModel = {
			number: getValueOrDefault(orderModel.invoice?.number, ""),
			serieNumber: getSafeStringValue(
				orderModel.invoice?.serieNumber.toString()
			),
			key: getValueOrDefault(orderModel.invoice?.key, ""),
			issuanceDate: getValueOrDefault(orderModel.invoice?.issuanceDate, ""),
			fileUrl: getValueOrDefault(orderModel.invoice?.fileUrl, null),
			xmlUrl: getValueOrDefault(orderModel.invoice?.xmlUrl, null),
		};
		setInvoiceData(originalInvoiceData);
		setDateValue(null);
		setFormErrors({});
	};

	const handleSubmit = () => {
		let errors: FormErrors = {};
		for (const [key, value] of Object.entries(invoiceData)) {
			errors[key] = FormValidator.validateField(
				key,
				value,
				formValidationMapper
			);
		}
		setFormErrors(errors);
		if (FormValidator.hasError(errors)) return;
		setIsLoadingUpdate(true);
		const convertedSerieNumber: number = Number(
			normalizeDigitsOnly(invoiceData.serieNumber)
		);
		const invoiceBody: { invoice: Omit<InvoiceModel, "totalAmount"> } = {
			invoice: {
				number: invoiceData.number,
				serieNumber: convertedSerieNumber,
				key: invoiceData.key,
				issuanceDate: invoiceData.issuanceDate,
				fileUrl: invoiceData.fileUrl,
				xmlUrl: invoiceData.xmlUrl,
			},
		};

		upsertInvoice(orderModel.id, invoiceBody);
	};

	const upsertInvoice = async (
		orderId: string,
		body: { invoice: Omit<InvoiceModel, "totalAmount"> }
	): Promise<void> => {
		const success = await cpCommerceManagementApi.upsertInvoice(orderId, body);
		setIsLoadingUpdate(false);
		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			return;
		}
		NewToastComponent({
			status: "success",
			title: i18n.t("invoiceDialog.UpsertSuccess"),
		});
		setIsDialogOpen(false);
		updateCallback();
	};

	return {
		invoiceData,
		dateValue,
		formErrors,
		isLoadingUpdate,
		handleOnChange,
		onChangeDate,
		handleFileDrop,
		handleSubmit,
		onCloseCallback,
	};
}
