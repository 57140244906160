import { Chip, Drawer, Grid, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import OrderDetailsService from "./OrderDetailsService";
import OrderInfo from "./components/OrderInfo";
import OrderClient from "./components/OrderClient";
import OrderStatus from "./components/OrderStatus";
import DeliveryReceiptDialog from "../../components/PickupReceiptDialog/DeliveryReceiptDialog";
import ChangeStatusDialog from "./Dialogs/ChangeStatusDialog";
import OrderCheckDialog from "../../components/OrderCheckDialog/OrderCheckDialog";
import OrderHistory from "./components/OrderHistory";
import OrderTotal from "./components/OrderTotal";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import CustomSkeleton from "../../MUICustomComponents/CustomSkeleton";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { LegacyOrderStatus } from "../../domain/enums/LegacyOrderStatus";
import { paletteObject } from "../../theme/foundations/palette";
import { i18n } from "../../translate/i18n";
import { formatDateStringOnly } from "../../infrastructure/utils/FormatMask";
import OrderItems from "./components/OrderItems";
import OrderShipping from "./components/OrderShipping";
import OrderInvoice from "./components/OrderInvoice";
import CreateOrderSummary from "./CreateOrderSummary";
import OrderPayments from "./components/OrderPayments";
import InvoiceDialog from "./Dialogs/InvoiceDialog/InvoiceDialog";
import CancellationReasonDialog from "./Dialogs/CancellationReasonDialog";
import OrderSeller from "./components/OrderSeller";

export default function OrderDetails() {
	const isCustomBreakpoint = useMediaQuery(`(max-width:992px)`);
	const {
		order,
		isLoadingOrder,
		statusToUpdateTo,
		setStatusToUpdateTo,
		isUpdatingStatus,
		isHistoryOpen,
		setIsHistoryOpen,
		isDeliveryReceiptDialogOpen,
		setIsDeliveryReceiptDialogOpen,
		isOrderCheckDialogOpen,
		setIsOrderCheckDialogOpen,
		isOrderStatusChangeOpen,
		setIsOrderStatusChangeOpen,
		isInvoiceDialogOpen,
		setIsInvoiceDialogOpen,
		isCancellationDialogOpen,
		setIsCancellationDialogOpen,
		updateOrderCallback,
		onActionButtonClick,
		onSubmitPicking,
		reprocessOrder,
		updateOrderStatus,
	} = OrderDetailsService();

	// prettier-ignore
	const statusMap: { [key in LegacyOrderStatus]: string } = {
    [LegacyOrderStatus.None]: i18n.t("legacyOrderProgress.None"),
    [LegacyOrderStatus.Pending]: i18n.t("legacyOrderProgress.Pending"),
    [LegacyOrderStatus.PendingCreate]: i18n.t("legacyOrderProgress.PendingCreate"),
    [LegacyOrderStatus.PendingApproval]: i18n.t("legacyOrderProgress.PendingApproval"),
    [LegacyOrderStatus.ApprovedExternal]: i18n.t("legacyOrderProgress.ApprovedExternal"),
    [LegacyOrderStatus.Processing]: i18n.t("legacyOrderProgress.Processing"),
    [LegacyOrderStatus.Separate]: i18n.t("legacyOrderProgress.Separate"),
    [LegacyOrderStatus.InDelivery]: i18n.t("legacyOrderProgress.InDelivery"),
    [LegacyOrderStatus.ReadyForPickup]: i18n.t("legacyOrderProgress.ReadyForPickup"),
    [LegacyOrderStatus.Finalized]: i18n.t("legacyOrderProgress.Finalized"),
    [LegacyOrderStatus.CanceledBySeller]: i18n.t("legacyOrderProgress.CanceledBySeller"),
    [LegacyOrderStatus.CanceledByBuyer]: i18n.t("legacyOrderProgress.CanceledByBuyer"),
    [LegacyOrderStatus.CanceledByAdmin]: i18n.t("legacyOrderProgress.CanceledByAdmin"),
    [LegacyOrderStatus.PendingCancel]: i18n.t("legacyOrderProgress.PendingCancel"),
    [LegacyOrderStatus.PendingCreatePreOrder]: i18n.t("legacyOrderProgress.PendingCreatePreOrder"),
    [LegacyOrderStatus.PreOrderCreated]: i18n.t("legacyOrderProgress.PreOrderCreated"),
    [LegacyOrderStatus.ErrorCancel]: i18n.t("legacyOrderProgress.ErrorCancel"),
    [LegacyOrderStatus.PendingUpdate]: i18n.t("legacyOrderProgress.PendingUpdate"),
    [LegacyOrderStatus.Updated]: i18n.t("legacyOrderProgress.Updated"),
    [LegacyOrderStatus.ErrorUpdate]: i18n.t("legacyOrderProgress.ErrorUpdate"),
    [LegacyOrderStatus.InvoiceCreated]: i18n.t("legacyOrderProgress.InvoiceCreated"),
    [LegacyOrderStatus.CanceledByProcessor]: i18n.t("legacyOrderProgress.CanceledByProcessor"),
  };

	const getChipLabel = (orderObj: OrderModel): string => {
		return (
			statusMap[orderObj.status as LegacyOrderStatus] ||
			statusMap[LegacyOrderStatus.None]
		);
	};

	const getChipColor = (orderObj: OrderModel): string => {
		switch (orderObj.status) {
			case LegacyOrderStatus.Finalized:
				return paletteObject.success.main;
			case LegacyOrderStatus.ErrorCancel:
			case LegacyOrderStatus.ErrorUpdate:
			case LegacyOrderStatus.CanceledByAdmin:
			case LegacyOrderStatus.CanceledByBuyer:
			case LegacyOrderStatus.CanceledByProcessor:
			case LegacyOrderStatus.CanceledBySeller:
				return paletteObject.danger.main;
			default:
				return paletteObject.info.main;
		}
	};

	const renderSubHeader = (): JSX.Element => {
		if (!order)
			return <CustomSkeleton quantity={1} height={20} width={"50%"} />;

		const quantityText = ` • ${order.itemQuantity} ${
			order.itemQuantity <= 1 ? "produto" : "produtos"
		}`;

		const modalityText = ` • ${
			localStorage.getItem("role") === "8"
				? order.freight?.description
				: i18n.t(`newOrderDetails.IsDelivery.${order.isDelivery}`)
		}`;

		return (
			<CustomTypography variant="default" color="grey80">
				Origem:{" "}
				<strong>
					{order.origin}
					{quantityText}
					{modalityText}
				</strong>
				{order.estimateDate && (
					<>
						<strong> • </strong>
						Estimativa de entrega:{" "}
						<strong>{formatDateStringOnly(order.estimateDate)}</strong>
					</>
				)}
			</CustomTypography>
		);
	};

	return (
		<div className="container dash-content">
			<Stack
				direction={{ xs: "column", md: "row" }}
				justifyContent="space-between"
				spacing={2}
				mb={2}
			>
				<Stack
					direction={{
						xs: "column",
						md: isCustomBreakpoint ? "column" : "row",
					}}
					alignItems={{
						xs: "start",
						md: isCustomBreakpoint ? "start" : "center",
					}}
					spacing={1}
				>
					<CustomTypography variant="big" color="grey90" isBold>
						Pedido{" "}
						{order?.orderCode || (
							<CustomSkeleton
								quantity={1}
								height={24}
								width={150}
								sx={{ display: "inline-flex" }}
							/>
						)}
					</CustomTypography>
					{order && (
						<Chip
							label={getChipLabel(order).toUpperCase()}
							size="small"
							variant="filled"
							sx={{
								fontWeight: "bold",
								borderRadius: 1,
								backgroundColor: getChipColor(order),
								color: paletteObject.white.main,
							}}
						/>
					)}
				</Stack>
				{order && (
					<CreateOrderSummary order={order} isLoading={isLoadingOrder} />
				)}
			</Stack>
			{renderSubHeader()}
			<Grid
				container
				spacing={2}
				mt={0}
				sx={{
					".card": { height: "100%" },
					".MuiTypography-body1": {
						lineHeight: "19px",
					},
				}}
			>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 4}>
					<OrderInfo order={order} isLoading={isLoadingOrder} />
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 4}>
					<OrderClient
						order={order}
						isLoading={isLoadingOrder}
						updateOrderCallback={updateOrderCallback}
					/>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 4}>
					<OrderTotal order={order} isLoading={isLoadingOrder} />
				</Grid>
				<Grid item xs={12}>
					<OrderStatus
						order={order}
						isLoading={isLoadingOrder}
						isUpdatingStatus={isUpdatingStatus}
						onActionButtonClick={onActionButtonClick}
						reprocessOrder={reprocessOrder}
						setIsHistoryOpen={setIsHistoryOpen}
					/>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<OrderPayments order={order} isLoading={isLoadingOrder} />
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<OrderInvoice
						order={order}
						isLoading={isLoadingOrder}
						setIsInvoiceDialogOpen={setIsInvoiceDialogOpen}
					/>
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<OrderSeller order={order} isLoading={isLoadingOrder} />
				</Grid>
				<Grid item xs={12} md={isCustomBreakpoint ? 12 : 6}>
					<OrderShipping
						order={order}
						isLoading={isLoadingOrder}
						updateOrderCallback={updateOrderCallback}
						setIsDeliveryReceiptDialogOpen={setIsDeliveryReceiptDialogOpen}
					/>
				</Grid>
				<Grid item xs={12}>
					<OrderItems order={order} isLoading={isLoadingOrder} />
				</Grid>
			</Grid>
			<ChangeStatusDialog
				isUpdatingStatus={isUpdatingStatus}
				isDialogOpen={isOrderStatusChangeOpen}
				setIsDialogOpen={setIsOrderStatusChangeOpen}
				updateStatus={() => updateOrderStatus(statusToUpdateTo, null)}
			/>
			<CancellationReasonDialog
				isCancellationDialogOpen={isCancellationDialogOpen}
				setIsCancellationDialogOpen={setIsCancellationDialogOpen}
				statusToUpdateTo={statusToUpdateTo}
				updateOrderStatus={updateOrderStatus}
				isUpdatingStatus={isUpdatingStatus}
				isDelivery={order?.isDelivery || false}
			/>
			{order && (
				<>
					<Drawer
						anchor="right"
						open={isHistoryOpen}
						onClose={() => setIsHistoryOpen(false)}
					>
						<OrderHistory order={order} setIsHistoryOpen={setIsHistoryOpen} />
					</Drawer>
					<DeliveryReceiptDialog
						orderModel={order}
						isDeliveryReceiptDialogOpen={isDeliveryReceiptDialogOpen}
						setIsDeliveryReceiptDialogOpen={setIsDeliveryReceiptDialogOpen}
						onSubmitCallback={() => {
							setIsDeliveryReceiptDialogOpen(false);
							updateOrderStatus(statusToUpdateTo, null);
						}}
					/>
					<OrderCheckDialog
						orderModel={order}
						isOrderCheckDialogOpen={isOrderCheckDialogOpen}
						setIsOrderCheckDialogOpen={setIsOrderCheckDialogOpen}
						isLoadingSubmit={isUpdatingStatus}
						onCancelCallback={() => {
							setStatusToUpdateTo("CanceledBySeller");
							setIsCancellationDialogOpen(true);
						}}
						onSubmitCallback={onSubmitPicking}
					/>
					{order && (
						<InvoiceDialog
							orderModel={order}
							isDialogOpen={isInvoiceDialogOpen}
							setIsDialogOpen={setIsInvoiceDialogOpen}
							updateCallback={updateOrderCallback}
						/>
					)}
				</>
			)}
		</div>
	);
}
