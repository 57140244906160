import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AutocompleteSellerView } from "../../domain/views/dealers/AutocompleteSellerView";
import { isValidString } from "../../infrastructure/utils/TypeValidator";
import { formatDocumentCnpj } from "../../infrastructure/utils/FormatMask";
import { AutocompleteSellerDto } from "../../domain/dtos/dealers/AutocompleteSellerDto";
import { retailerManagementApi } from "../../infrastructure/api/RetailerManagementApi";
import NewToastComponent from "../NewToastComponent";
import { i18n } from "../../translate/i18n";

export default function SellerAutocompleteService(
	sellerCode: string | null,
	setCurrentSeller: Dispatch<SetStateAction<AutocompleteSellerView | null>>,
	onlyActiveSellers: boolean,
	defaultOption?: AutocompleteSellerView
) {
	const [currentSellerInput, setCurrentSellerInput] = useState<string>("");
	const [sellerList, setSellerList] = useState<AutocompleteSellerView[]>([]);

	useEffect(() => {
		fetchAndInitializeSellerData();
	}, [sellerCode]);

	const fetchAndInitializeSellerData = async (): Promise<void> => {
		let updatedSellerList = sellerList;

		if (!sellerList.length) {
			updatedSellerList = await getAutocompleteSellers();
			setSellerList(updatedSellerList);

			if (defaultOption) setCurrentSeller(defaultOption);
		}

		if (sellerCode && !currentSellerInput) {
			const foundCurrentSeller = updatedSellerList.find(
				(seller) => seller.cnpj === sellerCode
			);

			if (foundCurrentSeller) setCurrentSeller(foundCurrentSeller);
		}
	};

	const getAutocompleteSellers = async (): Promise<AutocompleteSellerDto[]> => {
		let data: AutocompleteSellerDto[] = [];
		try {
			data = await retailerManagementApi.getAutocompleteSellers(
				onlyActiveSellers
			);

			if (defaultOption) data.unshift(defaultOption);

			return data;
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
		return data;
	};

	const filterOptions = (option: AutocompleteSellerView): string => {
		option.fullName = option.fullName ?? "";
		option.cnpj = option.cnpj ?? "";
		return option.fullName + option.cnpj;
	};

	const getOptionLabel = (option: AutocompleteSellerView): string => {
		if (isValidString(option.fullName)) {
			if (isValidString(option.cnpj)) {
				return `${option.fullName} - ${formatDocumentCnpj(option.cnpj)}`;
			}
			return `${option.fullName}`;
		}
		return formatDocumentCnpj(option.cnpj);
	};

	const isOptionEqualToValue = (
		option: AutocompleteSellerView,
		value: AutocompleteSellerView
	): boolean => option.id === value.id;

	return {
		sellerList,
		currentSellerInput,
		setCurrentSellerInput,
		// Functions
		filterOptions,
		getOptionLabel,
		isOptionEqualToValue,
	};
}
