import React from "react";
import { Stack, Skeleton, SxProps, Theme } from "@mui/material";
import { createId } from "@paralleldrive/cuid2";

type SkeletonProps = {
	quantity: number;
	height: number;
	width?: number | string;
	sx?: SxProps<Theme>;
};

export default function CustomSkeleton({
	quantity,
	height,
	width,
	sx = {},
}: SkeletonProps): JSX.Element {
	return (
		<Stack mb={0.5} gap={1} width={width} sx={sx}>
			{Array.from({ length: quantity }).map(() => (
				<Skeleton
					key={createId()}
					variant="rounded"
					width={"100%"}
					height={height}
				/>
			))}
		</Stack>
	);
}
