import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import CustomDialog from "../../../MUICustomComponents/CustomDialog";
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import LoadingButtonIcon from "../../../components/LoadingButtonIcon";
import { i18n } from "../../../translate/i18n";
import { CancellationReason } from "../../../domain/dtos/retailerManagement/retailerConfigurations/CancellationReason";
import CustomInput from "../../../MUICustomComponents/CustomInput";
import {
	FormErrors,
	FormValidator,
	InputValidator,
} from "../../../infrastructure/utils/FormUtils";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomCheckbox from "../../../MUICustomComponents/CustomCheckbox";
import { paletteObject } from "../../../theme/foundations/palette";
import CustomScrollbar from "../../../MUICustomComponents/CustomScrollbar";

type Props = {
	isCancellationDialogOpen: boolean;
	setIsCancellationDialogOpen: Dispatch<SetStateAction<boolean>>;
	statusToUpdateTo: string;
	updateOrderStatus: (
		newStatus: string,
		body: { tryToChangeSeller?: boolean; cancellationReason?: string } | null
	) => Promise<void>;
	isUpdatingStatus: boolean;
	isDelivery: boolean;
};

export default function CancellationReasonDialog({
	isCancellationDialogOpen,
	setIsCancellationDialogOpen,
	statusToUpdateTo,
	updateOrderStatus,
	isUpdatingStatus,
	isDelivery,
}: Props) {
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const enableChangeSeller = configurations?.enableChangeSeller as boolean;
	const cancellationReasons = configurations?.cancellationReasons as
		| CancellationReason[]
		| null;
	const [isOrderCancelConfirmed, setIsOrderCancelConfirmed] =
		useState<boolean>(false);
	const [currentReason, setCurrentReason] = useState<string | null>(null);
	const [customReasonInput, setCustomReasonInput] = useState<string>("");
	const [formErrors, setFormErrors] = useState<
		FormErrors & Record<"customReasonInput", string[]>
	>({ customReasonInput: [] });

	const onChangeReason = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setCurrentReason(value);
		setCustomReasonInput("");
		setFormErrors((prevState) => ({
			...prevState,
			customReasonInput:
				value === "CustomReasonRadio"
					? InputValidator.isRequired(customReasonInput)
					: [],
		}));
	};

	const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const inputError: string[] = InputValidator.isRequired(value);
		setFormErrors((prevState) => ({
			...prevState,
			customReasonInput: inputError,
		}));
		setCustomReasonInput(value);
	};

	const onOrderCancel = (tryToChangeSeller: boolean) => {
		if (!currentReason) return;

		updateOrderStatus(statusToUpdateTo, {
			cancellationReason:
				currentReason === "CustomReasonRadio"
					? customReasonInput
					: currentReason,
			...(enableChangeSeller && { tryToChangeSeller: tryToChangeSeller }),
		});
	};

	const renderDialogActions = () => {
		const showChangeSellerBtn =
			localStorage.getItem("role") !== "3" && isDelivery && enableChangeSeller;
		const isDisabled =
			isUpdatingStatus ||
			!isOrderCancelConfirmed ||
			currentReason === null ||
			FormValidator.hasError(formErrors);
		return (
			<>
				{showChangeSellerBtn && (
					<Button
						variant="contained"
						color="info"
						onClick={() => onOrderCancel(true)}
						disabled={isDisabled}
					>
						{i18n.t("cancellationReasonDialog.ChangeSellerAction").toString()}
					</Button>
				)}
				<Button
					variant="contained"
					color="danger"
					onClick={() => {
						onOrderCancel(localStorage.getItem("role") === "3" && isDelivery);
					}}
					disabled={isDisabled}
				>
					{isUpdatingStatus ? (
						<LoadingButtonIcon />
					) : (
						i18n.t("cancellationReasonDialog.CancelOrderAction").toString()
					)}
				</Button>
			</>
		);
	};

	return (
		<CustomDialog
			isOpen={isCancellationDialogOpen}
			setIsOpen={setIsCancellationDialogOpen}
			closeCallback={() => {
				setCurrentReason(null);
				setCustomReasonInput("");
			}}
			dialogInfo={{
				title: i18n.t("cancellationReasonDialog.Title"),
				subtitle: i18n.t("cancellationReasonDialog.Subtitle"),
				titleStyles: {
					marginBottom: 1,
					...((!enableChangeSeller ||
						localStorage.getItem("role") === "3" ||
						!isDelivery) && { marginRight: "32px" }),
				},
				subtitleStyles: {
					fontSize: "13px",
				},
			}}
			dialogStyles={{ titleVariant: "secondaryTitle", customMaxWidth: "550px" }}
			customActions={renderDialogActions()}
		>
			<CustomCheckbox
				id="isOrderCancelConfirmed"
				isChecked={isOrderCancelConfirmed}
				label={i18n.t("cancellationReasonDialog.ConfirmCancel")}
				color="info"
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					const { checked } = e.target;
					setIsOrderCancelConfirmed(checked);
				}}
			/>
			<Divider sx={{ my: 2, color: paletteObject.grey10.main }} />
			<FormControl fullWidth>
				<FormLabel>
					<CustomTypography variant="default" isBold sx={{ fontSize: "16px" }}>
						{i18n.t("cancellationReasonDialog.CancellationReasons").toString()}
					</CustomTypography>
				</FormLabel>
				<CustomScrollbar minHeight="0px" maxHeight="45vh">
					<RadioGroup
						name="cancellationReasonOptions"
						value={currentReason}
						onChange={onChangeReason}
					>
						{cancellationReasons &&
							cancellationReasons.map((reason) => (
								<FormControlLabel
									key={reason.id}
									label={
										<CustomTypography variant="default">
											{reason.reason}
										</CustomTypography>
									}
									value={reason.reason}
									sx={{
										margin: "0px",
										borderBottom: `1px solid ${paletteObject.grey10.main}`,
										marginLeft: "-10px",
									}}
									control={
										<Radio
											color="info"
											sx={{
												color: paletteObject.grey30.main,
												"&.Mui-checked": {
													color: paletteObject.info.main,
												},
											}}
										/>
									}
								/>
							))}
						<FormControlLabel
							label={
								<CustomTypography variant="default">
									{i18n
										.t("cancellationReasonDialog.CustomReasonRadio")
										.toString()}
								</CustomTypography>
							}
							value="CustomReasonRadio"
							sx={{ margin: "0px", marginLeft: "-10px" }}
							control={
								<Radio
									color="info"
									sx={{
										color: paletteObject.grey30.main,
										"&.Mui-checked": {
											color: paletteObject.info.main,
										},
									}}
								/>
							}
						/>
						{currentReason === "CustomReasonRadio" && (
							<Box pl={4}>
								<CustomInput
									placeholder={i18n.t(
										"cancellationReasonDialog.CustomReasonPlaceholder"
									)}
									name="customReasonInput"
									value={customReasonInput}
									onChange={onChangeInput}
									autoFocus
									error={FormValidator.hasInputError(
										formErrors,
										"customReasonInput"
									)}
									helperText={FormValidator.translateHelperTextList(
										formErrors,
										"customReasonInput"
									)}
								/>
							</Box>
						)}
					</RadioGroup>
				</CustomScrollbar>
			</FormControl>
		</CustomDialog>
	);
}
