import React, { useState, useEffect } from "react";
import { Box, Chip, Stack, Tooltip, Zoom } from "@mui/material";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { i18n } from "../../../translate/i18n";
import { PaymentMethodType } from "../../../domain/enums/Orders/PaymentMethodType";
import { PaymentModel } from "../../../domain/models/commerce/Payment/PaymentModel";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { formatCurrency } from "../../../infrastructure/utils/FormatUtils";
import { FraudStatus } from "../../../domain/enums/Orders/FraudStatus";
import { paletteObject } from "../../../theme/foundations/palette";
import { PaymentStatus } from "../../../domain/enums/Orders/PaymentStatus";
import CopyToClipboard from "../../../components/CopyToClipboard";
import { getPaymentIcon } from "../utils/getPaymentIcon";

type Props = { order: OrderModel | null; isLoading: boolean };

export default function OrderPayments({ order, isLoading }: Props) {
	const [count, setCount] = useState(0);
	const [disableCarousel, setDisableCarousel] = useState({
		prev: true,
		next: false,
	});

	useEffect(() => {
		if (!order) return;
		setDisableCarousel({
			prev: count === 0,
			next: count === order.paymentList.length - 1,
		});
	}, [count]);

	const getPaymentLabel = (
		paymentStatus: PaymentStatus,
		paymentMethodType: PaymentMethodType,
		isTooltip: boolean
	): string => {
		const isBankslipOrPix =
			paymentMethodType === PaymentMethodType.BankSlip ||
			paymentMethodType === PaymentMethodType.Pix;

		let label = `orderPayments.PaymentStatus${isTooltip ? "Tooltip" : ""}.${
			PaymentStatus[paymentStatus]
		}`;
		if (paymentStatus === PaymentStatus.PaymentCodeCreated && isBankslipOrPix) {
			label += `${PaymentMethodType[paymentMethodType]}`;
		}
		return i18n.t(label);
	};

	const getChipColor = (paymentStatus: PaymentStatus): string => {
		switch (paymentStatus) {
			case PaymentStatus.WaitingPreApproval:
			case PaymentStatus.WaitingCapture:
			case PaymentStatus.WaitingDirect:
			case PaymentStatus.WaitingRefund:
			case PaymentStatus.WaitingRetry:
			case PaymentStatus.PickupStorePayment:
				return paletteObject.warning.main;
			case PaymentStatus.PreApproved:
			case PaymentStatus.Pay:
			case PaymentStatus.PayOnOrderCreate:
			case PaymentStatus.PaymentCodeCreated:
			case PaymentStatus.PickupStorePreApproved:
			case PaymentStatus.PickupStoreApproved:
				return paletteObject.success.main;
			case PaymentStatus.PreApprovalError:
			case PaymentStatus.CaptureError:
			case PaymentStatus.PaymentError:
			case PaymentStatus.RefundError:
			case PaymentStatus.RetryExpired:
				return paletteObject.danger.main;
			case PaymentStatus.None:
			case PaymentStatus.Refunded:
			case PaymentStatus.WaitingNewAmountDirect:
			default:
				return paletteObject.grey80.main;
		}
	};

	const getPaymentMethodType = (paymentObject: PaymentModel | null): string => {
		if (!paymentObject?.paymentMethod?.type) {
			return i18n.t("orderPayments.PaymentMethodType.None");
		}
		return i18n.t(
			`orderPayments.PaymentMethodType.${
				PaymentMethodType[paymentObject.paymentMethod.type]
			}`
		);
	};

	const getFraudStatus = (fraudStatus: FraudStatus) => {
		return i18n.t(`orderPayments.FraudStatus.${FraudStatus[fraudStatus]}`);
	};

	const paymentInformation = (paymentObject: PaymentModel) => {
		return (
			<>
				<CustomTypography variant="default" color="blueish20">
					<strong>Valor: </strong>
					{formatCurrency(paymentObject.amount)}
				</CustomTypography>
				{paymentObject.quantityInstallments !== 0 &&
					paymentObject.amount !== 0 && (
						<CustomTypography variant="default" color="blueish20">
							<strong>Parcelas: </strong>
							{`${paymentObject.quantityInstallments} (${
								paymentObject.quantityInstallments
							}x de	${formatCurrency(
								paymentObject.amount / paymentObject.quantityInstallments
							)})`}
						</CustomTypography>
					)}
				{paymentObject.acquirerMessage && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Mensagem do adquirente: </strong>
						{paymentObject.acquirerMessage}
					</CustomTypography>
				)}
				{paymentObject.fraudStatus !== FraudStatus.None && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Status anti-fraude: </strong>
						{getFraudStatus(paymentObject?.fraudStatus)}
					</CustomTypography>
				)}
				{paymentObject?.acquirerApprovalCode && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Código de aprovação: </strong>
						{paymentObject.acquirerApprovalCode}
						<CopyToClipboard textToCopy={paymentObject.acquirerApprovalCode} />
					</CustomTypography>
				)}
				{paymentObject?.nsu && (
					<CustomTypography variant="default" color="blueish20">
						<strong>NSU: </strong>
						{paymentObject.nsu}
						<CopyToClipboard textToCopy={paymentObject.nsu} />
					</CustomTypography>
				)}
				{paymentObject?.paymentMethod?.info && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Informações adicionais: </strong>
						{paymentObject?.paymentMethod?.info}
					</CustomTypography>
				)}
			</>
		);
	};

	const renderPaymentIcon = (order: OrderModel): JSX.Element => {
		if (order.paymentList.length > 1) {
			return (
				<img
					src={getPaymentIcon(
						order.paymentList[count]?.paymentMethod?.type ?? null,
						order.paymentList[count]?.paymentMethod?.brand ?? null
					)}
				/>
			);
		}
		return (
			<img
				src={getPaymentIcon(
					order.paymentInfo?.paymentMethod?.type ?? null,
					order.paymentInfo?.paymentMethod?.brand ?? null
				)}
			/>
		);
	};

	return (
		<Box className="card" p={3}>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						<CustomTypography variant="secondaryTitle" isBold>
							Pagamento
						</CustomTypography>
						<Stack direction="row" spacing={1} alignItems="center">
							{renderPaymentIcon(order)}
							{order.paymentInfo && order.paymentInfo.paymentMethod && (
								<Tooltip
									arrow
									placement="top"
									TransitionComponent={Zoom}
									title={getPaymentLabel(
										order.paymentInfo.paymentStatus,
										order.paymentInfo.paymentMethod.type,
										true
									)}
								>
									<Chip
										label={getPaymentLabel(
											order.paymentInfo.paymentStatus,
											order.paymentInfo.paymentMethod.type,
											false
										).toUpperCase()}
										size="small"
										variant="filled"
										sx={{
											backgroundColor: getChipColor(
												order.paymentInfo.paymentStatus
											),
											color: paletteObject.white.main,
											fontWeight: "bold",
											borderRadius: 1,
											paddingX: 2,
										}}
									/>
								</Tooltip>
							)}
						</Stack>
					</Stack>
					{order.paymentList.length > 1 ? (
						<>
							<CustomTypography
								variant="default"
								color="blueish20"
								sx={{ mb: 1 }}
							>
								<strong>Forma: </strong>
								{getPaymentMethodType(order.paymentList[count])}
							</CustomTypography>
							<div
								id="carouselExampleControls"
								className="carousel slide payment-carousel"
								data-ride="carousel"
								data-interval="false" // Não trocar automaticamente
								data-wrap="false" // Não fazer um loop
							>
								<ol className="carousel-indicators">
									{order.paymentList.map((payment, index: number) => (
										<li
											key={payment.id}
											data-target="#carouselExampleIndicators"
											data-slide-to={index}
											className={
												index === 0
													? "carousel-indicators-item active"
													: "carousel-indicators-item"
											}
										></li>
									))}
								</ol>
								<div className="carousel-inner">
									{order.paymentList.map(
										(payment: PaymentModel, index: number) => {
											return (
												<div
													key={payment.id}
													className={
														index === 0
															? "carousel-item active"
															: "carousel-item"
													}
												>
													{paymentInformation(payment)}
												</div>
											);
										}
									)}
								</div>
								<button
									className="carousel-control-prev"
									type="button"
									data-target="#carouselExampleControls"
									data-slide="prev"
									onClick={() => setCount(count - 1)}
									disabled={disableCarousel.prev}
								>
									<i className="material-icons-outlined" aria-hidden="true">
										navigate_before
									</i>
								</button>
								<button
									className="carousel-control-next"
									type="button"
									data-target="#carouselExampleControls"
									data-slide="next"
									onClick={() => setCount(count + 1)}
									disabled={disableCarousel.next}
								>
									<i className="material-icons-outlined" aria-hidden="true">
										navigate_next
									</i>
								</button>
							</div>
						</>
					) : (
						<Stack direction="column" spacing={1}>
							<CustomTypography variant="default" color="blueish20">
								<strong>Forma: </strong>
								{getPaymentMethodType(order.paymentInfo)}
							</CustomTypography>
							{order.paymentInfo && paymentInformation(order.paymentInfo)}
						</Stack>
					)}
				</>
			)}
		</Box>
	);
}
