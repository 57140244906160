import React from "react";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { Box, Stack } from "@mui/material";
import { paletteObject } from "../../../theme/foundations/palette";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { formatDate } from "../../../infrastructure/utils/FormatMask";
import CopyToClipboard from "../../../components/CopyToClipboard";

type Props = { order: OrderModel | null; isLoading: boolean };

export default function OrderInfo({ order, isLoading }: Props) {
	return (
		<Box
			className="card"
			p={3}
			sx={{ backgroundColor: paletteObject.grey90.main }}
		>
			<CustomTypography
				variant="secondaryTitle"
				isBold
				color="white"
				marginBottom={2}
			>
				Informações do pedido
			</CustomTypography>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<Stack direction="column" spacing={1}>
					<CustomTypography variant="default" color="white">
						<strong>Data de criação: </strong>
						{formatDate(order.createDate)}
					</CustomTypography>
					<CustomTypography variant="default" color="white">
						<strong>Última atualização: </strong>
						{formatDate(order.lastUpdate)}
					</CustomTypography>
					{order.sellerOrderId && (
						<CustomTypography variant="default" color="white">
							<strong>Código ERP/PDV: </strong>
							{order.sellerOrderId}
							<CopyToClipboard textToCopy={order.sellerOrderId} color="white" />
						</CustomTypography>
					)}
					{order.externalId && (
						<CustomTypography variant="default" color="white">
							<strong>Código canal de venda: </strong>
							{order.externalId}
							<CopyToClipboard textToCopy={order.externalId} color="white" />
						</CustomTypography>
					)}
					{localStorage.getItem("role") === "8" ? (
						order.freight?.description && (
							<CustomTypography variant="default" color="white">
								<strong>Tipo de envio: </strong>
								{order.freight.description}
							</CustomTypography>
						)
					) : (
						<CustomTypography variant="default" color="white">
							<strong>Modalidade: </strong>
							{order.isDelivery ? "Receba em Casa" : "Retire na Loja"}
						</CustomTypography>
					)}
					<CustomTypography variant="default" color="white">
						<strong>Origem: </strong>
						{order.origin}
					</CustomTypography>
					{order.dealerCode && (
						<CustomTypography variant="default" color="white">
							<strong>Código do vendedor: </strong>
							{order.dealerCode}
						</CustomTypography>
					)}
					{order.cancellationReason && (
						<CustomTypography variant="default" color="white">
							<strong>Motivo de cancelamento: </strong>
							{order.cancellationReason}
						</CustomTypography>
					)}
				</Stack>
			)}
		</Box>
	);
}
