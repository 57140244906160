import React, { Dispatch, SetStateAction } from "react";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { Box, Button, Stack } from "@mui/material";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { formatCurrency } from "../../../infrastructure/utils/FormatUtils";
import { formatDateStringOnly } from "../../../infrastructure/utils/FormatMask";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import {
	DownloadRounded,
	EditOutlined,
	ReceiptOutlined,
} from "@mui/icons-material";
import { paletteObject } from "../../../theme/foundations/palette";
import { LegacyOrderStatus } from "../../../domain/enums/LegacyOrderStatus";
import CopyToClipboard from "../../../components/CopyToClipboard";

type Props = {
	order: OrderModel | null;
	isLoading: boolean;
	setIsInvoiceDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export default function OrderInvoice({
	order,
	isLoading,
	setIsInvoiceDialogOpen,
}: Props) {
	const isCanceledOrFinalized = (orderObject: OrderModel): boolean => {
		const canceledOrFinalizedStatuses = [
			LegacyOrderStatus.CanceledByAdmin,
			LegacyOrderStatus.CanceledByBuyer,
			LegacyOrderStatus.CanceledByProcessor,
			LegacyOrderStatus.CanceledBySeller,
			LegacyOrderStatus.Finalized,
		];

		return canceledOrFinalizedStatuses.includes(
			orderObject.status as LegacyOrderStatus
		);
	};

	return (
		<Box className="card" p={3}>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<>
					<CustomTypography variant="secondaryTitle" isBold marginBottom={2}>
						Faturamento
					</CustomTypography>
					{!order.invoice || !order.invoice.number ? (
						<Stack
							direction="column"
							height="100%"
							justifyContent="center"
							alignItems={"center"}
							spacing={2}
						>
							<CustomTypography
								variant="default"
								sx={{ textAlign: "center" }}
								color="blueish20"
							>
								Este pedido ainda não possui uma nota fiscal anexada.
							</CustomTypography>
							{!isCanceledOrFinalized(order) && (
								<Button
									variant="contained"
									color="grey30"
									onClick={() => setIsInvoiceDialogOpen(true)}
									sx={{
										color: paletteObject.grey70.main,
										width: "fit-content",
										minWidth: "unset",
									}}
								>
									<CustomIcon
										icon={<ReceiptOutlined />}
										variant="default"
										color="grey70"
										sx={{ mr: 1 }}
									/>
									Faturar pedido
								</Button>
							)}
						</Stack>
					) : (
						<>
							<Stack direction="column" spacing={1} mb={2}>
								<CustomTypography variant="default" color="blueish20">
									<strong>Valor da nota: </strong>
									{formatCurrency(order.invoice.totalAmount)}
								</CustomTypography>
								<CustomTypography variant="default" color="blueish20">
									<strong>Número da nota: </strong>
									{order.invoice.number}
									<CopyToClipboard textToCopy={order.invoice.number} />
								</CustomTypography>
								<CustomTypography variant="default" color="blueish20">
									<strong>Série: </strong>
									{order.invoice.serieNumber}
								</CustomTypography>
								{order.invoice.key && (
									<CustomTypography
										variant="default"
										color="blueish20"
										sx={{ wordBreak: "break-all" }}
									>
										<strong>Chave: </strong>
										{order.invoice.key}
										<CopyToClipboard textToCopy={order.invoice.key} />
									</CustomTypography>
								)}
								<CustomTypography variant="default" color="blueish20">
									<strong>Data de emissão: </strong>
									{formatDateStringOnly(order.invoice.issuanceDate)}
								</CustomTypography>
							</Stack>
							<Stack direction="row" spacing={1}>
								{!isCanceledOrFinalized(order) && (
									<Button
										variant="contained"
										color="grey30"
										onClick={() => setIsInvoiceDialogOpen(true)}
										sx={{
											color: paletteObject.grey70.main,
											width: "fit-content",
											minWidth: "unset",
										}}
									>
										<CustomIcon
											icon={<EditOutlined />}
											variant="default"
											color="grey70"
											sx={{ mr: 1 }}
										/>
										Alterar nota fiscal
									</Button>
								)}
								{(order.invoice.fileUrl || order.invoice.xmlUrl) && (
									<Button
										variant="contained"
										color="info"
										onClick={() => {
											if (order.invoice?.fileUrl) {
												window.open(order.invoice.fileUrl);
												return;
											}
											if (order.invoice?.xmlUrl)
												window.open(order.invoice.xmlUrl);
										}}
										sx={{
											width: "fit-content",
											minWidth: "unset",
										}}
									>
										<CustomIcon
											icon={<DownloadRounded />}
											variant="default"
											sx={{ mr: 1 }}
										/>
										Baixar DANFE
									</Button>
								)}
							</Stack>
						</>
					)}
				</>
			)}
		</Box>
	);
}
