import React from "react";

import SalesPersonForm from "./components/SalesPersonForm";
import { useConfig } from "./../../providers/ConfigProvider";
import LoadDashboard from "../Dashboard/components/LoadDashboard";
import CustomContainer from "../../MUICustomComponents/CustomContainer";

export default function SalesPerson() {
	const { mainLoading } = useConfig();
	return (
		<>
			{mainLoading ? (
				<LoadDashboard />
			) : (
				<CustomContainer>
					<SalesPersonForm />
				</CustomContainer>
			)}
		</>
	);
}
