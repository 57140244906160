import { Box, Stack } from "@mui/material";
import React from "react";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { formatDocumentCnpj } from "../../../infrastructure/utils/FormatMask";
import { applyMask } from "../../../infrastructure/utils/FormatUtils";
import { InputMasks } from "../../../infrastructure/utils/InputMasks";

type Props = { order: OrderModel | null; isLoading: boolean };

export default function OrderSeller({ order, isLoading }: Props) {
	return (
		<Box className="card" p={3}>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<>
					<CustomTypography variant="secondaryTitle" isBold marginBottom={2}>
						Informações do Seller
					</CustomTypography>
					<CustomTypography variant="default" color="blueish20" isBold>
						{order.seller.fullName}
					</CustomTypography>
					<Stack direction="column" spacing={1} mt={2}>
						{order.seller.companyName && (
							<CustomTypography variant="default" color="blueish20" isBold>
								{order.seller.companyName}
							</CustomTypography>
						)}
						<CustomTypography variant="default" color="blueish20" isBold>
							{formatDocumentCnpj(order.seller.document)}
						</CustomTypography>
						<CustomTypography variant="default" color="blueish20">
							{order.seller.address.street}
							{order.seller.address.number &&
								`, ${order.seller.address.number}`}
							{order.seller.address.complement &&
								`, ${order.seller.address.complement}`}
							{order.seller.address.neighborhood &&
								` - ${order.seller.address.neighborhood}`}
							{order.seller.address.city && ` - ${order.seller.address.city}`}
							{order.seller.address.state && ` - ${order.seller.address.state}`}
						</CustomTypography>
						{order.seller.address.zipCode && (
							<CustomTypography variant="default" color="blueish20">
								CEP:{" "}
								{applyMask(order.seller.address.zipCode, InputMasks.ZipCode)}
							</CustomTypography>
						)}
					</Stack>
				</>
			)}
		</Box>
	);
}
