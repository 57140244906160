import React, { useEffect, useState } from "react";
import {
	Box,
	Divider,
	Link,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { CustomDataModel } from "../../domain/models/commerce/CustomDataModel";
import {
	isValidUrl,
	isValueInStringEnum,
} from "../../infrastructure/utils/TypeValidator";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { i18n } from "../../translate/i18n";
import { AttachmentKeys } from "./AttachmentKeys";
import { paletteObject } from "../../theme/foundations/palette";

type Props = {
	orderModel: OrderModel;
};

export default function OrderAttachments({ orderModel }: Props) {
	const [attachmentList, setAttachmentList] = useState<CustomDataModel[]>([]);

	useEffect(() => {
		if (!orderModel?.customData) return;
		createAttachmentList(orderModel.customData);
	}, [orderModel]);

	const createAttachmentList = (customData: CustomDataModel[]): void => {
		setAttachmentList(customData);
	};

	const getAttachmentTitle = (itemKey: string): string => {
		if (isValueInStringEnum(AttachmentKeys, itemKey))
			return i18n.t(`attachments.${itemKey}`);
		return itemKey;
	};

	const attachmentItem = (item: CustomDataModel) => (
		<TableRow key={item.key}>
			<TableCell
				component="th"
				scope="row"
				sx={{ width: "30%", whiteSpace: "nowrap", paddingLeft: 3 }}
			>
				<CustomTypography variant="default" color="blueish20" isBold>
					{getAttachmentTitle(item.key)}
				</CustomTypography>
			</TableCell>
			<TableCell>
				{isValidUrl(item.value, false) ? (
					<Link
						href={item.value}
						underline="none"
						color="inherit"
						target="_blank"
						rel="noreferrer"
					>
						<Stack
							direction="row"
							py={0.5}
							px={2}
							alignItems="center"
							spacing={0.5}
							sx={{
								width: "fit-content",
								border: `1px solid ${paletteObject.blueish20.main}`,
								borderRadius: 1,
							}}
						>
							<CustomTypography variant="default" color="blueish20" isBold>
								{i18n.t("attachments.Open").toString()}
							</CustomTypography>
							<CustomIcon
								icon={<OpenInNewIcon />}
								variant="small"
								color="blueish20"
								sx={{ mt: "2px" }}
							/>
						</Stack>
					</Link>
				) : (
					<CustomTypography
						variant="default"
						color="grey90"
						sx={{ whiteSpace: "pre-wrap" }}
					>
						{item.value}
					</CustomTypography>
				)}
			</TableCell>
		</TableRow>
	);

	return (
		<>
			{attachmentList.length > 0 && (
				<>
					<Divider sx={{ my: 3 }} />
					<Box py={1} borderRadius={1} bgcolor={paletteObject.bgNeo.main}>
						<CustomTypography variant="secondaryTitle" sx={{ py: 2, px: 3 }}>
							{i18n.t("attachments.Title").toString()}
						</CustomTypography>
						<Divider />
						<Box
							sx={(theme) => ({
								overflowX: "auto",
								"&::-webkit-scrollbar": {
									width: "6px",
									height: "6px",
									backgroundColor: "transparent",
								},
								"&::-webkit-scrollbar-track": {
									borderRadius: "10px",
									backgroundColor: paletteObject.grey20.main,
								},
								"&::-webkit-scrollbar-thumb": {
									borderRadius: "10px",
									backgroundColor: paletteObject.grey50.main,
									height: "40px",
								},
							})}
						>
							<Table sx={{ minWidth: 450 }}>
								<TableBody>{attachmentList?.map(attachmentItem)}</TableBody>
							</Table>
						</Box>
					</Box>
				</>
			)}
		</>
	);
}
