import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { i18n } from "../../../translate/i18n";
import { buildURLWithParams } from "../../../infrastructure/utils/BuildURLWithParams";
import NewToastComponent from "../../../components/NewToastComponent";
import { retailerManagementApi } from "../../../infrastructure/api/RetailerManagementApi";
import { ErrorResponse } from "../../../infrastructure/api/ErrorResponse";

export const DealersProvider = createContext({});

export default function DealersContext(props) {
	const params = new URL(document.location).searchParams;
	const pageParam = useParams().page;
	const [page, setPage] = useState(pageParam || "1");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [query, setQuery] = useState(params.get("query") || "");
	const [dealerFilters, setDealerFilters] = useState({
		Office: params.get("role") ? params.get("role") : "",
	});
	const [dealersList, setDealersList] = useState([]);
	const [roleClient, setRoleClient] = useState("");
	const [loading, setLoading] = useState(true);
	const [messageToast, setMessageToast] = useState("");
	const optionsDict = [
		{
			value: "",
			label: i18n.t("staticMessage.SelectMessage.ShowingAll"),
		},
		{
			value: "Salesperson",
			label: i18n.t("staticMessage.SelectMessage.Salesperson"),
		},
		{
			value: "ResponsibleSeller",
			label: i18n.t("staticMessage.SelectMessage.ResponsibleSeller"),
		},
		{
			value: "Supervisor",
			label: i18n.t("staticMessage.SelectMessage.Supervisor"),
		},
		{
			value: "Coordinator",
			label: i18n.t("staticMessage.SelectMessage.Coordinator"),
		},
		{
			value: "AssistantManager",
			label: i18n.t("staticMessage.SelectMessage.AssistantManager"),
		},
		{
			value: "Manager",
			label: i18n.t("staticMessage.SelectMessage.Manager"),
		},
		{
			value: "Partner",
			label: i18n.t("staticMessage.SelectMessage.Partner"),
		},
	];

	useEffect(() => {
		getDealersList();
	}, [query, dealerFilters]);

	const getDealersList = async () => {
		setLoading(true);
		setRoleClient(localStorage.getItem("role"));

		let queryParams = {
			page: page,
			perpage: rowsPerPage,
			query: query,
			role: dealerFilters.Office,
		};

		try {
			const data = await retailerManagementApi.getSalesPersons(
				buildURLWithParams(queryParams)
			);
			setDealersList(data);
		} catch (error) {
			if (error instanceof ErrorResponse) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: error.message,
				});
				return;
			}
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.ERR.REQUEST"),
			});
			setDealersList([]);
		}

		setLoading(false);
	};

	const getSearch = (query) => {
		setQuery(query);
	};

	const deleteDealer = async (id) => {
		try {
			const success = await retailerManagementApi.deleteSalesPerson(id);

			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.ERR.REQUEST"),
				});
				return;
			}
			window.location.reload();
			localStorage.setItem("deleteDealer", true);
		} catch (error) {
			if (error instanceof ErrorResponse) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: error.message,
				});
				return;
			}
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
	};

	const contextValue = useMemo(
		() => ({
			dealersList,
			loading,
			messageToast,
			roleClient,
			setMessageToast,
			setLoading,
			getSearch,
			page,
			setPage,
			rowsPerPage,
			setRowsPerPage,
			query,
			setQuery,
			optionsDict,
			dealerFilters,
			setDealerFilters,
			getDealersList,
			deleteDealer,
		}),
		[
			dealersList,
			loading,
			messageToast,
			roleClient,
			setMessageToast,
			setLoading,
			getSearch,
			page,
			setPage,
			rowsPerPage,
			setRowsPerPage,
			query,
			setQuery,
			optionsDict,
			dealerFilters,
			setDealerFilters,
			getDealersList,
			deleteDealer,
		]
	);

	return (
		<DealersProvider.Provider value={contextValue}>
			{props.children}
		</DealersProvider.Provider>
	);
}

export const useDealers = () => useContext(DealersProvider);
