import {
	getEstimateDate,
	showOrderCode,
} from "../../../infrastructure/utils/OrdersUtils";
import {
	getSafeStringValue,
	normalizeDigitsOnly,
} from "../../../infrastructure/utils/StringUtils";
import { LegacyCustomDataDto } from "../../dtos/legacyCommerceManagement/LegacyCustomDataDto";
import { LegacyItemDto } from "../../dtos/legacyCommerceManagement/LegacyItemDto";
import { LegacyOrderDto } from "../../dtos/legacyCommerceManagement/LegacyOrderDto";
import { CustomDataDto } from "../../dtos/smOrders/CustomDataDto";
import { ItemDto } from "../../dtos/smOrders/items/ItemDto";
import { OrderDto } from "../../dtos/smOrders/OrderDto";
import { NumericDocumentType } from "../../enums/DocumentType";
import { CustomDataModel } from "../../models/commerce/CustomDataModel";
import { ItemModel } from "../../models/commerce/Items/ItemModel";
import { OrderModel } from "../../models/commerce/OrderModel";

const getProductName = (
	skuName: string | null,
	productName: string | null
): string => {
	if (skuName && productName) {
		return skuName.toLowerCase().includes(productName.toLowerCase())
			? skuName
			: `${productName} - ${skuName}`;
	}
	return skuName || productName || "";
};

const legacyToOrderModel = (order: LegacyOrderDto): OrderModel => {
	const buyerDocument = normalizeDigitsOnly(
		order.buyer.cpf ?? order.buyer.cnpj
	);

	const orderCode: string = showOrderCode(
		order.friendlyCode,
		order?.externalId,
		order?.sellerOrderId
	);

	const customData: CustomDataModel[] = order.customData.map(
		(data: LegacyCustomDataDto): CustomDataModel => ({
			key: data.key,
			value: data.value,
		})
	);

	const getDocumentType = (): NumericDocumentType => {
		if (buyerDocument.length === 11) return NumericDocumentType.Cpf;
		if (buyerDocument.length === 14) return NumericDocumentType.Cnpj;
		return NumericDocumentType.None;
	};

	const itemQuantity: number = order.items.reduce(
		(acc, currentValue) => acc + currentValue.quantity,
		0
	);

	const items: ItemModel[] = order.items.map((item: LegacyItemDto) => {
		const name: string = getProductName(item.sku.name, item.sku.product.name);

		if (item.sku.barcode === "null") item.sku.barcode = null;

		return {
			id: item.id,
			ean: item.sku.barcode,
			name: name,
			productCode: item.sku.product.code,
			quantity: item.quantity,
			skuCode: item.sku.code,
			sku: item.sku,
			unitPrice: item.unitPrice,
			discountPrice: item.discountPrice,
			totalAmount: item.totalAmount,
			totalPrice: item.totalPrice,
		};
	});

	const genericOrder: OrderModel = {
		actionLogs: order.actionLogs,
		id: order.id,
		orderCode: orderCode,
		sellerOrderId: order.sellerOrderId,
		externalId: order.externalId,
		createDate: order.createDate,
		lastUpdate: order.actionLogs[order.actionLogs.length - 1].lastUpdate,
		origin: order.channel,
		isDelivery: order.isDelivery,
		estimateDate: getEstimateDate(order.freight?.estimateDate),
		customData: customData,
		status: order.status,
		itemQuantity: itemQuantity,
		subTotalAmount: order.subTotalAmount,
		discountAmount: order.discountAmount,
		feeAmount: order.feeAmount,
		freightAmount: order.freightAmount,
		totalAmount: order.totalAmount,
		plp: order.plp,
		items: items,
		cancellationReason: order?.cancellationReason || null,
		dealerCode: order.dealerCode,
		steps: order.steps,
		buttons: order.buttons,
		freight: order.freight,
		promotion: order.voucher,
		buyer: {
			fullName: order.buyer.fullName,
			dateOfBirth: order.buyer.dateOfBirth,
			phoneNumber: order.buyer.phoneNumber,
			companyName: order.buyer.companyName,
			document: buyerDocument,
			documentType: getDocumentType(),
			email: order.buyer.email,
			stateInscription: order.buyer.stateInscription,
			address: order.buyer.address
				? {
						city: order.buyer.address.city,
						complement: order.buyer.address.complement,
						neighborhood: order.buyer.address.neighborhood,
						number: order.buyer.address.number,
						state: order.buyer.address.state,
						street: order.buyer.address.street,
						zipCode: normalizeDigitsOnly(order.buyer.address.cep),
						reference: order.buyer.address.reference,
						fullAddress: order.buyer.address.fullAddress,
				  }
				: null,
		},
		seller: {
			fullName: order.seller.fullName,
			email: order.seller.email,
			document: order.seller.cnpj,
			companyName: order.seller.companyName,
			address: {
				city: getSafeStringValue(order.seller.address?.city),
				complement: getSafeStringValue(order.seller.address?.complement),
				neighborhood: getSafeStringValue(order.seller.address?.neighborhood),
				number: getSafeStringValue(order.seller.address?.number),
				state: getSafeStringValue(order.seller.address?.state),
				street: getSafeStringValue(order.seller.address?.street),
				zipCode: getSafeStringValue(order.seller.address?.cep),
				fullAddress: getSafeStringValue(order.seller.address?.fullAddress),
			},
		},
		shippingData: {
			deliveryAddress: order.isDelivery
				? {
						city: order.deliveryAddress.city,
						neighborhood: order.deliveryAddress.neighborhood,
						number: order.deliveryAddress.number,
						state: order.deliveryAddress.state,
						street: order.deliveryAddress.street,
						zipCode: normalizeDigitsOnly(order.deliveryAddress.cep),
						complement: order.deliveryAddress.complement,
						fullAddress: order.deliveryAddress.fullAddress,
				  }
				: null,
		},
		pickingInfo: null,
		tracking: order.tracking,
		invoice: order.invoice,
		paymentInfo: order.paymentInfo,
		paymentList: order.paymentList,
	};

	return genericOrder;
};

const smToOrderModel = (order: OrderDto): OrderModel => {
	const orderCode: string = showOrderCode(
		order.FriendlyCode,
		order?.ExternalId,
		order?.SellerOrderId
	);

	const customData: CustomDataModel[] = order.CustomData.map(
		(data: CustomDataDto): CustomDataModel => ({
			key: data.Key,
			value: data.Value,
		})
	);

	const itemQuantity: number = order.Items.reduce(
		(acc, currentValue) => acc + currentValue.Quantity,
		0
	);

	const items: ItemModel[] = order.Items.map((item: ItemDto) => {
		return {
			id: item.Sku.Code,
			ean: item.Sku.BarCode === "null" ? null : item.Sku.BarCode,
			name: item.Sku.Name,
			productCode: item.Sku.ProductCode ?? "",
			quantity: item.Quantity,
			skuCode: item.Sku.Code,
			sku: null,
			unitPrice: item.UnitPrice,
			discountPrice: item.DiscountPrice,
			totalAmount: item.TotalAmount,
			totalPrice: 0,
		};
	});

	const genericOrder: OrderModel = {
		actionLogs: null,
		id: order.id,
		orderCode: orderCode,
		sellerOrderId: order.SellerOrderId,
		externalId: order.ExternalId,
		createDate: order.CreateDate,
		lastUpdate: "",
		origin: order.Origin,
		isDelivery: order.IsDelivery,
		estimateDate: getEstimateDate(
			order.ShippingData.Shipment.EstimatedDeliveryDate
		),
		customData: customData,
		status: order.Status,
		itemQuantity: itemQuantity,
		subTotalAmount: 0,
		discountAmount: order.Totals.DiscountAmount,
		feeAmount: order.Totals.FeeAmount,
		freightAmount: order.Totals.FreightAmount,
		totalAmount: order.Totals.TotalAmount,
		plp: null,
		items: items,
		cancellationReason: order?.CancellationReason || null,
		dealerCode: null,
		steps: [],
		buttons: [],
		freight: null,
		promotion: null,
		buyer: {
			fullName: order.Buyer.FullName,
			phoneNumber: order.Buyer.PhoneNumber,
			companyName: null,
			document: order.Buyer.Document,
			documentType: NumericDocumentType[order.Buyer.DocumentType],
			email: order.Buyer.Email,
			stateInscription: null,
			address: null,
			dateOfBirth: null,
		},
		seller: {
			fullName: getSafeStringValue(order.Seller?.FullName),
			email: getSafeStringValue(order.Seller?.Email),
			document: getSafeStringValue(order.Seller?.Document),
			companyName: getSafeStringValue(order.Seller?.CompanyName),
			address: {
				city: getSafeStringValue(order.Seller?.Address.City),
				complement: getSafeStringValue(order.Seller?.Address.Complement),
				neighborhood: getSafeStringValue(order.Seller?.Address.Neighborhood),
				number: getSafeStringValue(order.Seller?.Address.Number),
				state: getSafeStringValue(order.Seller?.Address.State),
				street: getSafeStringValue(order.Seller?.Address.Street),
				zipCode: getSafeStringValue(order.Seller?.Address.ZipCode),
				fullAddress: getSafeStringValue(order.Seller?.Address.FullAddress),
			},
		},
		shippingData: {
			deliveryAddress: order.IsDelivery
				? {
						city: order.ShippingData.DeliveryAddress.City,
						complement: getSafeStringValue(
							order.ShippingData.DeliveryAddress.Complement
						),
						neighborhood: order.ShippingData.DeliveryAddress.Neighborhood,
						number: order.ShippingData.DeliveryAddress.Number,
						state: order.ShippingData.DeliveryAddress.State,
						street: order.ShippingData.DeliveryAddress.Street,
						zipCode: order.ShippingData.DeliveryAddress.ZipCode,
						fullAddress: order.ShippingData.DeliveryAddress.FullAddress,
				  }
				: null,
		},
		pickingInfo: order.PickingInfo
			? {
					packagesQuantity: order.PickingInfo.PackagesQuantity,
					picker: {
						code: order.PickingInfo.Picker.Code,
						document: order.PickingInfo.Picker.Document,
						name: order.PickingInfo.Picker.Name,
					},
			  }
			: null,
		tracking: null,
		invoice: null,
		paymentInfo: null,
		paymentList: [],
	};

	return genericOrder;
};

export const OrderMapper = { legacyToOrderModel, smToOrderModel };
