import React from "react";
import Invoice from "./Invoice.jsx";
import { Button, Stack } from "@mui/material";
import { Print } from "@mui/icons-material";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { paletteObject } from "../../theme/foundations/palette";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { getSafeStringValue } from "../../infrastructure/utils/StringUtils";
import { formatDate } from "../../infrastructure/utils/FormatMask.jsx";
import { i18n } from "../../translate/i18n.jsx";
import { FraudStatus } from "../../domain/enums/Orders/FraudStatus";
import { getValueOrDefault } from "../../infrastructure/utils/getValueOrDefault";

type Props = { order: OrderModel; isLoading: boolean };

export default function CreateOrderSummary({ order, isLoading }: Props) {
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];

	const information = {
		delivery: order.shippingData.deliveryAddress,
		productDisplayCodeName: configurations.productDisplayCodeName,
		amount: {
			feeAmount: order.feeAmount,
			discountAmount: order.discountAmount,
			freightAmount: order.freightAmount,
			totalAmount: order.totalAmount,
		},
		order: {
			friendlyCode: order.orderCode,
			createDate: formatDate(order.createDate),
			modality:
				localStorage.getItem("role") === "8"
					? getSafeStringValue(order.freight?.description)
					: i18n.t(`newOrderDetails.IsDelivery.${order.isDelivery}`),
		},
		paymentInfo: {
			info: getSafeStringValue(order.paymentInfo?.paymentMethod?.info),
			acquirerMessage: getSafeStringValue(order.paymentInfo?.acquirerMessage),
			fraudStatus: i18n.t(
				`orderPayments.FraudStatus.${
					FraudStatus[getValueOrDefault(order.paymentInfo?.fraudStatus, 0)]
				}`
			),
			acquirerApprovalCode: getSafeStringValue(
				order.paymentInfo?.acquirerApprovalCode
			),
			nsu: getSafeStringValue(order.paymentInfo?.nsu),
			paymentStatus: getValueOrDefault(order.paymentInfo?.paymentStatus, 0),
			quantityInstallments: getValueOrDefault(
				order.paymentInfo?.quantityInstallments,
				0
			),
		},
		seller: {
			address: {
				cep: order.seller.address.zipCode,
				street: order.seller.address.street,
				number: order.seller.address.number,
				complement: order.seller.address.complement,
				neighborhood: order.seller.address.neighborhood,
				city: order.seller.address.city,
				state: order.seller.address.state,
			},
			cnpj: order.seller.document,
			email: order.seller.email,
			fullName: order.seller.fullName,
		},
		buyer: {
			address: {
				cep: getSafeStringValue(order.buyer.address?.zipCode),
				street: getSafeStringValue(order.buyer.address?.street),
				number: getSafeStringValue(order.buyer.address?.number),
				complement: getSafeStringValue(order.buyer.address?.complement),
				neighborhood: getSafeStringValue(order.buyer.address?.neighborhood),
				city: getSafeStringValue(order.buyer.address?.city),
				state: getSafeStringValue(order.buyer.address?.state),
			},
			fullName: getSafeStringValue(order.buyer.fullName),
			cpf: getSafeStringValue(order.buyer.document),
			email: getSafeStringValue(order.buyer.email),
		},
		image:
			localStorage.getItem("primaryLogo") === "undefined"
				? ""
				: localStorage.getItem("primaryLogo"),
	};

	return (
		<Button
			variant="contained"
			color="grey30"
			disabled={isLoading}
			onClick={() => Invoice(information, order.items)}
			sx={{ maxWidth: "190px" }}
		>
			<Stack
				direction="row"
				alignItems="center"
				color={paletteObject.grey70.main}
			>
				{isLoading ? (
					<LoadingButtonIcon />
				) : (
					<>
						<CustomIcon
							icon={<Print />}
							variant="default"
							color="grey70"
							sx={{ mt: 0.5, mr: 1 }}
						/>
						Imprimir pedido
					</>
				)}
			</Stack>
		</Button>
	);
}
