import {
	Box,
	Divider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import React from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import TotalSummary from "./TotalSummary";
import { formatCurrency } from "../../../infrastructure/utils/FormatUtils";
import { showProductDisplayCodeName } from "../../../infrastructure/utils/OrdersUtils";
import { paletteObject } from "../../../theme/foundations/palette";
import { ItemModel } from "../../../domain/models/commerce/Items/ItemModel";
import OrderAttachments from "../../../components/OrderAttachments/OrderAttachments";
import CopyToClipboard from "../../../components/CopyToClipboard";

type Props = { order: OrderModel | null; isLoading: boolean };

export default function OrderItems({ order, isLoading }: Props) {
	const renderTableRow = (item: ItemModel): JSX.Element => {
		const productDisplayCodeName = showProductDisplayCodeName(
			item.sku?.product.code,
			item.sku?.product.referenceCode,
			item.sku?.product.auxCode
		);

		return (
			<TableRow key={item.id}>
				<TableCell sx={{ width: "40px" }}>
					<img
						src={
							item.sku?.imageUrl ||
							item.sku?.product.imageUrl ||
							"/images/placeholder.jpg"
						}
						height="40px"
						width="40px"
					/>
				</TableCell>
				<TableCell>
					<Stack direction={"column"}>
						<span>{item.sku?.productSku}</span>
						<Stack
							direction="row"
							spacing={1}
							mt={0.5}
							sx={{
								color: paletteObject.grey60.main,
								fontSize: "12px",
							}}
						>
							{productDisplayCodeName && (
								<span>
									{`Cód Produto: ${productDisplayCodeName}`}
									<CopyToClipboard textToCopy={productDisplayCodeName} />
								</span>
							)}
							{productDisplayCodeName && item.sku?.code && (
								<Divider
									orientation="vertical"
									sx={{
										height: "auto",
										borderColor: paletteObject.grey60.main,
									}}
								/>
							)}
							{item.sku?.code && (
								<span>
									{`Cód Sku: ${item.sku.code}`}
									<CopyToClipboard textToCopy={item.sku.code} />
								</span>
							)}
							{item.sku?.code && item.sku?.barcode && (
								<Divider
									orientation="vertical"
									sx={{
										height: "auto",
										borderColor: paletteObject.grey60.main,
									}}
								/>
							)}
							{item.sku?.barcode && (
								<span>
									{`EAN: ${item.sku.barcode}`}
									<CopyToClipboard textToCopy={item.sku.barcode} />
								</span>
							)}
						</Stack>
					</Stack>
				</TableCell>
				<TableCell align="center">{item.quantity}</TableCell>
				<TableCell align="center">{formatCurrency(item.unitPrice)}</TableCell>
				<TableCell align="center">
					{formatCurrency(item.discountPrice)}
				</TableCell>
				<TableCell align="center">{formatCurrency(item.totalAmount)}</TableCell>
			</TableRow>
		);
	};

	return (
		<Box className="card" p={3}>
			<CustomTypography variant="secondaryTitle" isBold marginBottom={2}>
				Itens do Pedido
			</CustomTypography>

			{isLoading || !order ? (
				<CustomSkeleton quantity={6} height={30} />
			) : (
				<>
					<Divider />
					<Box
						sx={(theme) => ({
							[theme.breakpoints.down("md")]: {
								width: "100%",
								overflowX: "auto",
								"&::-webkit-scrollbar": {
									width: "6px",
									height: "6px",
									backgroundColor: "transparent",
								},
								"&::-webkit-scrollbar-track": {
									borderRadius: "10px",
									backgroundColor: paletteObject.grey20.main,
								},
								"&::-webkit-scrollbar-thumb": {
									borderRadius: "10px",
									backgroundColor: paletteObject.grey50.main,
									height: "40px",
								},
							},
						})}
					>
						<Table sx={{ minWidth: 650 }}>
							<TableHead>
								<TableRow sx={{ textTransform: "uppercase" }}>
									<TableCell sx={{ maxWidth: "60px" }} />
									<TableCell>Produto</TableCell>
									<TableCell align="center">QTD</TableCell>
									<TableCell align="center">Preço Un.</TableCell>
									<TableCell align="center">DESC.</TableCell>
									<TableCell align="center">Total</TableCell>
								</TableRow>
							</TableHead>
							<TableBody
								sx={{
									".MuiTableCell-root": { color: paletteObject.blueish20.main },
								}}
							>
								{order.items.map((item: ItemModel) => renderTableRow(item))}
							</TableBody>
						</Table>
					</Box>
					<Stack direction="row" justifyContent="end" mt={2}>
						<TotalSummary order={order} />
					</Stack>
					<OrderAttachments orderModel={order} />
				</>
			)}
		</Box>
	);
}
