import React, { Dispatch, SetStateAction } from "react";
import CustomDialog from "../../../../MUICustomComponents/CustomDialog";
import { OrderModel } from "../../../../domain/models/commerce/OrderModel";
import InvoiceDialogService from "./InvoiceDialogService";
import { Grid } from "@mui/material";
import { InvoiceInputs } from "./InvoiceInputs";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import { i18n } from "../../../../translate/i18n";
import { FormValidator } from "../../../../infrastructure/utils/FormUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import DropzoneInvoice from "./DropzoneInvoice";

type Props = {
	orderModel: OrderModel;
	isDialogOpen: boolean;
	setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
	updateCallback: Function;
};

export default function InvoiceDialog({
	orderModel,
	isDialogOpen,
	setIsDialogOpen,
	updateCallback,
}: Props) {
	const {
		invoiceData,
		dateValue,
		formErrors,
		isLoadingUpdate,
		handleOnChange,
		onChangeDate,
		handleFileDrop,
		handleSubmit,
		onCloseCallback,
	} = InvoiceDialogService(orderModel, updateCallback, setIsDialogOpen);

	const renderInputField = (fieldName: InvoiceInputs) => {
		return (
			<CustomInput
				name={fieldName}
				title={i18n.t(`invoiceDialog.${fieldName}`)}
				placeholder={i18n.t(`invoiceDialog.${fieldName}`)}
				value={invoiceData[fieldName]}
				onChange={handleOnChange}
				{...(fieldName === InvoiceInputs.SerieNumber && { type: "number" })}
				disabled={isLoadingUpdate}
				error={FormValidator.hasInputError(formErrors, fieldName)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					fieldName
				)}
			/>
		);
	};

	return (
		<CustomDialog
			isOpen={isDialogOpen}
			setIsOpen={setIsDialogOpen}
			closeCallback={onCloseCallback}
			dialogInfo={{
				title: i18n.t("invoiceDialog.Title"),
				subtitle: i18n.t("invoiceDialog.Subtitle"),
			}}
			actionButton={{
				text: i18n.t("invoiceDialog.InvoiceOrder"),
				variant: "contained",
				color: "success",
				onClick: handleSubmit,
				isDisabled: isLoadingUpdate || FormValidator.hasError(formErrors),
				isLoading: isLoadingUpdate,
			}}
			dialogStyles={{
				titleVariant: "title",
				maxWidth: "md",
				dialogTitleStyles: { pt: 2 },
				actionStyles: { pb: 2 },
			}}
		>
			<Grid container columnSpacing={2}>
				<Grid item xs={12} sm={6}>
					<DropzoneInvoice
						id={orderModel.id}
						onChange={handleFileDrop}
						formDropFile={invoiceData}
						updateCallback={updateCallback}
					/>
				</Grid>
				<Grid item container xs={12} sm={6} spacing={2} pt={"30px"}>
					<Grid item xs={8}>
						{renderInputField(InvoiceInputs.Number)}
					</Grid>
					<Grid item xs={4}>
						{renderInputField(InvoiceInputs.SerieNumber)}
					</Grid>
					<Grid item xs={12}>
						{renderInputField(InvoiceInputs.Key)}
					</Grid>
					<Grid item xs={12}>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={"pt-br"}
						>
							<DatePicker
								label="Data de emissão"
								value={dateValue}
								onChange={onChangeDate}
								disabled={isLoadingUpdate}
								sx={{
									width: "100%",
									".MuiInputBase-formControl": { height: "37px" },
								}}
								{...(FormValidator.hasInputError(
									formErrors,
									InvoiceInputs.IssuanceDate
								) && {
									slotProps: {
										textField: {
											error: true,
											helperText: "Campo obrigatório.",
										},
									},
								})}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
			</Grid>
		</CustomDialog>
	);
}
