import React, { useState } from "react";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { Box, IconButton, Stack } from "@mui/material";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import {
	formatDateStringOnly,
	formatDocument,
} from "../../../infrastructure/utils/FormatMask";
import {
	mapNumericToStringDocumentType,
	NumericDocumentType,
} from "../../../domain/enums/DocumentType";
import { applyMask } from "../../../infrastructure/utils/FormatUtils";
import { countryMaskRules } from "../../../components/InternationalPhoneInput/CountryMaskRules";
import { InputMasks } from "../../../infrastructure/utils/InputMasks";
import BuyerEditDialog from "../Dialogs/BuyerEditDialog/BuyerEditDialog";
import { WhatsApp } from "@mui/icons-material";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import CopyToClipboard from "../../../components/CopyToClipboard";

type Props = {
	order: OrderModel | null;
	isLoading: boolean;
	updateOrderCallback: () => void;
};

export default function OrderClient({
	order,
	isLoading,
	updateOrderCallback,
}: Props) {
	const [isBuyerEditOpen, setIsBuyerEditOpen] = useState<boolean>(false);

	const renderClientData = (orderObj: OrderModel): JSX.Element => {
		return (
			<Stack direction="column" spacing={1}>
				{orderObj.buyer.fullName && (
					<CustomTypography variant="default" color="blueish20" isBold>
						{orderObj.buyer.fullName}
						<CopyToClipboard textToCopy={orderObj.buyer.fullName} />
					</CustomTypography>
				)}
				{orderObj.buyer.dateOfBirth && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Data de nascimento: </strong>
						{formatDateStringOnly(orderObj.buyer.dateOfBirth)}
					</CustomTypography>
				)}
				{orderObj.buyer.documentType !== NumericDocumentType.None && (
					<CustomTypography variant="default" color="blueish20">
						<strong>
							{orderObj.buyer.documentType === NumericDocumentType.Cpf
								? "CPF: "
								: "CNPJ: "}
						</strong>
						{formatDocument(
							mapNumericToStringDocumentType[orderObj.buyer.documentType],
							orderObj.buyer.document
						)}
						<CopyToClipboard
							textToCopy={formatDocument(
								mapNumericToStringDocumentType[orderObj.buyer.documentType],
								orderObj.buyer.document
							)}
						/>
					</CustomTypography>
				)}
				{orderObj.buyer.email && (
					<CustomTypography
						variant="default"
						color="blueish20"
						sx={{ wordBreak: "break-all" }}
					>
						<strong>E-mail: </strong>
						{orderObj.buyer.email}
						<CopyToClipboard textToCopy={orderObj.buyer.email} />
					</CustomTypography>
				)}
				{orderObj.buyer.phoneNumber && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Telefone: </strong>
						{applyMask(
							orderObj.buyer.phoneNumber,
							countryMaskRules.BR.maskByLengthRule(
								orderObj.buyer.phoneNumber.length
							)
						)}
						<CopyToClipboard textToCopy={orderObj.buyer.phoneNumber} />
						<IconButton
							onClick={() =>
								window.open(`https://wa.me/55${orderObj.buyer.phoneNumber}`)
							}
							sx={{ p: 0, mt: -0.5, ml: 1 }}
						>
							<CustomIcon
								icon={<WhatsApp />}
								variant="custom"
								fontSize={"18px"}
								color="success"
							/>
						</IconButton>
					</CustomTypography>
				)}
				{orderObj.buyer.companyName && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Razão social: </strong>
						{orderObj.buyer.companyName}
					</CustomTypography>
				)}
				{orderObj.buyer.stateInscription && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Inscrição estadual: </strong>
						{orderObj.buyer.stateInscription}
					</CustomTypography>
				)}
				{orderObj.buyer.address && (
					<>
						<CustomTypography variant="default" color="blueish20" isBold>
							Endereço de faturamento:
							{orderObj.buyer.address.fullAddress && (
								<CopyToClipboard
									textToCopy={orderObj.buyer.address.fullAddress}
								/>
							)}
						</CustomTypography>
						<CustomTypography variant="default" color="blueish20">
							{orderObj.buyer.address.street}, {orderObj.buyer.address.number}
							{orderObj.buyer.address.complement &&
								`, ${orderObj.buyer.address.complement}`}
						</CustomTypography>
						<CustomTypography variant="default" color="blueish20">
							{orderObj.buyer.address.neighborhood},{" "}
							{orderObj.buyer.address.city}, {orderObj.buyer.address.state}
						</CustomTypography>
						{orderObj.buyer.address.reference && (
							<CustomTypography variant="default" color="blueish20">
								<strong>Referência: </strong>
								{orderObj.buyer.address.reference}
							</CustomTypography>
						)}
						{orderObj.buyer.address.zipCode && (
							<CustomTypography variant="default" color="blueish20">
								CEP:{" "}
								{applyMask(orderObj.buyer.address.zipCode, InputMasks.ZipCode)}
							</CustomTypography>
						)}
					</>
				)}
			</Stack>
		);
	};

	return (
		<Box className="card" p={3}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				mb={2}
			>
				<CustomTypography variant="secondaryTitle" isBold>
					Dados do cliente
				</CustomTypography>
				<a {...(!isLoading && { onClick: () => setIsBuyerEditOpen(true) })}>
					<CustomTypography
						variant="default"
						color="info"
						sx={{ textDecoration: "underline" }}
					>
						Alterar
					</CustomTypography>
				</a>
			</Stack>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				renderClientData(order)
			)}
			{order && (
				<BuyerEditDialog
					orderModel={order}
					isDialogOpen={isBuyerEditOpen}
					setIsDialogOpen={setIsBuyerEditOpen}
					updateCallback={updateOrderCallback}
				/>
			)}
		</Box>
	);
}
