import { ChangeEvent, useEffect, useState } from "react";
import { isValidString } from "../../../infrastructure/utils/TypeValidator";
import { normalizeDigitsOnly } from "../../../infrastructure/utils/StringUtils";
import { SelectChangeEvent } from "@mui/material";
import { AutocompleteSellerView } from "../../../domain/views/dealers/AutocompleteSellerView";
import { useHistory, useParams } from "react-router-dom";
import NewToastComponent from "../../../components/NewToastComponent";
import { i18n } from "../../../translate/i18n";
import { DocumentType } from "../../../domain/enums/DocumentType";
import { SalesPersonInputs } from "../enums/SalesPersonInputs";
import {
	FormErrors,
	FormValidator,
	InputValidator,
} from "../../../infrastructure/utils/FormUtils";
import { SelectOptions } from "../../../MUICustomComponents/CustomSelect";
import { SalesPerson } from "../../../domain/dtos/retailerManagement/salesPersons/SalesPerson";
import { retailerManagementApi } from "../../../infrastructure/api/RetailerManagementApi";
import { DefaultChannels } from "../../../domain/dtos/retailerManagement/salesPersons/DefaultChannels";
import { SalesChannel } from "../../../domain/dtos/retailerManagement/salesPersons/SalesChannel";
import { SalesPersonRole } from "../../../domain/dtos/retailerManagement/salesPersons/SalesPersonRole";
import { ErrorResponse } from "../../../infrastructure/api/ErrorResponse";

type ViewSalesPerson = Omit<SalesPerson, "commissionPercentage"> & {
	commissionPercentage: string;
};

export default function SalesPersonFormService() {
	const history = useHistory();
	const params = useParams();
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [currentSeller, setCurrentSeller] =
		useState<AutocompleteSellerView | null>(null);
	const [currentDocumentType, setCurrentDocumentType] = useState<DocumentType>(
		DocumentType.Cpf
	);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [salesPerson, setSalesPerson] = useState<ViewSalesPerson>({
		code: null,
		fullName: null,
		document: null,
		role: SalesPersonRole.None,
		storeDocument: null,
		gatewayId: null,
		useDefaultCommission: false,
		commissionPercentage: "0",
		salesChannels: [],
		isActive: true,
		storeId: null,
	});
	const formValidationMapper: Record<string, Function> = {
		[SalesPersonInputs.Code]: InputValidator.isRequired,
		[SalesPersonInputs.FullName]: InputValidator.isRequired,
		[SalesPersonInputs.Document]: (value: string) =>
			currentDocumentType === DocumentType.Cnpj
				? InputValidator.isCnpjValid(value)
				: InputValidator.isCpfValid(value),
		[SalesPersonInputs.Role]: InputValidator.isRequired,
		[SalesPersonInputs.StoreDocument]: (value: string) =>
			localStorage.getItem("role") === "2"
				? InputValidator.isRequired(value)
				: [],
	};

	useEffect(() => {
		if (!isValidString(params.id)) {
			setIsLoading(false);
			return;
		}
		getSalesPersonById(params.id);
	}, []);

	const salesPersonRoles: SelectOptions = [
		{
			label: i18n.t(`salesPersons.Role.${SalesPersonRole.Salesperson}`),
			value: SalesPersonRole.Salesperson,
		},
		{
			label: i18n.t(`salesPersons.Role.${SalesPersonRole.ResponsibleSeller}`),
			value: SalesPersonRole.ResponsibleSeller,
		},
		{
			label: i18n.t(`salesPersons.Role.${SalesPersonRole.Supervisor}`),
			value: SalesPersonRole.Supervisor,
		},
		{
			label: i18n.t(`salesPersons.Role.${SalesPersonRole.Coordinator}`),
			value: SalesPersonRole.Coordinator,
		},
		{
			label: i18n.t(`salesPersons.Role.${SalesPersonRole.AssistantManager}`),
			value: SalesPersonRole.AssistantManager,
		},
		{
			label: i18n.t(`salesPersons.Role.${SalesPersonRole.Manager}`),
			value: SalesPersonRole.Manager,
		},
		{
			label: i18n.t(`salesPersons.Role.${SalesPersonRole.Partner}`),
			value: SalesPersonRole.Partner,
		},
	];

	const documentTypeOptions: SelectOptions = [
		{
			label: i18n.t(`salesPersons.Document.${DocumentType.Cpf}`),
			value: DocumentType.Cpf,
		},
		{
			label: i18n.t(`salesPersons.Document.${DocumentType.Cnpj}`),
			value: DocumentType.Cnpj,
		},
	];

	const defaultChannelOptions: SelectOptions = [
		{ label: DefaultChannels.App, value: DefaultChannels.App },
		{ label: DefaultChannels.Totem, value: DefaultChannels.Totem },
		{ label: DefaultChannels.Marketplace, value: DefaultChannels.Marketplace },
		{ label: DefaultChannels.Ecommerce, value: DefaultChannels.Ecommerce },
	];

	const getSalesPersonById = async (id: string): Promise<void> => {
		try {
			const data: SalesPerson = await retailerManagementApi.getSalesPersonById(
				id
			);
			const commissionAsString: string = `${data.commissionPercentage * 100}`;
			if (normalizeDigitsOnly(data.document).length === 14) {
				setCurrentDocumentType(DocumentType.Cnpj);
			}
			setSalesPerson({ ...data, commissionPercentage: commissionAsString });
		} catch (error) {
			if (error instanceof ErrorResponse) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: error.message,
				});
				return;
			}
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
		setIsLoading(false);
	};

	const handleSubmit = (): void => {
		let commissionValue = 0;
		if (salesPerson.commissionPercentage !== "") {
			commissionValue = parseFloat(salesPerson.commissionPercentage) / 100;
		}
		const data: SalesPerson = {
			code: salesPerson.code,
			fullName: salesPerson.fullName,
			document: salesPerson.document,
			role: salesPerson.role,
			storeDocument: currentSeller?.cnpj ?? null,
			gatewayId: salesPerson.gatewayId,
			useDefaultCommission: salesPerson.useDefaultCommission,
			commissionPercentage: commissionValue,
			salesChannels: salesPerson.salesChannels,
			...(isValidString(salesPerson.id) && { id: salesPerson.id }),
			isActive: true,
			storeId: currentSeller?.id ?? null,
		};

		let errors: FormErrors = {};
		for (const [key, value] of Object.entries(data)) {
			if (formValidationMapper[key] === undefined) continue;
			const errorMessages: string[] = formValidationMapper[key](value);
			errors[key] = errorMessages;
		}
		if (FormValidator.hasError(errors)) {
			setFormErrors(errors);
			return;
		}

		data?.id ? updateSalesPerson(data.id, data) : createSalesPerson(data);
	};

	const createSalesPerson = async (data: SalesPerson): Promise<void> => {
		try {
			const success = await retailerManagementApi.createSalesPerson(data);
			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				return;
			}
			localStorage.setItem("createSalesPerson", "true");
			history.push("/salespeople/1");
		} catch (error) {
			if (error instanceof ErrorResponse) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: error.message,
				});
				return;
			}
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
	};

	const updateSalesPerson = async (
		id: string,
		data: SalesPerson
	): Promise<void> => {
		try {
			const success = await retailerManagementApi.updateSalesPerson(id, data);
			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				return;
			}
			localStorage.setItem("updateSalesPerson", "true");
			history.push("/salespeople/1");
		} catch (error) {
			if (error instanceof ErrorResponse) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: error.message,
				});
				return;
			}
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		let { name, value } = e.target;
		if (formValidationMapper[name] !== undefined) {
			const errMessages: string[] = formValidationMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}
		if (name === SalesPersonInputs.UseDefaultCommission) {
			const isChecked = e.target.checked;
			setSalesPerson((prevState) => ({ ...prevState, [name]: isChecked }));
			return;
		}
		if (name === SalesPersonInputs.Document) value = normalizeDigitsOnly(value);

		setSalesPerson((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSelectChange = (event: SelectChangeEvent<string>): void => {
		const { name, value } = event.target;
		if (formValidationMapper[name] !== undefined) {
			const errMessages: string[] = formValidationMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errMessages }));
		}
		if (name === SalesPersonInputs.DocumentType) {
			setSalesPerson((prevState) => ({ ...prevState, document: null }));
			setCurrentDocumentType(value as DocumentType);
			return;
		}
		setSalesPerson((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handlePercentageChange = (
		event: ChangeEvent<HTMLInputElement>
	): void => {
		let inputValue = event.target.value;
		if (!inputValue) {
			setSalesPerson((prevState) => ({
				...prevState,
				commissionPercentage: "",
			}));
			return;
		}
		if (inputValue.includes(".")) {
			const splittedValue = inputValue.split(".");
			inputValue = `${splittedValue[0]}.${splittedValue[1].slice(0, 2)}`;
		}
		setSalesPerson((prevState) => ({
			...prevState,
			commissionPercentage: inputValue,
		}));
	};

	const handleAutocompleteChange = (
		value: AutocompleteSellerView | null
	): void => {
		setCurrentSeller(value);
		const storeDocument = value?.cnpj ?? "";
		const errMessages: string[] =
			formValidationMapper[SalesPersonInputs.StoreDocument](storeDocument);
		setFormErrors((prevState) => ({
			...prevState,
			[SalesPersonInputs.StoreDocument]: errMessages,
		}));
	};

	const findEqualSalesChannel = (
		data: SalesChannel,
		dataIndex: number,
		{ selectValue }: { selectValue: string },
		editIndex?: number
	): boolean => {
		return (
			data.channel === selectValue &&
			(editIndex === undefined || dataIndex !== editIndex)
		);
	};

	const addSalesChannelId = (
		inputValue: string,
		selectValue: string,
		_?: number
	): void => {
		const updatedSalesChannels: SalesChannel[] = [
			...salesPerson.salesChannels,
			{ channel: selectValue as DefaultChannels, code: inputValue },
		];
		setSalesPerson((prevState) => ({
			...prevState,
			salesChannels: updatedSalesChannels,
		}));
	};

	const updateSalesChannelId = (
		inputValue: string,
		selectValue: string,
		indextoUpdate?: number
	): void => {
		const updatedSalesChannels: SalesChannel[] = salesPerson.salesChannels.map(
			(salesChannel, index) =>
				index === indextoUpdate
					? {
							channel: selectValue as DefaultChannels,
							code: inputValue,
					  }
					: salesChannel
		);
		setSalesPerson((prevState) => ({
			...prevState,
			salesChannels: updatedSalesChannels,
		}));
	};

	const removeSalesChannelId = (salesChannel: SalesChannel) => {
		const filteredData = salesPerson.salesChannels.filter(
			(data) => data.channel !== salesChannel.channel
		);
		setSalesPerson((prevState) => ({
			...prevState,
			salesChannels: filteredData,
		}));
	};

	return {
		// Props
		salesPerson,
		isLoading,
		salesPersonRoles,
		documentTypeOptions,
		defaultChannelOptions,
		configurations,
		currentSeller,
		setCurrentSeller,
		currentDocumentType,
		formErrors,
		// Functions
		handleSubmit,
		handleChange,
		handleSelectChange,
		handlePercentageChange,
		handleAutocompleteChange,
		addSalesChannelId,
		updateSalesChannelId,
		removeSalesChannelId,
		findEqualSalesChannel,
	};
}
