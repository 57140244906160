export enum SalesPersonRole {
	None = "",
	Salesperson = "Salesperson",
	ResponsibleSeller = "ResponsibleSeller",
	Supervisor = "Supervisor",
	Coordinator = "Coordinator",
	AssistantManager = "AssistantManager",
	Manager = "Manager",
	Partner = "Partner",
}
