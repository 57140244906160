import { CancellationReason } from "../../../../../domain/dtos/retailerManagement/retailerConfigurations/CancellationReason";
import { i18n } from "../../../../../translate/i18n";

export const getDefaultCancellationReasons = (): CancellationReason[] => {
	return [
		{
			reason: i18n.t("retailerConfig.DefaultReasons.DamagedProduct"),
			sortOrder: 1,
		},
		{
			reason: i18n.t(
				"retailerConfig.DefaultReasons.DescriptionOrColorMismatch"
			),
			sortOrder: 2,
		},
		{
			reason: i18n.t("retailerConfig.DefaultReasons.UnavailableProduct"),
			sortOrder: 3,
		},
		{
			reason: i18n.t("retailerConfig.DefaultReasons.ReservedForPhysicalStore"),
			sortOrder: 4,
		},
		{
			reason: i18n.t("retailerConfig.DefaultReasons.PhotoMismatch"),
			sortOrder: 5,
		},
		{
			reason: i18n.t("retailerConfig.DefaultReasons.PriceMismatch"),
			sortOrder: 6,
		},
		{
			reason: i18n.t("retailerConfig.DefaultReasons.TestOrder"),
			sortOrder: 7,
		},
	];
};
