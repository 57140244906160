export class ErrorResponse extends Error {
	message: string;
	errorMessages: string[];

	constructor(data: { message: string; errorMessages: string[] }) {
		super(data.message);
		this.message = data.message;
		this.errorMessages = data.errorMessages;

		Object.setPrototypeOf(this, ErrorResponse.prototype);
	}
}
