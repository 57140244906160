import { Box, Stack } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { LegacyOrderStatus } from "../../../domain/enums/LegacyOrderStatus";
import UpdateAddresseeDialog from "../../../components/UpdateAddresseeDialog/UpdateAddresseeDialog";
import { DeliveryAddressModel } from "../../../domain/models/commerce/ShippingData/DeliveryAddressModel";
import { SellerAddressModel } from "../../../domain/models/commerce/Seller/SellerAddressModel";
import { applyMask } from "../../../infrastructure/utils/FormatUtils";
import { InputMasks } from "../../../infrastructure/utils/InputMasks";
import CopyToClipboard from "../../../components/CopyToClipboard";
import OrderShippingActions from "./OrderShippingActions";
import { formatDateStringOnly } from "../../../infrastructure/utils/FormatMask";

type Props = {
	order: OrderModel | null;
	isLoading: boolean;
	updateOrderCallback: () => void;
	setIsDeliveryReceiptDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export default function OrderShipping({
	order,
	isLoading,
	updateOrderCallback,
	setIsDeliveryReceiptDialogOpen,
}: Props) {
	const [isAddresseeDialogOpen, setIsAddresseeDialogOpen] =
		useState<boolean>(false);

	const renderShippingData = (orderObj: OrderModel): JSX.Element => {
		const fullName = orderObj.isDelivery
			? orderObj.buyer.fullName
			: orderObj.seller.fullName;

		const addressData: DeliveryAddressModel | SellerAddressModel | null =
			orderObj.isDelivery
				? orderObj.shippingData.deliveryAddress
				: orderObj.seller.address;

		return (
			<Stack direction="column" spacing={1}>
				<CustomTypography variant="default" color="blueish20" isBold>
					{fullName}
					{fullName && <CopyToClipboard textToCopy={fullName} />}
				</CustomTypography>
				{addressData && (
					<>
						<CustomTypography variant="default" color="blueish20">
							{addressData.street}
							{addressData.number && `, ${addressData.number}`}
							{addressData.complement && `, ${addressData.complement}`}
							{addressData.neighborhood && ` - ${addressData.neighborhood}`}
							{addressData.city && ` - ${addressData.city}`}
							{addressData.state && ` - ${addressData.state}`}
							<CopyToClipboard textToCopy={addressData.fullAddress} />
						</CustomTypography>
						<CustomTypography variant="default" color="blueish20">
							CEP: {applyMask(addressData.zipCode, InputMasks.ZipCode)}
						</CustomTypography>
					</>
				)}
			</Stack>
		);
	};

	const renderTrackingData = (orderObj: OrderModel): JSX.Element => {
		return (
			<Stack direction="column" spacing={1} mt={2}>
				<CustomTypography variant="secondaryTitle" isBold>
					Forma de entrega
				</CustomTypography>
				{orderObj.freight?.description && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Tipo de envio: </strong>
						{orderObj.freight.description}
					</CustomTypography>
				)}
				{orderObj.freight?.estimateDate && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Estimativa para entrega: </strong>
						{formatDateStringOnly(orderObj.freight.estimateDate)}
					</CustomTypography>
				)}
				{orderObj.tracking?.carrierName && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Transportadora: </strong>
						{orderObj.tracking?.carrierName}
					</CustomTypography>
				)}
				{orderObj.tracking?.trackingLink && (
					<Stack direction="row" spacing={0.5}>
						<CustomTypography variant="default" color="blueish20">
							<strong>Link de rastreio:</strong>
						</CustomTypography>
						<a href={orderObj.tracking.trackingLink} target="_blank">
							<CustomTypography
								variant="default"
								color="info"
								sx={{ fontStyle: "italic", fontWeight: 500 }}
							>
								Link
							</CustomTypography>
						</a>
						<CopyToClipboard textToCopy={orderObj.tracking.trackingLink} />
					</Stack>
				)}
				{orderObj.tracking?.deliveryDate && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Data de entrega: </strong>
						{orderObj.tracking?.deliveryDate}
					</CustomTypography>
				)}
				{orderObj.tracking?.trackingCode && (
					<CustomTypography variant="default" color="blueish20">
						<strong>Código de rastreio: </strong>
						{orderObj.tracking.trackingCode}
						<CopyToClipboard textToCopy={orderObj.tracking.trackingCode} />
					</CustomTypography>
				)}
			</Stack>
		);
	};

	return (
		<Box className="card" p={3}>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						<CustomTypography variant="secondaryTitle" isBold>
							{order.isDelivery ? "Entrega" : "Retirada"}
						</CustomTypography>
						{order.isDelivery &&
							order.status !== LegacyOrderStatus.Finalized && (
								<a onClick={() => setIsAddresseeDialogOpen(true)}>
									<CustomTypography
										variant="default"
										color="info"
										sx={{ textDecoration: "underline" }}
									>
										Alterar
									</CustomTypography>
								</a>
							)}
					</Stack>
					{renderShippingData(order)}
					{order?.isDelivery && renderTrackingData(order)}
					<OrderShippingActions
						order={order}
						updateOrderCallback={updateOrderCallback}
						setIsDeliveryReceiptDialogOpen={setIsDeliveryReceiptDialogOpen}
					/>
					{order.isDelivery && order.status !== LegacyOrderStatus.Finalized && (
						<UpdateAddresseeDialog
							orderModel={order}
							isAddresseeDialogOpen={isAddresseeDialogOpen}
							setIsAddresseeDialogOpen={setIsAddresseeDialogOpen}
							callback={() => {
								setIsAddresseeDialogOpen(false);
								updateOrderCallback();
							}}
						/>
					)}
				</>
			)}
		</Box>
	);
}
