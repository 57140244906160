import {
	Button,
	darken,
	IconButton,
	Stack,
	Tooltip,
	Zoom,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { paletteObject } from "../../../theme/foundations/palette";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import {
	EditOutlined,
	PlaylistAddCheckRounded,
	PostAdd,
} from "@mui/icons-material";
import LoadingButtonIcon from "../../../components/LoadingButtonIcon";
import TrackingOrdersModal from "../Dialogs/TrackingOrdersModal";
import { APIV2 } from "../../../API";
import { LegacyOrderDto } from "../../../domain/dtos/legacyCommerceManagement/LegacyOrderDto";
import NewToastComponent from "../../../components/NewToastComponent";
import { LegacyOrderStatus } from "../../../domain/enums/LegacyOrderStatus";

type Props = {
	order: OrderModel;
	updateOrderCallback: () => void;
	setIsDeliveryReceiptDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export default function OrderShippingActions({
	order,
	updateOrderCallback,
	setIsDeliveryReceiptDialogOpen,
}: Props) {
	const [isLoadingLabel, setIsLoadingLabel] = useState<boolean>(false);

	const handleLabelError = () => {
		setIsLoadingLabel(false);
		NewToastComponent({
			status: "error",
			title: "Não foi possível gerar a etiqueta.",
			message: "Tente novamente!",
		});
		setTimeout(() => {
			window.location.reload();
		}, 6000);
	};

	const handleLabelSuccess = (shippingLabelUrl: string) => {
		window.open(shippingLabelUrl, "_blank");
		NewToastComponent({
			status: "success",
			title:
				"A etiqueta de postagem foi gerada e seu arquivo baixado com sucesso!",
		});
		setTimeout(() => {
			window.location.reload();
		}, 6000);
	};

	const downloadLabel = async (): Promise<void> => {
		setIsLoadingLabel(true);
		if (!order.tracking?.shippingLabelUrl) {
			APIV2.patch(`management/commerce/orders/${order.id}/shippingcode`)
				.then((response: { data: LegacyOrderDto }) => {
					if (
						!response.data.tracking?.shippingCode ||
						!response.data.plp ||
						!response.data.tracking?.shippingLabelUrl
					) {
						handleLabelError();
						return;
					}
					handleLabelSuccess(response.data.tracking.shippingLabelUrl);
				})
				.catch(() => handleLabelError());
			return;
		}

		if (
			!order.tracking?.shippingCode ||
			!order.plp ||
			!order.tracking?.shippingLabelUrl
		) {
			handleLabelError();
			return;
		}

		handleLabelSuccess(order.tracking.shippingLabelUrl);
	};

	return (
		<Stack direction="row" spacing={1} mt={2}>
			{order?.isDelivery && (
				<>
					<Button
						variant="contained"
						color="grey30"
						data-toggle="modal"
						data-target="#trackingOrders"
						sx={{
							color: paletteObject.grey70.main,
							width: "fit-content",
							minWidth: "unset",
						}}
					>
						<CustomIcon
							icon={<EditOutlined />}
							variant="default"
							color="grey70"
							sx={{ mr: 1 }}
						/>
						Editar rastreio
					</Button>
					{order.invoice?.key && (
						<Button
							variant="contained"
							color="info"
							disabled={isLoadingLabel}
							onClick={() => downloadLabel()}
							sx={{
								width: "fit-content",
								minWidth: "unset",
							}}
						>
							{isLoadingLabel ? (
								<LoadingButtonIcon />
							) : (
								<>
									<CustomIcon
										variant="default"
										icon={<PostAdd />}
										sx={{ mr: 1 }}
									/>
									{order.plp
										? "Baixar etiqueta de postagem"
										: "Emitir etiqueta de postagem"}
								</>
							)}
						</Button>
					)}
					<TrackingOrdersModal
						order={order}
						updateOrderCallback={updateOrderCallback}
					/>
				</>
			)}
			{order.status === LegacyOrderStatus.Finalized && (
				<Tooltip
					arrow
					placement="top"
					TransitionComponent={Zoom}
					title={"Inserir comprovante de entrega"}
				>
					<IconButton
						onClick={() => setIsDeliveryReceiptDialogOpen(true)}
						sx={{
							backgroundColor: paletteObject.grey30.main,
							borderRadius: 1,
							paddingLeft: "10px",
							":hover": {
								backgroundColor: darken(paletteObject.grey30.main, 0.1),
							},
						}}
					>
						<CustomIcon
							icon={<PlaylistAddCheckRounded />}
							variant="default"
							color="grey70"
						/>
					</IconButton>
				</Tooltip>
			)}
		</Stack>
	);
}
