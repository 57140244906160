import {
	Box,
	Button,
	Stack,
	Step,
	StepIconProps,
	StepLabel,
	Stepper,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import { paletteObject } from "../../../theme/foundations/palette";
import { StepModel } from "../../../domain/models/commerce/StepModel";
import {
	Cached,
	Check,
	RemoveRounded,
	WarningAmberRounded,
} from "@mui/icons-material";
import { formatDate } from "../../../infrastructure/utils/FormatMask";
import { LegacyOrderStatus } from "../../../domain/enums/LegacyOrderStatus";
import { ButtonModel } from "../../../domain/models/commerce/ButtonModel";
import LoadingButtonIcon from "../../../components/LoadingButtonIcon";
import { StepLevel } from "../../../domain/enums/LegacyOrders/StepLevel";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";

type Props = {
	order: OrderModel | null;
	isLoading: boolean;
	isUpdatingStatus: boolean;
	onActionButtonClick: (buttonEndpoint: string) => void;
	reprocessOrder: () => void;
	setIsHistoryOpen: Dispatch<SetStateAction<boolean>>;
};

export default function OrderStatus({
	order,
	isLoading,
	isUpdatingStatus,
	onActionButtonClick,
	reprocessOrder,
	setIsHistoryOpen,
}: Props) {
	const [activeStep, setActiveStep] = useState<number>(0);
	const [stepsWithIssues, setStepsWithIssues] = useState<string[]>([]);
	const canceledStatuses = [
		LegacyOrderStatus.CanceledByAdmin,
		LegacyOrderStatus.CanceledByBuyer,
		LegacyOrderStatus.CanceledByProcessor,
		LegacyOrderStatus.CanceledBySeller,
	];

	useEffect(() => {
		if (!order) return;

		const isCanceled = canceledStatuses.includes(
			order?.status as LegacyOrderStatus
		);

		const lastSuccessIndex = order.steps
			.map((step) => step.level)
			.lastIndexOf(StepLevel.Success);

		const activeStepIndex = lastSuccessIndex + 1;

		const previousIssues = order.steps
			.slice(0, activeStepIndex)
			.filter((step) => step.level !== StepLevel.Success)
			.map((step) => step.id);
		setStepsWithIssues(previousIssues);

		setActiveStep(isCanceled ? order.steps.length : activeStepIndex);
	}, [order]);

	const getIcon = (
		isCanceled: boolean,
		isStepWithIssue: boolean,
		completed?: boolean,
		active?: boolean
	): JSX.Element => {
		if (isCanceled) return <Check />;
		if (isStepWithIssue) return <WarningAmberRounded />;
		if (completed) return <Check />;
		if (active) return <Cached />;
		return <RemoveRounded />;
	};

	const getIconBackgroundColor = (
		isCanceled: boolean,
		isStepWithIssue: boolean,
		completed?: boolean,
		active?: boolean
	): string => {
		if (isCanceled) return paletteObject.danger.main;
		if (isStepWithIssue) return paletteObject.warning.main;
		if (completed) return paletteObject.success.main;
		return active ? paletteObject.info.main : paletteObject.grey50.main;
	};

	const customStepIcon = ({
		active,
		completed,
		className,
		step,
	}: StepIconProps & { step: StepModel }): JSX.Element => {
		const isCanceled = canceledStatuses.includes(
			order?.status as LegacyOrderStatus
		);

		const isStepWithIssue: boolean = stepsWithIssues.includes(step.id);

		let icon: JSX.Element = getIcon(
			isCanceled,
			isStepWithIssue,
			completed,
			active
		);

		const backgroundColor = getIconBackgroundColor(
			isCanceled,
			isStepWithIssue,
			completed,
			active
		);

		return (
			<Stack
				className={className}
				justifyContent="center"
				alignItems="center"
				sx={{
					width: 30,
					height: 30,
					borderRadius: "50%",
					backgroundColor: backgroundColor,
					color: paletteObject.white.main,
				}}
			>
				<CustomIcon
					icon={icon}
					variant={isStepWithIssue ? "custom" : "default"}
					{...(isStepWithIssue && { fontSize: "20px" })}
				/>
			</Stack>
		);
	};

	const renderButton = (button: ButtonModel) => {
		return (
			<Button
				key={button.name}
				variant="contained"
				color={button.action === "DELETE" ? "danger" : "success"}
				onClick={() => onActionButtonClick(button.endpoint)}
				disabled={isUpdatingStatus}
			>
				{isUpdatingStatus ? <LoadingButtonIcon /> : button.name}
			</Button>
		);
	};

	const renderReprocessBtn = () => {
		// Temporary Code - will be changed when backend finish their changes on "button" from GetOrder response
		return (
			<Button
				variant="contained"
				color={"success"}
				onClick={reprocessOrder}
				disabled={isUpdatingStatus}
			>
				{isUpdatingStatus ? <LoadingButtonIcon /> : "Reprocessar pedido"}
			</Button>
		);
	};

	return (
		<Box className="card" p={3}>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				mb={3}
			>
				<CustomTypography variant="secondaryTitle" isBold>
					Status do pedido
				</CustomTypography>
				<a {...(!isLoading && { onClick: () => setIsHistoryOpen(true) })}>
					<CustomTypography
						variant="default"
						color="info"
						sx={{ textDecoration: "underline" }}
					>
						Ver histórico completo
					</CustomTypography>
				</a>
			</Stack>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<Stack direction="column" alignItems="center">
					<Stepper
						activeStep={activeStep}
						alternativeLabel
						sx={{
							maxWidth: "100%",
							overflowX: "auto",
							"&::-webkit-scrollbar": {
								width: "6px",
								height: "6px",
								backgroundColor: "transparent",
							},
							"&::-webkit-scrollbar-track": {
								borderRadius: "10px",
								backgroundColor: paletteObject.grey20.main,
							},
							"&::-webkit-scrollbar-thumb": {
								borderRadius: " 10px",
								backgroundColor: paletteObject.grey50.main,
								height: "40px",
							},
						}}
					>
						{order.steps.map((step: StepModel, index: number) => (
							<Step
								key={step.description}
								sx={{
									height: "100%",
									".MuiStepConnector-line": {
										borderTop: `3px solid ${paletteObject.grey50.main}`,
									},
								}}
							>
								<StepLabel
									StepIconComponent={(props) =>
										customStepIcon({ ...props, step })
									}
									sx={{
										height: "100%",
										".MuiStepLabel-labelContainer": {
											marginTop: 1,
											height: "100%",
										},
										".MuiStepLabel-label": {
											color: paletteObject.grey50.main,
											fontWeight: 400,
											"&.Mui-completed": { color: paletteObject.grey90.main },
											"&.Mui-active": {
												color: paletteObject.grey90.main,
												fontWeight: 700,
											},
											"&.MuiStepLabel-alternativeLabel": {
												marginTop: 0,
												marginX: "auto",
												height: "100%",
												width: "100px",
												display: "flex",
												flexDirection: "column",
												justifyContent: "space-between",
											},
										},
									}}
								>
									{step.description}
									{index < activeStep && (
										<CustomTypography
											variant="small"
											color="grey65"
											sx={{ mt: 1 }}
										>
											{formatDate(step.lastUpdate, true)}
										</CustomTypography>
									)}
								</StepLabel>
							</Step>
						))}
					</Stepper>
					<Stack direction="row" spacing={2} mt={4}>
						{order.status === LegacyOrderStatus.ErrorUpdate &&
							renderReprocessBtn()}
						{order.buttons.map((button) => renderButton(button))}
					</Stack>
				</Stack>
			)}
		</Box>
	);
}
