import { PaymentMethodType } from "../../../domain/enums/Orders/PaymentMethodType";
import awaitMethod from "../../../assets/payments-flags/update_black_24dp.svg";
import withdrawal from "../../../assets/payments-flags/icon-pag-retirada.svg";
import bankslip from "../../../assets/payments-flags/icon-pag-boleto.svg";
import voucher from "../../../assets/payments-flags/icon-pag-voucher.svg";
import pix from "../../../assets/payments-flags/icon-pag-pix.svg";
import linkpag from "../../../assets/payments-flags/icon-link-pag.svg";
import installmentPix from "../../../assets/payments-flags/icon-pix-parcelado.svg";
import paymentTerminal from "../../../assets/payments-flags/icon-paymentTerminal.svg";
import { getCardBrandIcon } from "./getCardBrandIcon";

export const getPaymentIcon = (
	paymentMethodType: PaymentMethodType | null,
	paymentBrand: string | null
): string => {
	const paymentIcons: { [key in PaymentMethodType]: string } = {
		[PaymentMethodType.None]: awaitMethod,
		[PaymentMethodType.CreditCard]: getCardBrandIcon(paymentBrand),
		[PaymentMethodType.PickupStore]: withdrawal,
		[PaymentMethodType.BankSlip]: bankslip,
		[PaymentMethodType.Voucher]: voucher,
		[PaymentMethodType.DebitCard]: getCardBrandIcon(paymentBrand),
		[PaymentMethodType.Pix]: pix,
		[PaymentMethodType.PaymentLink]: linkpag,
		[PaymentMethodType.InstallmentPix]: installmentPix,
		[PaymentMethodType.Cashback]: awaitMethod,
		[PaymentMethodType.TerminalCreditCard]: paymentTerminal,
		[PaymentMethodType.TerminalDebitCard]: paymentTerminal,
		[PaymentMethodType.Invoice]: awaitMethod,
	};
	if (!paymentMethodType) return paymentIcons[PaymentMethodType.None];

	return (
		paymentIcons[paymentMethodType] || paymentIcons[PaymentMethodType.None]
	);
};
