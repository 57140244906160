import {
	Checkbox,
	FormControlLabel,
	SxProps,
	Theme,
	Tooltip,
	Zoom,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import CustomTypography from "./CustomTypography";
import CustomIcon from "./CustomIcon";
import { HelpOutlineOutlined } from "@mui/icons-material";
import {
	PaletteDiscriminatedUnion,
	paletteObject,
} from "../theme/foundations/palette";

type Props = {
	id: string;
	isChecked: boolean;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	label: string;
	tooltipText?: string;
	formControlSx?: SxProps<Theme>;
	color?: PaletteDiscriminatedUnion;
};

export default function CustomCheckbox({
	id,
	isChecked,
	onChange,
	label,
	tooltipText,
	formControlSx,
	color,
}: Props) {
	return (
		<FormControlLabel
			sx={{ margin: 0, ...formControlSx }}
			control={
				<Checkbox
					name={id}
					checked={isChecked}
					onChange={onChange}
					sx={{
						padding: 0,
						marginRight: 1,
						...(color && {
							"&.Mui-checked": {
								color: paletteObject[color].main,
							},
						}),
					}}
				/>
			}
			label={
				<CustomTypography variant="default">
					{label}
					{tooltipText && (
						<>
							{" "}
							<Tooltip
								arrow
								placement="top"
								TransitionComponent={Zoom}
								title={tooltipText}
							>
								<i>
									<CustomIcon icon={<HelpOutlineOutlined />} variant="small" />
								</i>
							</Tooltip>
						</>
					)}
				</CustomTypography>
			}
		/>
	);
}
