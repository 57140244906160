import React, { useState } from "react";
import InputMask from "react-input-mask";
import { APIV2 } from "../../../API";
import NewToastComponent from "../../../components/NewToastComponent";
import { getValueOrDefault } from "../../../infrastructure/utils/getValueOrDefault";

export default function TrackingOrdersModal({ order, updateOrderCallback }) {
	const [isLoading, setIsLoading] = useState(false);
	const [shipping, setShipping] = useState({
		carrierName: getValueOrDefault(order.tracking?.carrierName, ""),
		trackingLink: getValueOrDefault(order.tracking?.trackingLink, ""),
		trackingCode: getValueOrDefault(order.tracking?.trackingCode, ""),
		deliveryDate: getValueOrDefault(order.tracking?.deliveryDate, ""),
		shippingType: getValueOrDefault(order.tracking?.shippingType, ""),
		shippingCode: getValueOrDefault(order.tracking?.shippingCode, ""),
	});

	const createTrackingOrders = async () => {
		setIsLoading(true);
		try {
			await APIV2.put(
				`management/commerce/orders/${order.id}/trackingdetails`,
				shipping
			);
			NewToastComponent({
				status: "success",
				title: "Dados de rastreio cadastrados com sucesso!",
			});
			updateOrderCallback();
		} catch (error) {
			console.log(error);
		}
	};

	const handleInput = ({ name, value }) => {
		setShipping((prevState) => ({ ...prevState, [name]: value }));
	};

	return (
		<div
			className="modal fade"
			id="trackingOrders"
			tabIndex="-1"
			aria-labelledby="trackingOrdersLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "500px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "514px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="financial-text title mb-3">
							{`${order.tracking ? "Editar" : "Inserir"} Rastreio`}
						</h5>
						<p className="mb-3" style={{ color: "#747E8B", fontSize: "14px" }}>
							Preencha corretamente os campos abaixo para adicionar a forma de
							rastreio para este pedido
						</p>
					</div>
					<div className="modal-body m-0 p-0">
						<form>
							<div className="form-group">
								<label htmlFor="carrierName">
									<strong>Nome da Transportadora</strong>
								</label>
								<div>
									<input
										type="text"
										className="form-control"
										id="carrierName"
										name="carrierName"
										placeholder="Clique para preencher"
										value={shipping.carrierName}
										onChange={(event) => handleInput(event.target)}
									/>
								</div>
							</div>
							<div className="form-group mx-0">
								<label htmlFor="trackingLink">
									<strong>Link para Rastreamento</strong>
								</label>
								<div>
									<input
										type="text"
										className="form-control"
										id="trackingLink"
										name="trackingLink"
										placeholder="Clique para preencher"
										value={shipping.trackingLink}
										onChange={(event) => handleInput(event.target)}
									/>
								</div>
							</div>
							<div className="form-group mx-0">
								<label htmlFor="trackingCode">
									<strong>Código de Rastreamento</strong>
								</label>
								<div>
									<input
										type="text"
										className="form-control"
										id="trackingCode"
										name="trackingCode"
										placeholder="Clique para preencher"
										value={shipping.trackingCode}
										onChange={(event) => handleInput(event.target)}
									/>
								</div>
							</div>
							<div className="form-group mx-0">
								<label htmlFor="deliveryDate">
									<strong>Data prevista de entrega</strong>
								</label>
								<div>
									<InputMask
										mask="99/99/9999"
										className="form-control"
										type="text"
										name="deliveryDate"
										placeholder="dd/mm/aaaa"
										value={shipping.deliveryDate}
										onChange={(event) => handleInput(event.target)}
									/>
								</div>
							</div>
						</form>
					</div>
					<div className="modal-footer d-flex justify-content-end mt-3">
						<button
							type="button"
							className="btn btn-link"
							data-dismiss="modal"
							style={{
								padding: "10px 20px",
								width: "190px",
								color: "#5A5E61",
								border: "1px solid #8D8D8D",
								backgroundColor: "transparent",
							}}
						>
							Cancelar
						</button>
						<button
							type="button"
							className="btn btn-success btn-icon d-flex justify-content-center"
							data-dismiss={"modal"}
							disabled={isLoading}
							onClick={createTrackingOrders}
							style={{ padding: "10px 20px", width: "190px" }}
						>
							{order.tracking ? "Salvar alterações" : "Inserir Rastreio"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
