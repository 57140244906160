import {
	Avatar,
	Box,
	Divider,
	IconButton,
	Stack,
	Step,
	StepContent,
	StepLabel,
	Stepper,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import {
	AddCircleOutline,
	CloseRounded,
	ErrorOutline,
	InfoOutlined,
	PaymentsOutlined,
	PolicyOutlined,
	ReceiptOutlined,
} from "@mui/icons-material";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import { ActionLogModel } from "../../../domain/models/commerce/ActionLogModel";
import { ActionLogType } from "../../../domain/enums/LegacyOrders/ActionLogType";
import { ActionLogLevel } from "../../../domain/enums/LegacyOrders/ActionLogLevel";
import { i18n } from "../../../translate/i18n";
import { formatDate } from "../../../infrastructure/utils/FormatMask";
import { paletteObject } from "../../../theme/foundations/palette";

type Props = {
	order: OrderModel;
	setIsHistoryOpen: Dispatch<SetStateAction<boolean>>;
};

export default function OrderHistory({ order, setIsHistoryOpen }: Props) {
	const getActionLevelColor = (level: ActionLogLevel): string => {
		switch (level) {
			case ActionLogLevel.Info:
				return paletteObject.info.main;
			case ActionLogLevel.Warning:
				return paletteObject.warning.main;
			case ActionLogLevel.Success:
				return paletteObject.success.main;
			case ActionLogLevel.Error:
				return paletteObject.error.main;
			case ActionLogLevel.None:
			default:
				return paletteObject.grey80.main;
		}
	};

	const getActionTypeIcon = (actionLog: ActionLogModel): JSX.Element => {
		switch (actionLog.type) {
			case ActionLogType.Order:
				if (actionLog.level === ActionLogLevel.None)
					return <AddCircleOutline />;
				if (actionLog.level === ActionLogLevel.Error) return <ErrorOutline />;
				return <ReceiptOutlined />;
			case ActionLogType.Payment:
				return <PaymentsOutlined />;
			case ActionLogType.Fraud:
				return <PolicyOutlined />;
			case ActionLogType.Information:
				return <InfoOutlined />;
			case ActionLogType.None:
			default:
				return <AddCircleOutline />;
		}
	};

	const customStepIcon = (actionLog: ActionLogModel): JSX.Element => {
		const backgroundColor = getActionLevelColor(actionLog.level);
		const icon = getActionTypeIcon(actionLog);

		return (
			<Avatar sx={{ bgcolor: backgroundColor, width: 24, height: 24 }}>
				<CustomIcon icon={icon} variant="small" />
			</Avatar>
		);
	};

	const getActionLogTitle = (actionLog: ActionLogModel): string => {
		if (actionLog.title) return actionLog.title;
		let title: string;
		switch (actionLog.type) {
			case ActionLogType.Payment:
				title = "Payment";
				break;
			case ActionLogType.Fraud:
				title = "Fraud";
				break;
			case ActionLogType.Information:
				title = "Information";
				break;
			case ActionLogType.None:
			case ActionLogType.Order:
			default:
				title = "Order";
		}
		if (
			actionLog.type !== ActionLogType.Information &&
			actionLog.level === ActionLogLevel.Error
		) {
			title = `${title}Error`;
		}
		return i18n.t(`cpActionLogTitle.${title}`);
	};

	return (
		<Box p={4}>
			<CustomTypography variant="secondaryTitle" isBold>
				Histórico do Pedido
			</CustomTypography>
			<IconButton
				sx={{ position: "absolute", top: "16px", right: "16px" }}
				onClick={() => setIsHistoryOpen(false)}
			>
				<CustomIcon variant="default" icon={<CloseRounded />} />
			</IconButton>
			<Stepper orientation="vertical" connector={null}>
				{order.actionLogs?.map((actionLog: ActionLogModel, index: number) => (
					<Step
						key={actionLog.id}
						expanded
						{...(order.actionLogs &&
							index !== order.actionLogs.length - 1 && {
								sx: {
									position: "relative",
									"&::before": {
										content: '""',
										position: "absolute",
										left: 11,
										top: 20,
										bottom: -20,
										width: 2,
										backgroundColor: getActionLevelColor(actionLog.level),
										zIndex: -1,
									},
								},
							})}
					>
						<StepLabel StepIconComponent={() => customStepIcon(actionLog)}>
							<CustomTypography variant="small" color="blueish20">
								{formatDate(actionLog.lastUpdate)}
							</CustomTypography>
						</StepLabel>
						<StepContent sx={{ border: "none", overflowWrap: "break-word" }}>
							<Box
								py="10px"
								px="20px"
								borderRadius={1}
								sx={{ backgroundColor: paletteObject.grey10.main }}
								maxWidth={"480px"}
							>
								<CustomTypography variant="default" isBold sx={{ mb: 1 }}>
									{getActionLogTitle(actionLog)}
								</CustomTypography>
								<CustomTypography variant="small">
									{actionLog.displayMessage}
								</CustomTypography>
								<Divider sx={{ my: "10px" }} />
								<Stack direction="row" justifyContent="space-between">
									<CustomTypography variant="small" color="grey65">
										Via {actionLog.issuer ?? "Plataforma Lori"}
									</CustomTypography>
									{actionLog.urlLog && (
										<a onClick={() => window.open(actionLog.urlLog!)}>
											<CustomTypography
												variant="small"
												color="grey65"
												sx={{ textDecoration: "underline" }}
											>
												Informações adicionais
											</CustomTypography>
										</a>
									)}
								</Stack>
							</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</Box>
	);
}
