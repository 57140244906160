import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import Modal from "../../components/Modal";
import List from "../../components/List/List";
import HeaderSearches from "../../components/HeaderSearches/HeaderSearches";
import Empty from "../../components/Empty";
import { Link, useHistory } from "react-router-dom";
import { APIV2 } from "../../API";
import { useDealers } from "./providers/DealersContext";
import { i18n } from "../../translate/i18n";
import NewToastComponent from "../../components/NewToastComponent";
import {
	formatDocumentCnpj,
	formatDocumentCpf,
} from "../../infrastructure/utils/FormatMask";
import { normalizeDigitsOnly } from "../../infrastructure/utils/StringUtils";

// This screen/code needs to be refactored
export default function DealersList() {
	const {
		dealersList,
		loading,
		setLoading,
		roleClient,
		messageToast,
		page,
		setPage,
		rowsPerPage,
		query,
		setQuery,
		optionsDict,
		dealerFilters,
		setDealerFilters,
		getDealersList,
		deleteDealer,
	} = useDealers();
	const history = useHistory();
	const [deleteId, setDeleteId] = useState("");
	const columListRetailer = [
		{
			name: "Matrícula",
			selector: "name",
			sortable: false,
			center: true,
			width: "150px",
			cell: (row) => row.code,
		},

		{
			name: "Nome",
			selector: "name",
			sortable: false,
			center: true,
			cell: (row) => row.fullName,
		},

		{
			name: "Cargo",
			selector: "name",
			sortable: false,
			center: true,
			cell: (row) => i18n.t(`salesPersons.Role.${row.role}`),
		},
		{
			name: "Loja",
			selector: "name",
			sortable: false,
			center: true,
			cell: (row) => row.companyName,
		},
		{
			name: "",
			sortable: false,
			width: "150px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						id="btnGroupDrop1"
						to={`/salespeople/${page}/edit/` + row.id}
						className="btn btn-info"
					>
						Editar
					</Link>

					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>

					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<a
							className="dropdown-item text-danger"
							data-toggle="modal"
							data-target="#deleteSalespeople"
							onClick={() => setDeleteId(row.id)}
						>
							Excluir
						</a>
					</div>
				</div>
			),
		},
	];
	const columsList = [
		{
			name: "Matrícula",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => <div className="">{row.code}</div>,
		},
		{
			name: "Nome e DOCUMENTO",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => (
				<div className="d-flex flex-column">
					<span>
						<strong>{row.fullName}</strong>
					</span>
					{row.document && (
						<span style={{ fontSize: "12px" }}>
							{normalizeDigitsOnly(row.document).length === 14
								? `${i18n.t(
										`orderDetails.General.SellerDoc`
								  )}: ${formatDocumentCnpj(row.document)}`
								: `${i18n.t(
										`orderDetails.General.BuyerDoc`
								  )}: ${formatDocumentCpf(row.document)}`}
						</span>
					)}
					<span></span>
				</div>
			),
		},

		{
			name: "Cargo",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => i18n.t(`salesPersons.Role.${row.role}`),
		},
		{
			name: "Loja",
			selector: "name",
			sortable: false,
			center: false,
			cell: (row) => (
				<div className="">
					<span>
						<strong>{row.companyName}</strong>
					</span>
				</div>
			),
		},
		{
			name: "Ações",
			sortable: false,
			center: true,
			width: "170px",
			cell: (row) => (
				<div className="btn-group" role="group">
					<Link
						to={`/salespeople/${page}/edit/` + row.id}
						className="btn btn-info"
						id="btnGroupDrop1"
					>
						Editar
					</Link>
					<button
						id="btnGroupDrop1"
						type="button"
						className="btn btn-sm border-left border-white btn-info rounded-right"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="material-icons" style={{ fontSize: "15px" }}>
							keyboard_arrow_down
						</span>
					</button>

					<div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
						<button
							className="dropdown-item text-danger"
							data-toggle="modal"
							data-target="#deleteSalespeople"
							onClick={() => setDeleteId(row.id)}
						>
							Excluir
						</button>
					</div>
				</div>
			),
		},
	];

	const selectList = [
		{
			title: "Office",
			options: optionsDict,
		},
	];

	useEffect(() => {
		localStorage.getItem("createSalesPerson") === "true" &&
			NewToastComponent({
				specifyTimer: 10000,
				status: "success",
				title:
					"Vendedor cadastrado com sucesso! Por favor, solicite ao vendedor que efetue o login/logout pelo aplicativo!",
			});
		localStorage.getItem("updateSalesPerson") === "true" &&
			NewToastComponent({
				specifyTimer: 10000,
				status: "success",
				title:
					"Cadastro atualizado com sucesso! Por favor, solicite ao vendedor que efetue o login/logout pelo aplicativo!",
			});
		localStorage.getItem("deleteDealer") === "true" &&
			NewToastComponent({
				specifyTimer: 10000,
				status: "success",
				title:
					"Vendedor excluído com sucesso! Por favor, solicite ao vendedor que efetue o logout pelo aplicativo!",
			});
		setTimeout(() => {
			localStorage.removeItem("createSalesPerson");
			localStorage.removeItem("updateSalesPerson");
			localStorage.removeItem("deleteDealer");
		}, 4000);
	}, [messageToast]);

	useEffect(() => {
		history.push(`${page}?query=${query}&role=${dealerFilters.Office}`);
		getDealersList();
	}, [page, rowsPerPage, query, dealerFilters]);

	const handleQueryChange = (queryValue) => {
		setQuery(queryValue);
		setPage("1");
	};

	const handleClearFilters = () => {
		setDealerFilters({
			Office: "",
		});
		setPage("1");
	};

	const importSelles = (e) => {
		let formData = new FormData();
		formData.append("file", e.target.files[0]);
		setLoading(true);
		APIV2.post("management/marketing/dealers/import", formData, {
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(async () => {
				NewToastComponent({
					status: "success",
					title:
						"Estamos importando os vendedores em segundo plano. Em breve eles estarão disponíveis no painel.",
				});
				setLoading(false);
			})
			.catch(() => {
				NewToastComponent({
					status: "error",
					title:
						"Infelizmente tivemos um problema, tente novamente ou contate o suporte.",
				});
				setLoading(false);
			});
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		return skele.map((key) => (
			<ContentLoader
				key={key}
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="40" y="25" rx="3" ry="3" width="60" height="10" />
				<rect x="250" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="550" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="800" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	return (
		<div className="container dash-content">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center p-3">
					<h4 className="card-title">Vendedores</h4>
					<div className="d-flex flex-column flex-lg-row justify-content-end">
						<button
							className="btn btn-info light btn-icon justify-content-center m-1"
							style={{ width: "190px" }}
						>
							<input
								type="file"
								onChange={importSelles}
								style={{
									opacity: 0,
									position: "absolute",
									zIndex: 1,
									cursor: "pointer",
								}}
							/>
							<i className="material-icons-outlined">upload</i>
							&nbsp;&nbsp;Importar Vendedores
						</button>
						<Link
							className="btn btn-grey-80 m-1"
							to={`/salespeople/${page}/new`}
							style={{ width: "190px" }}
						>
							Novo Vendedor
						</Link>
					</div>
				</div>
				<div className="m-2 p-2">
					<HeaderSearches
						query={query}
						handleQueryChange={handleQueryChange}
						handleClearFilters={handleClearFilters}
						selectList={selectList}
						setFilters={setDealerFilters}
						filters={dealerFilters}
						placeholder={"SearchDealer"}
					/>
				</div>
				<div className="card-content">
					<div
						className="d-flex align-items-center p-3"
						style={{ width: "100%" }}
					>
						<strong>
							{i18n
								.t("components.ShowingAllResultsQuantity")
								.replace("%X", dealersList.length || "0")
								.toUpperCase()}
						</strong>
					</div>
					<div className="card-body no-padding">
						{loading ? (
							skeleton()
						) : (
							<>
								{dealersList.length <= 0 ? (
									<Empty
										img={"/images/empty_dealer.svg"}
										onChange={importSelles}
										genre="seu primeiro"
										name="vendedor"
										link={`/salespeople/${page}/new`}
										importIn={true}
									/>
								) : roleClient === "2" ? (
									<List
										data={dealersList}
										colums={columsList}
										paginationServer={false}
										pagination={true}
									/>
								) : (
									<List
										data={dealersList}
										colums={columListRetailer}
										paginationServer={false}
										pagination={true}
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			<Modal
				id={"deleteSalespeople"}
				title={"Tem certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={() => deleteDealer(deleteId)}
			/>
		</div>
	);
}
