import React, {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import { lightOrDark } from "../infrastructure/utils/LightOrDark";
import { ThemeOptions, ThemeProvider } from "@mui/material/styles";
import { theme, createWhiteLabelTheme } from "../theme";
import { retailerManagementApi } from "../infrastructure/api/RetailerManagementApi";
import NewToastComponent from "../components/NewToastComponent";
import { LayoutProfile } from "../domain/dtos/retailerManagement/LayoutProfile";
import { paletteObject } from "../theme/foundations/palette";

export type ConfigProvider = {
	profileConfigs: LayoutProfile | null;
	mainLoading: boolean;
	useDarkImageUrl: boolean;
};

export const ConfigContext = createContext<ConfigProvider>(
	{} as ConfigProvider
);

export default function ConfigProviders({ children }: PropsWithChildren) {
	const [profileConfigs, setProfileConfigs] = useState<LayoutProfile | null>(
		null
	);
	const [mainLoading, setMainLoading] = useState<boolean>(true);
	const [currentTheme, setCurrentTheme] = useState<ThemeOptions>(theme);
	const [useDarkImageUrl, setUseDarkImageUrl] = useState<boolean>(true);

	useEffect(() => {
		const isWhiteLabel = localStorage.getItem("isWhiteLabel") === "true";
		if (!isWhiteLabel) {
			setProfileConfigs(defaultLayoutProfile);
			setMainLoading(false);
			return;
		}

		const foundLayoutProfile = localStorage.getItem("layoutProfile");
		if (!foundLayoutProfile) {
			getProfileConfigs();
			return;
		}
		const data: LayoutProfile = JSON.parse(foundLayoutProfile);
		applyLayoutProfile(data);
	}, []);

	const defaultLayoutProfile = {
		dashboardHorizontalDarkImageUrl: "/images/logo-primary.svg",
		dashboardHorizontalLightImageUrl: "/images/logo-primary.svg",
		dashboardPrimaryColor: paletteObject.primary.main,
		dashboardSecondaryColor: paletteObject.primary.main,
	};

	const notifyLayoutProfileError = () => {
		NewToastComponent({
			status: "warning",
			title: "Não foi possível aplicar identidade visual",
		});
	};

	const applyLayoutProfile = (data: LayoutProfile) => {
		if (!data.dashboardPrimaryColor || !data.dashboardSecondaryColor) {
			setProfileConfigs(defaultLayoutProfile);
			notifyLayoutProfileError();
			setMainLoading(false);
			return;
		}

		localStorage.setItem("layoutProfile", JSON.stringify(data));

		const root = document.documentElement;
		root.style.setProperty("--primary", data.dashboardPrimaryColor);
		root.style.setProperty("--secondary", data.dashboardSecondaryColor);

		setProfileConfigs(data);
		setUseDarkImageUrl(lightOrDark(data.dashboardPrimaryColor) === "light");
		setCurrentTheme(
			createWhiteLabelTheme(
				data.dashboardPrimaryColor,
				data.dashboardSecondaryColor
			)
		);
		setMainLoading(false);
	};

	const getProfileConfigs = async () => {
		try {
			const data: LayoutProfile =
				await retailerManagementApi.getLayoutProfile();
			applyLayoutProfile(data);
		} catch {
			setProfileConfigs(defaultLayoutProfile);
			notifyLayoutProfileError();
			setMainLoading(false);
		}
	};

	return (
		<ThemeProvider theme={currentTheme}>
			<ConfigContext.Provider
				value={{
					profileConfigs,
					useDarkImageUrl,
					mainLoading,
				}}
			>
				{children}
			</ConfigContext.Provider>
		</ThemeProvider>
	);
}

export const useConfig = () => useContext<ConfigProvider>(ConfigContext);
