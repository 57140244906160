import React, { useState } from "react";
import CustomIcon from "../MUICustomComponents/CustomIcon";
import { CheckCircle, ContentCopy } from "@mui/icons-material";
import { PaletteDiscriminatedUnion } from "../theme/foundations/palette";

type Props = { textToCopy: string; color?: PaletteDiscriminatedUnion };

export default function CopyToClipboard({
	textToCopy,
	color = "blueish20",
}: Props) {
	const [isCopied, setIsCopied] = useState<boolean>(false);

	return (
		<CustomIcon
			variant="small"
			hasPointerCursor
			icon={isCopied ? <CheckCircle /> : <ContentCopy />}
			color={color}
			sx={{ marginLeft: 0.5 }}
			{...(isCopied && { color: "success" })}
			{...(!isCopied && {
				onClick: () => {
					navigator.clipboard.writeText(textToCopy);
					setIsCopied(true);
					setTimeout(() => setIsCopied(false), 3000);
				},
			})}
		/>
	);
}
