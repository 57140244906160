import {
	Close,
	DeleteOutline,
	Done,
	DragHandleRounded,
	EditOutlined,
	ExpandMore,
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	IconButton,
	SelectChangeEvent,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import CustomTypography from "../../MUICustomComponents/CustomTypography";
import CustomInput from "../../MUICustomComponents/CustomInput";
import {
	FormErrors,
	FormValidator,
	InputValidator,
} from "../../infrastructure/utils/FormUtils";
import AttentionDialog from "../AttentionDialog";
import { i18n } from "../../translate/i18n";
import FixedAlertComponent from "../FixedAlertComponent/FixedAlertComponent";
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	Droppable,
	DroppableProvided,
	DropResult,
} from "react-beautiful-dnd";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import CustomSelect, {
	SelectOptions,
} from "../../MUICustomComponents/CustomSelect";

type TableDisplayKey<T> = {
	[K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

type ComponentTexts = {
	addInputTitle: string;
	addButtonText: string;
	editInputTitle: string;
};

type ResetConfig = {
	buttonText: string;
	attentionDialogText: string;
	resetToDefaultData: () => void;
	isDefaultData: boolean;
	isDefaultAlertText: string;
};

type DragDropConfig<T> = {
	dataKeyAsDraggableId: keyof T;
	sortData: (dataArray: T[]) => void;
};

type DataSelectConfig<T> = {
	options: SelectOptions;
	label: string;
	findEqualSelect?: (
		data: T,
		dataIndex: number,
		{ inputValue, selectValue }: { inputValue?: string; selectValue: string },
		editIndex?: number
	) => boolean;
	displayKey: TableDisplayKey<T>;
};

type DataChangeFn = (inputValue: string, index: number) => void;
type DataChangeSelectFn = (
	inputValue: string,
	selectValue: string,
	index?: number
) => void;

type Props<T> = {
	title: string;
	dataArray: T[];
	tableDisplayKey: TableDisplayKey<T>;
	componentTexts: ComponentTexts;
	addNewData: DataChangeFn | DataChangeSelectFn;
	updateData: DataChangeFn | DataChangeSelectFn;
	removeData: (data: T) => void;
	findEqual?: (data: T, inputValue: string, editIndex?: number) => boolean;
	resetConfig?: ResetConfig;
	dragDropConfig?: DragDropConfig<T>;
	dataSelectConfig?: DataSelectConfig<T>;
};

export default function AccordionTable<T>({
	title,
	dataArray,
	tableDisplayKey,
	componentTexts,
	addNewData,
	updateData,
	removeData,
	findEqual,
	resetConfig,
	dragDropConfig,
	dataSelectConfig,
}: Props<T>) {
	const [addNewInput, setAddNewInput] = useState<string>("");
	const [addNewSelect, setAddNewSelect] = useState<string>("");
	const [dataEditIndex, setDataEditIndex] = useState<number | null>(null);
	const [editDataInput, setEditDataInput] = useState<string>("");
	const [editDataSelect, setEditDataSelect] = useState<string>("");
	const [isResetConfirmOpen, setIsResetConfirmOpen] = useState<boolean>(false);
	const [inputErrors, setInputErrors] = useState<
		FormErrors &
			Record<
				"addNewInput" | "addNewSelect" | "editDataInput" | "editDataSelect",
				string[]
			>
	>({
		addNewInput: [],
		addNewSelect: [],
		editDataInput: [],
		editDataSelect: [],
	});

	const getDataKeyAsString = (
		dataObj: T,
		dataKey: TableDisplayKey<T>
	): string => {
		return dataObj[dataKey] as string;
	};

	const onChange = (
		event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
		setState: Dispatch<SetStateAction<string>>
	): void => {
		const { name, value } = event.target;
		setState(value);
		if (FormValidator.hasInputError(inputErrors, name)) {
			setInputErrors((prevState) => ({ ...prevState, [name]: [] }));
		}
	};

	const validateInput = (inputValue: string, editIndex?: number): string[] => {
		if (!inputValue) return InputValidator.isRequired(inputValue);
		if (!findEqual) return [];
		const foundEqualData = dataArray.find((data) =>
			findEqual(data, inputValue, editIndex)
		);
		return foundEqualData ? ["ValueAlreadyExists"] : [];
	};

	const validateSelect = (
		inputValue: string,
		selectValue: string,
		editIndex?: number
	): string[] => {
		if (!selectValue) return InputValidator.isRequired(selectValue);
		const foundEqualData = dataArray.find(
			(data, dataIndex) =>
				dataSelectConfig?.findEqualSelect &&
				dataSelectConfig.findEqualSelect(
					data,
					dataIndex,
					{ inputValue, selectValue },
					editIndex
				)
		);
		return foundEqualData ? ["ValueAlreadyExists"] : [];
	};

	const addNewValue = (inputValue: string, selectValue: string): void => {
		const trimmedValue = inputValue.trim();
		const errors = {
			...inputErrors,
			addNewInput: validateInput(trimmedValue),
			...(dataSelectConfig && {
				addNewSelect: validateSelect(trimmedValue, selectValue),
			}),
		};
		setInputErrors((prevState) => ({ ...prevState, ...errors }));
		if (FormValidator.hasError(errors)) return;
		setAddNewInput("");
		if (dataSelectConfig) {
			setAddNewSelect("");
			(addNewData as DataChangeSelectFn)(
				trimmedValue,
				selectValue,
				dataArray.length
			);
			return;
		}
		(addNewData as DataChangeFn)(trimmedValue, dataArray.length);
	};

	const updateValue = (editIndex: number): void => {
		const trimmedValue = editDataInput.trim();
		const errors = {
			...inputErrors,
			editDataInput: validateInput(trimmedValue, editIndex),
			...(dataSelectConfig && {
				editDataSelect: validateSelect(trimmedValue, editDataSelect, editIndex),
			}),
		};
		setInputErrors((prevState) => ({ ...prevState, ...errors }));
		if (FormValidator.hasError(errors)) return;
		setDataEditIndex(null);
		setEditDataInput("");
		if (dataSelectConfig) {
			setEditDataSelect("");
			(updateData as DataChangeSelectFn)(
				trimmedValue,
				editDataSelect,
				editIndex
			);
			return;
		}
		(updateData as DataChangeFn)(trimmedValue, editIndex);
	};

	const resetData = (): void => {
		if (!resetConfig) return;
		setIsResetConfirmOpen(false);
		resetConfig.resetToDefaultData();
	};

	const onDragEnd = (result: DropResult): void => {
		if (!result.destination || !dragDropConfig?.sortData) return;

		const reorderedList = Array.from(dataArray);
		const [movedItem] = reorderedList.splice(result.source.index, 1);
		reorderedList.splice(result.destination.index, 0, movedItem);

		dragDropConfig.sortData(reorderedList);
	};

	const renderSelectCell = (dataObj: T, index: number): JSX.Element => {
		if (!dataSelectConfig) return <></>;
		return (
			<TableCell width={"50%"}>
				{index === dataEditIndex ? (
					<CustomSelect
						id="editDataSelect"
						label={dataSelectConfig.label}
						value={editDataSelect}
						options={dataSelectConfig.options}
						onChange={(e) => onChange(e, setEditDataSelect)}
						{...(FormValidator.hasInputError(inputErrors, "editDataSelect") && {
							error: true,
							helperText: FormValidator.translateHelperTextList(
								inputErrors,
								"editDataSelect"
							),
						})}
					/>
				) : (
					getDataKeyAsString(dataObj, dataSelectConfig.displayKey)
				)}
			</TableCell>
		);
	};

	const renderActionButtons = (dataObj: T, index: number): JSX.Element => {
		if (index === dataEditIndex) {
			return (
				<>
					<IconButton
						color="success"
						disabled={
							FormValidator.hasInputError(inputErrors, "editDataInput") ||
							FormValidator.hasInputError(inputErrors, "editDataSelect")
						}
						onClick={() => updateValue(index)}
					>
						<CustomIcon variant="default" icon={<Done />} />
					</IconButton>
					<IconButton
						color="danger"
						onClick={() => {
							setDataEditIndex(null);
							setEditDataInput("");
							setEditDataSelect("");
							setInputErrors((prevState) => ({
								...prevState,
								editDataInput: [],
								editDataSelect: [],
							}));
						}}
					>
						<CustomIcon variant="default" icon={<Close />} />
					</IconButton>
				</>
			);
		}

		return (
			<>
				<IconButton
					disabled={dataEditIndex !== null}
					onClick={() => {
						setDataEditIndex(index);
						setEditDataInput(getDataKeyAsString(dataObj, tableDisplayKey));
						if (dataSelectConfig)
							setEditDataSelect(
								getDataKeyAsString(dataObj, dataSelectConfig.displayKey)
							);
					}}
				>
					<CustomIcon variant="default" icon={<EditOutlined />} />
				</IconButton>
				<IconButton color="danger" onClick={() => removeData(dataObj)}>
					<CustomIcon variant="default" icon={<DeleteOutline />} />
				</IconButton>
			</>
		);
	};

	const renderTableRow = (dataObj: T, index: number): JSX.Element => {
		const key = dragDropConfig
			? `${dataObj[dragDropConfig.dataKeyAsDraggableId]}-${index}`
			: `${index}`;
		const draggableId = dragDropConfig
			? `${dataObj[dragDropConfig.dataKeyAsDraggableId]}-${index}`
			: `${index}`;
		return (
			<Draggable
				key={key}
				draggableId={draggableId}
				isDragDisabled={!dragDropConfig}
				index={index}
			>
				{(provided: DraggableProvided) => (
					<TableRow ref={provided.innerRef} {...provided.draggableProps}>
						{dragDropConfig && (
							<TableCell width={"1%"} {...provided.dragHandleProps}>
								<CustomIcon icon={<DragHandleRounded />} variant="default" />
							</TableCell>
						)}
						<TableCell {...(dataSelectConfig && { width: "50%" })}>
							{index === dataEditIndex ? (
								<CustomInput
									name={"editDataInput"}
									value={editDataInput}
									title={componentTexts.editInputTitle}
									onChange={(e) => onChange(e, setEditDataInput)}
									onKeyDown={(event) => {
										if (event.key === "Enter") {
											updateValue(index);
										}
									}}
									{...(FormValidator.hasInputError(
										inputErrors,
										"editDataInput"
									) && {
										error: true,
										helperText: FormValidator.translateHelperTextList(
											inputErrors,
											"editDataInput"
										),
									})}
								/>
							) : (
								getDataKeyAsString(dataObj, tableDisplayKey)
							)}
						</TableCell>
						{dataSelectConfig && renderSelectCell(dataObj, index)}
						<TableCell align="right" width={"1%"} sx={{ whiteSpace: "nowrap" }}>
							{renderActionButtons(dataObj, index)}
						</TableCell>
					</TableRow>
				)}
			</Draggable>
		);
	};

	return (
		<Accordion sx={{ mt: 1 }}>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<CustomTypography variant="default" isBold>
					{title}
				</CustomTypography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack direction="row" spacing={2}>
					<CustomInput
						title={componentTexts.addInputTitle}
						name={"addNewInput"}
						value={addNewInput}
						onChange={(e) => onChange(e, setAddNewInput)}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								addNewValue(addNewInput, addNewSelect);
							}
						}}
						{...(FormValidator.hasInputError(inputErrors, "addNewInput") && {
							error: true,
							helperText: FormValidator.translateHelperTextList(
								inputErrors,
								"addNewInput"
							),
						})}
					/>
					{dataSelectConfig && (
						<CustomSelect
							id="addNewSelect"
							label={dataSelectConfig.label}
							value={addNewSelect}
							options={dataSelectConfig.options}
							onChange={(e) => onChange(e, setAddNewSelect)}
							{...(FormValidator.hasInputError(inputErrors, "addNewSelect") && {
								error: true,
								helperText: FormValidator.translateHelperTextList(
									inputErrors,
									"addNewSelect"
								),
							})}
						/>
					)}
					<Button
						variant="contained"
						color="success"
						disabled={
							FormValidator.hasInputError(inputErrors, "addNewInput") ||
							FormValidator.hasInputError(inputErrors, "addNewSelect")
						}
						onClick={() => addNewValue(addNewInput, addNewSelect)}
					>
						{componentTexts.addButtonText}
					</Button>
					{resetConfig && (
						<>
							<Button
								variant="contained"
								color="warning"
								onClick={() => setIsResetConfirmOpen(true)}
							>
								{resetConfig.buttonText}
							</Button>
							<AttentionDialog
								isAttentionDialogOpen={isResetConfirmOpen}
								setIsAttentionDialogOpen={setIsResetConfirmOpen}
								closeCallback={() => setIsResetConfirmOpen(false)}
								showCancelButton={true}
								actionButton={{
									text: i18n.t("buttons.General.BtnConfirm"),
									variant: "contained",
									color: "success",
									onClick: resetData,
								}}
							>
								<CustomTypography
									variant="default"
									sx={{ textAlign: "center" }}
								>
									{resetConfig.attentionDialogText}
								</CustomTypography>
							</AttentionDialog>
						</>
					)}
				</Stack>
				{resetConfig && resetConfig.isDefaultData && (
					<FixedAlertComponent alertType={"warning"} marginTop="8px">
						<ul style={{ margin: 0, paddingLeft: "20px" }}>
							<li>
								<Typography fontSize={14}>
									{resetConfig.isDefaultAlertText}
								</Typography>
							</li>
						</ul>
					</FixedAlertComponent>
				)}
				<TableContainer>
					<Table sx={{ overflow: "hidden" }}>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="accordionTable">
								{(provided: DroppableProvided) => (
									<TableBody
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{dataArray.map((dataObj: T, index) =>
											renderTableRow(dataObj, index)
										)}
									</TableBody>
								)}
							</Droppable>
						</DragDropContext>
					</Table>
				</TableContainer>
			</AccordionDetails>
		</Accordion>
	);
}
