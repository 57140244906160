import React, { Dispatch, SetStateAction } from "react";
import CustomDialog from "../../../MUICustomComponents/CustomDialog";
import { i18n } from "../../../translate/i18n";

type Props = {
	isDialogOpen: boolean;
	isUpdatingStatus: boolean;
	setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
	updateStatus: () => Promise<void>;
};

export default function ChangeStatusDialog({
	isDialogOpen,
	isUpdatingStatus,
	setIsDialogOpen,
	updateStatus,
}: Props) {
	return (
		<CustomDialog
			isOpen={isDialogOpen}
			setIsOpen={setIsDialogOpen}
			showCloseButton={false}
			dialogInfo={{
				title: i18n.t("legacyOrderProgress.ChangeStatusDialogTitle"),
			}}
			actionButton={{
				text: i18n.t(`buttons.General.BtnConfirm`),
				variant: "contained",
				color: "success",
				isDisabled: isUpdatingStatus,
				isLoading: isUpdatingStatus,
				onClick: () => updateStatus(),
			}}
		/>
	);
}
