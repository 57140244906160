export enum SalesPersonInputs {
	Code = "code",
	FullName = "fullName",
	DocumentType = "documentType",
	Document = "document",
	Role = "role",
	StoreDocument = "storeDocument",
	GatewayId = "gatewayId",
	UseDefaultCommission = "useDefaultCommission",
	CommissionPercentage = "commissionPercentage",
}
