import { useEffect, useState } from "react";
import { cpCommerceManagementApi } from "../../infrastructure/api/CPCommerceManagement";
import NewToastComponent from "../../components/NewToastComponent";
import { i18n } from "../../translate/i18n";
import { OrderMapper } from "../../domain/mappers/orders/OrderMapper";
import { LegacyOrderDto } from "../../domain/dtos/legacyCommerceManagement/LegacyOrderDto";
import { OrderModel } from "../../domain/models/commerce/OrderModel";
import { useParams } from "react-router-dom";
import { APIV1 } from "../../API";
import { PickingModel } from "../../domain/models/commerce/PickingModel";
import { LegacyOrderStatus } from "../../domain/enums/LegacyOrderStatus";

export default function OrderDetailsService() {
	const routeParams = useParams();
	const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>(false);
	const [order, setOrder] = useState<OrderModel | null>(null);
	const [statusToUpdateTo, setStatusToUpdateTo] = useState<string>("");
	const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
	const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
	const [isDeliveryReceiptDialogOpen, setIsDeliveryReceiptDialogOpen] =
		useState<boolean>(false);
	const [isOrderCheckDialogOpen, setIsOrderCheckDialogOpen] =
		useState<boolean>(false);
	const [isOrderStatusChangeOpen, setIsOrderStatusChangeOpen] =
		useState<boolean>(false);
	const [isInvoiceDialogOpen, setIsInvoiceDialogOpen] =
		useState<boolean>(false);
	const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
		useState<boolean>(false);

	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const enableOrderPicking = configurations?.enableOrderPicking;

	useEffect(() => {
		setIsLoadingOrder(true);
		getOrderById(routeParams.id);
	}, []);

	const getOrderById = async (id: string) => {
		setIsLoadingOrder(true);
		try {
			const data: LegacyOrderDto = await cpCommerceManagementApi.getOrderById(
				id
			);
			const mappedOrderModel: OrderModel = OrderMapper.legacyToOrderModel(data);
			setOrder(mappedOrderModel);
			setIsLoadingOrder(false);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsLoadingOrder(false);
		}
	};

	const updateOrderCallback = (): void => {
		setIsLoadingOrder(true);
		getOrderById(routeParams.id);
	};

	const reprocessOrder = async (): Promise<void> => {
		// Temporary Code - will be changed when backend finish their changes on "button" from GetOrder response
		setIsUpdatingStatus(true);
		await APIV1.put(`orderprocessor/orders/${routeParams.id}/reprocess`)
			.then(() => {
				NewToastComponent({
					status: "success",
					title: "Pedido reprocessado!",
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			})
			.catch(() => {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				setIsUpdatingStatus(false);
			});
	};

	const updateOrderStatus = async (
		newStatus: string,
		body: { tryToChangeSeller?: boolean; cancellationReason?: string } | null
	): Promise<void> => {
		if (!newStatus) return;
		setIsUpdatingStatus(true);
		try {
			const success = await cpCommerceManagementApi.updateOrderStatus(
				routeParams.id,
				newStatus,
				body
			);
			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				setIsUpdatingStatus(false);
				return;
			}

			const canceledStatuses = [
				"CanceledByAdmin",
				"CanceledBySeller",
				"CanceledByBuyer",
			];

			NewToastComponent({
				status: "success",
				title: canceledStatuses.includes(newStatus)
					? "Status cancelado com sucesso!"
					: "Status alterado com sucesso!",
			});
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsUpdatingStatus(false);
		}
	};

	const onActionButtonClick = (buttonEndpoint: string) => {
		setStatusToUpdateTo(buttonEndpoint);
		const canceledEndpoints = [
			LegacyOrderStatus[LegacyOrderStatus.CanceledByAdmin],
			LegacyOrderStatus[LegacyOrderStatus.CanceledByBuyer],
			LegacyOrderStatus[LegacyOrderStatus.CanceledByProcessor],
			LegacyOrderStatus[LegacyOrderStatus.CanceledBySeller],
		];
		if (canceledEndpoints.includes(buttonEndpoint)) {
			setIsCancellationDialogOpen(true);
			return;
		}
		if (buttonEndpoint === "InvoiceCreated" && !order?.invoice?.number) {
			setIsInvoiceDialogOpen(true);
			return;
		}
		if (buttonEndpoint === "Finalized") setIsDeliveryReceiptDialogOpen(true);
		else if (buttonEndpoint === "Separate" && enableOrderPicking)
			setIsOrderCheckDialogOpen(true);
		else setIsOrderStatusChangeOpen(true);
	};

	const onSubmitPicking = async (pickingData: PickingModel) => {
		setIsUpdatingStatus(true);
		try {
			const success = await cpCommerceManagementApi.upsertPicking(
				routeParams.id,
				pickingData
			);
			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				setIsUpdatingStatus(false);
				return;
			}

			updateOrderStatus("Separate", null);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
			setIsUpdatingStatus(false);
		}
	};

	return {
		order,
		isLoadingOrder,
		statusToUpdateTo,
		setStatusToUpdateTo,
		isUpdatingStatus,
		isHistoryOpen,
		setIsHistoryOpen,
		isDeliveryReceiptDialogOpen,
		setIsDeliveryReceiptDialogOpen,
		isOrderCheckDialogOpen,
		setIsOrderCheckDialogOpen,
		isOrderStatusChangeOpen,
		setIsOrderStatusChangeOpen,
		isInvoiceDialogOpen,
		setIsInvoiceDialogOpen,
		isCancellationDialogOpen,
		setIsCancellationDialogOpen,
		updateOrderCallback,
		onActionButtonClick,
		onSubmitPicking,
		reprocessOrder,
		updateOrderStatus,
	};
}
