import { ShowOrderCodeType } from "../../domain/enums/ShowOrderCodeType";

export const showOrderCode = (
	friendlyCode: string,
	externalId?: string | null,
	sellerOrderId?: string | null
): string => {
	const configurations = localStorage.getItem("configurations");
	if (!configurations) return friendlyCode;
	const orderCodeType: ShowOrderCodeType =
		JSON.parse(configurations)[0].showOrderCodeType;

	switch (orderCodeType) {
		case ShowOrderCodeType.ExternalId:
			return externalId ?? friendlyCode;
		case ShowOrderCodeType.SellerOrderId:
			if (!sellerOrderId && externalId) return externalId;
			return sellerOrderId ?? friendlyCode;
		case ShowOrderCodeType.FriendlyCode:
		default:
			return friendlyCode;
	}
};

export const getEstimateDate = (date: NullishString): string | null => {
	if (!date) return null;
	const defaultEstimateDate = "0001-01-01T00:00:00";
	return date === defaultEstimateDate ? null : date;
};

export const showProductDisplayCodeName = (
	productCode: NullishString,
	productReferenceCode: NullishString,
	productAuxCode: NullishString
) => {
	const configurations = localStorage.getItem("configurations");
	if (!configurations) return "";
	const orderCodeType: string =
		JSON.parse(configurations)[0].productDisplayCodeName;

	switch (orderCodeType) {
		case "Code":
			return productCode ?? "";
		case "ReferenceCode":
			return productReferenceCode ?? "";
		case "AuxCode":
			return productAuxCode ?? "";
		default:
			return "";
	}
};
