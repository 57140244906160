export enum PaymentMethodType {
	None = 0,
	CreditCard = 1,
	PickupStore = 2,
	BankSlip = 3,
	Voucher = 4,
	DebitCard = 5,
	Pix = 6,
	PaymentLink = 7,
	InstallmentPix = 8,
	Cashback = 9,
	TerminalCreditCard = 10,
	TerminalDebitCard = 11,
	Invoice = 12,
}
