import React from "react";
import "react-widgets/dist/css/react-widgets.css";
import Loading from "../../../components/Loading";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Paper,
	Stack,
	Tooltip,
	Zoom,
} from "@mui/material";
import CustomInput from "../../../MUICustomComponents/CustomInput";
import { HelpOutlineOutlined } from "@mui/icons-material";
import CustomSelect from "../../../MUICustomComponents/CustomSelect";
import CustomMaskInput from "../../../MUICustomComponents/CustomMaskInput";
import CancelLink from "../../../components/CancelLink";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import CustomIcon from "../../../MUICustomComponents/CustomIcon";
import SalesPersonFormService from "./SalesPersonFormService";
import { i18n } from "../../../translate/i18n";
import { SalesPersonInputs } from "../enums/SalesPersonInputs";
import { DocumentType } from "../../../domain/enums/DocumentType";
import { FormValidator } from "../../../infrastructure/utils/FormUtils";
import SellerAutocomplete from "../../../components/SellerAutocomplete/SellerAutocomplete";
import AccordionTable from "../../../components/AccordionTable/AccordionTable";
import { InputMasks } from "../../../infrastructure/utils/InputMasks";

export default function SalesPersonForm() {
	const {
		// From context
		salesPerson,
		isLoading,
		// Props
		salesPersonRoles,
		documentTypeOptions,
		defaultChannelOptions,
		configurations,
		currentSeller,
		setCurrentSeller,
		currentDocumentType,
		formErrors,
		// Functions
		handleSubmit,
		handleChange,
		handleSelectChange,
		handlePercentageChange,
		handleAutocompleteChange,
		addSalesChannelId,
		updateSalesChannelId,
		removeSalesChannelId,
		findEqualSalesChannel,
	} = SalesPersonFormService();

	const showDocumentInput = (documentType: DocumentType): JSX.Element => {
		const mask =
			documentType !== DocumentType.None ? InputMasks[documentType] : "";
		return (
			<CustomMaskInput
				name={SalesPersonInputs.Document}
				title={i18n.t("salesPersons.Field.Document")}
				value={salesPerson.document ?? ""}
				onChange={handleChange}
				mask={mask}
				disabled={!!salesPerson.id}
				error={FormValidator.hasInputError(
					formErrors,
					SalesPersonInputs.Document
				)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					SalesPersonInputs.Document
				)}
			/>
		);
	};

	return (
		<Paper variant="customPaper">
			{isLoading ? (
				<Loading />
			) : (
				<>
					<CustomTypography variant="secondaryTitle" marginBottom={2}>
						{i18n.t("salesPersons.BasicInfo").toString()}
					</CustomTypography>
					<Grid container spacing={2} mb={2}>
						<Grid item xs={3}>
							<CustomInput
								name={SalesPersonInputs.Code}
								title={i18n.t("salesPersons.Field.Code")}
								placeholder={i18n.t("salesPersons.Placeholder.Code")}
								value={salesPerson.code ?? ""}
								onChange={handleChange}
								error={FormValidator.hasInputError(
									formErrors,
									SalesPersonInputs.Code
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									SalesPersonInputs.Code
								)}
							/>
						</Grid>
						<Grid item xs={9}>
							<CustomInput
								name={SalesPersonInputs.FullName}
								title={i18n.t("salesPersons.Field.FullName")}
								placeholder={i18n.t("salesPersons.Placeholder.FullName")}
								value={salesPerson.fullName ?? ""}
								onChange={handleChange}
								error={FormValidator.hasInputError(
									formErrors,
									SalesPersonInputs.FullName
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									SalesPersonInputs.FullName
								)}
							/>
						</Grid>
						<Grid item xs={3}>
							<CustomSelect
								id={SalesPersonInputs.DocumentType}
								label={i18n.t("salesPersons.Field.DocumentType")}
								value={currentDocumentType}
								isDisabled={!!salesPerson.id}
								options={documentTypeOptions}
								onChange={handleSelectChange}
							/>
						</Grid>
						<Grid item xs={6}>
							{showDocumentInput(currentDocumentType)}
						</Grid>
						<Grid item xs={3}>
							<CustomSelect
								id={SalesPersonInputs.Role}
								label={i18n.t("salesPersons.Field.Role")}
								value={salesPerson.role}
								options={salesPersonRoles}
								onChange={handleSelectChange}
								error={FormValidator.hasInputError(
									formErrors,
									SalesPersonInputs.Role
								)}
								helperText={FormValidator.translateHelperTextList(
									formErrors,
									SalesPersonInputs.Role
								)}
							/>
						</Grid>
						{localStorage.getItem("role") === "2" && (
							<Grid item xs={12}>
								<SellerAutocomplete
									sellerCode={salesPerson.storeDocument}
									currentSeller={currentSeller}
									setCurrentSeller={setCurrentSeller}
									handleAutocompleteChange={handleAutocompleteChange}
									id={SalesPersonInputs.StoreDocument}
									error={FormValidator.hasInputError(
										formErrors,
										SalesPersonInputs.StoreDocument
									)}
									helperText={FormValidator.translateHelperTextList(
										formErrors,
										SalesPersonInputs.StoreDocument
									)}
								/>
							</Grid>
						)}
					</Grid>
					{configurations?.hasSplitPayment &&
						configurations?.useDealerCommission && (
							<Box mb={2}>
								<CustomTypography variant="secondaryTitle" marginBottom={2}>
									{i18n.t("salesPersons.FinancialInfo").toString()}
								</CustomTypography>
								<Grid container spacing={2} alignItems="center">
									<Grid
										item
										container
										spacing={2}
										xs={12}
										md={7.5}
										alignItems="center"
									>
										<Grid item xs={7.5} p={0}>
											<CustomInput
												name={SalesPersonInputs.GatewayId}
												title={i18n.t("salesPersons.Field.GatewayId")}
												value={salesPerson.gatewayId ?? ""}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={4.5} p={0}>
											<FormControlLabel
												control={
													<Checkbox
														name={SalesPersonInputs.UseDefaultCommission}
														checked={salesPerson.useDefaultCommission}
														onChange={handleChange}
													/>
												}
												label={
													<CustomTypography variant="default">
														{i18n
															.t("salesPersons.Field.UseDefaultCommission")
															.toString()}{" "}
														{configurations?.percentageSplit !== null && (
															<Tooltip
																arrow
																placement="top"
																TransitionComponent={Zoom}
																title={i18n
																	.t("salesPersons.DefaultCommissionPercentage")
																	.replace(
																		"{X}",
																		configurations.percentageSplit
																	)}
															>
																<i>
																	<CustomIcon
																		icon={<HelpOutlineOutlined />}
																		variant="small"
																	/>
																</i>
															</Tooltip>
														)}
													</CustomTypography>
												}
											/>
										</Grid>
									</Grid>

									{!salesPerson.useDefaultCommission && (
										<Grid item xs={3} md={1.5}>
											<CustomInput
												name={SalesPersonInputs.CommissionPercentage}
												title={i18n.t(
													"salesPersons.Field.CommissionPercentage"
												)}
												type="number"
												value={salesPerson.commissionPercentage}
												onChange={handlePercentageChange}
												endAdornment="%"
											/>
										</Grid>
									)}
								</Grid>
							</Box>
						)}
					<CustomTypography variant="secondaryTitle" marginBottom={2}>
						{i18n.t("salesPersons.SalesChannels").toString()}
					</CustomTypography>
					<AccordionTable
						title={i18n.t("salesPersons.SalesChannelIdentifiers")}
						dataArray={salesPerson.salesChannels}
						tableDisplayKey={"code"}
						componentTexts={{
							addInputTitle: i18n.t(
								"salesPersons.Field.AddNewSalesChannelIdentifier"
							),
							addButtonText: i18n.t("salesPersons.Button.AddNew"),
							editInputTitle: i18n.t(
								"salesPersons.Field.EditSalesChannelIdentifier"
							),
						}}
						addNewData={addSalesChannelId}
						updateData={updateSalesChannelId}
						removeData={removeSalesChannelId}
						dataSelectConfig={{
							options: defaultChannelOptions,
							label: i18n.t("salesPersons.Field.AddNewChannel"),
							findEqualSelect: findEqualSalesChannel,
							displayKey: "channel",
						}}
					/>
					<Stack direction="row" justifyContent="end" mt={3} gap={2}>
						<CancelLink to={"/salespeople/1"} />
						<Button color="success" variant="contained" onClick={handleSubmit}>
							{i18n
								.t(
									`salesPersons.Button.${
										salesPerson.id ? "UpdateSalesPerson" : "CreateSalesPerson"
									}`
								)
								.toString()}
						</Button>
					</Stack>
				</>
			)}
		</Paper>
	);
}
