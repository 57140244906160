import { Box, Stack } from "@mui/material";
import React from "react";
import CustomTypography from "../../../MUICustomComponents/CustomTypography";
import { OrderModel } from "../../../domain/models/commerce/OrderModel";
import CustomSkeleton from "../../../MUICustomComponents/CustomSkeleton";
import TotalSummary from "./TotalSummary";
import { i18n } from "../../../translate/i18n";

type Props = { order: OrderModel | null; isLoading: boolean };

export default function OrderTotal({ order, isLoading }: Props) {
	return (
		<Box className="card" p={3}>
			<CustomTypography variant="secondaryTitle" isBold marginBottom={2}>
				{i18n.t("orderTotal.OrderTotal").toString()}
			</CustomTypography>
			{isLoading || !order ? (
				<CustomSkeleton quantity={5} height={20} />
			) : (
				<Stack direction="column" spacing={1}>
					<TotalSummary order={order} isFullWidth />
				</Stack>
			)}
		</Box>
	);
}
