export function formatPhoneNumber(phoneNumber) {
	const maskPhoneNumber = /(\d{2})(\d{5})(\d{4})/;

	if (phoneNumber.length === 11) {
		return phoneNumber?.replace(maskPhoneNumber, "($1) $2-$3");
	} else {
		return phoneNumber;
	}
}

export function formatDocument(documentType, document) {
	const maskCpf = /(\d{3})(\d{3})(\d{3})(\d{2})/;
	const maskCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;

	if (documentType === "Cpf") {
		return document?.replace(maskCpf, "$1.$2.$3-$4");
	}
	if (documentType === "Cnpj") {
		return document?.replace(maskCnpj, "$1.$2.$3/$4-$5");
	}
}

export function formatDocumentCnpj(document) {
	const maskCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
	return document?.replace(maskCnpj, "$1.$2.$3/$4-$5");
}

export function formatDocumentCpf(document) {
	const maskCpf = /(\d{3})(\d{3})(\d{3})(\d{2})/;
	return document?.replace(maskCpf, "$1.$2.$3-$4");
}

export function formatDate(date, hideSeconds = false) {
	if (!hideSeconds) return new Date(date)?.toLocaleString("pt-br");

	const options = {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	};
	return new Date(date)?.toLocaleString("pt-br", options);
}

export function formatDateStringOnly(date) {
	const d = new Date(date);
	const day = d.toLocaleDateString("pt-br", { day: "2-digit" });
	const month = d.toLocaleDateString("pt-br", { month: "2-digit" });
	const year = d.getFullYear().toString().padStart(4, "0");
	return `${day}/${month}/${year}`;
}

export function formatZipCode(zipCode) {
	const maskZipCode = /(\d{2})(\d{3})(\d{3})/;
	return zipCode?.replace(/\D+/g, "")?.replace(maskZipCode, "$1.$2-$3");
}

export function formatDateUTC(date) {
	const formatedDate = date
		?.replace(/\D+/g, "")
		?.replace(
			/(\d{2})(\d{2})(\d{4})/,
			(match, groupOne, groupTwo, groupThree) => {
				const day = String(groupOne);
				const month = String(groupTwo);
				const year = String(groupThree);
				return `${year}-${month}-${day}`;
			}
		);
	return new Date(formatedDate)?.toISOString();
}

export function addEllipsisToString(text, maxLength) {
	if (!text) return;
	if (text.length > maxLength)
		return `${text.substring(0, maxLength).trim()}...`;
	return text;
}
export function addMonetaryFormatting(value) {
	if (!value) return "0,00";
	let newValue = value.toString();

	let isNegative = false;
	if (newValue.includes("-")) {
		isNegative = true;
		newValue = newValue.replace("-", "");
	}
	while (newValue.length < 3) {
		newValue = "0" + newValue;
	}
	if (isNegative) newValue = "-" + newValue;
	return newValue.replace(/(\d)(\d{2})$/, "$1,$2");
}
