import { RetailerConfiguration } from "../../domain/dtos/retailerManagement/retailerConfigurations/RetailerConfiguration";

export function getRetailerConfiguration(): RetailerConfiguration | null {
	try {
		const unparsedConfigurations = localStorage.getItem("configurations");
		if (unparsedConfigurations) {
			const parsedConfigurations = JSON.parse(unparsedConfigurations);
			if (
				Array.isArray(parsedConfigurations) &&
				parsedConfigurations.length > 0
			) {
				return parsedConfigurations[0] as RetailerConfiguration;
			}
		}
	} catch {
		return null;
	}

	return null;
}
