import React, { Dispatch, SetStateAction } from "react";
import CustomDialog from "../../../../MUICustomComponents/CustomDialog";
import { i18n } from "../../../../translate/i18n";
import { Box, Grid, Typography } from "@mui/material";
import BuyerEditDialogService from "./BuyerEditDialogService";
import { OrderModel } from "../../../../domain/models/commerce/OrderModel";
import FixedAlertComponent from "../../../../components/FixedAlertComponent/FixedAlertComponent";
import CustomInput from "../../../../MUICustomComponents/CustomInput";
import { BuyerInputs } from "./BuyerInputs";
import ZipCodeInput from "../../../../components/ZipCodeinput/ZipCodeInput";
import StateAutocomplete from "../../../../components/StateAutocomplete/StateAutocomplete";
import CustomSelect from "../../../../MUICustomComponents/CustomSelect";
import {
	DocumentType,
	NumericDocumentType,
} from "../../../../domain/enums/DocumentType";
import { InputMasks } from "../../../../infrastructure/utils/InputMasks";
import { countryMaskRules } from "../../../../components/InternationalPhoneInput/CountryMaskRules";
import CustomScrollbar from "../../../../MUICustomComponents/CustomScrollbar";
import { FormValidator } from "../../../../infrastructure/utils/FormUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Props = {
	orderModel: OrderModel;
	isDialogOpen: boolean;
	setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
	updateCallback: Function;
};

export default function BuyerEditDialog({
	orderModel,
	isDialogOpen,
	setIsDialogOpen,
	updateCallback,
}: Props) {
	const {
		buyerFormData,
		dateOfBirth,
		currentState,
		setCurrentState,
		isLoadingUpdate,
		isLoadingZipCode,
		setIsLoadingZipCode,
		formErrors,
		handleSelectChange,
		handleOnChange,
		onChangeDate,
		handleZipCodeChange,
		handleAutocompleteChange,
		onCloseCallback,
		handleSubmit,
	} = BuyerEditDialogService(orderModel, updateCallback, setIsDialogOpen);

	const documentTypeOptions = [
		{
			label: i18n.t(`dialogBuyerEdit.${DocumentType.Cpf}`),
			value: NumericDocumentType.Cpf,
		},
		{
			label: i18n.t(`dialogBuyerEdit.${DocumentType.Cnpj}`),
			value: NumericDocumentType.Cnpj,
		},
	];

	const renderInputField = (fieldName: BuyerInputs) => {
		return (
			<CustomInput
				name={fieldName}
				title={i18n.t(`dialogBuyerEdit.${fieldName}`)}
				placeholder={i18n.t(`dialogBuyerEdit.${fieldName}`)}
				value={buyerFormData[fieldName] ?? ""}
				onChange={handleOnChange}
				disabled={isLoadingUpdate || isLoadingZipCode}
				error={FormValidator.hasInputError(formErrors, fieldName)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					fieldName
				)}
			/>
		);
	};

	const renderDocumentInput = (): JSX.Element => {
		return (
			<CustomInput
				name={BuyerInputs.Document}
				title={i18n.t(`dialogBuyerEdit.${BuyerInputs.Document}`)}
				{...(buyerFormData.documentType === NumericDocumentType.Cpf && {
					applyInputMask: InputMasks.Cpf,
				})}
				{...(buyerFormData.documentType === NumericDocumentType.Cnpj && {
					applyInputMask: InputMasks.Cnpj,
				})}
				value={buyerFormData.document}
				onChange={handleOnChange}
				disabled={
					isLoadingUpdate ||
					isLoadingZipCode ||
					buyerFormData.documentType === NumericDocumentType.None
				}
				error={FormValidator.hasInputError(formErrors, BuyerInputs.Document)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					BuyerInputs.Document
				)}
			/>
		);
	};

	const renderPhoneInput = (): JSX.Element => {
		return (
			<CustomInput
				name={BuyerInputs.PhoneNumber}
				title={i18n.t(`dialogBuyerEdit.${BuyerInputs.PhoneNumber}`)}
				applyInputMask={countryMaskRules.BR.maskByLengthRule(
					buyerFormData.phoneNumber
				)}
				value={buyerFormData.phoneNumber}
				onChange={handleOnChange}
				disabled={isLoadingUpdate || isLoadingZipCode}
				error={FormValidator.hasInputError(formErrors, BuyerInputs.PhoneNumber)}
				helperText={FormValidator.translateHelperTextList(
					formErrors,
					BuyerInputs.PhoneNumber
				)}
			/>
		);
	};

	const clientForm = () => {
		return (
			<CustomScrollbar minHeight="0px" maxHeight="60vh">
				<Grid container spacing={2} pr={"18px"}>
					<Grid item xs={12}>
						<Typography fontSize={16} sx={{ fontWeight: "bold" }}>
							{i18n.t("dialogBuyerEdit.BuyerData").toString()}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{renderInputField(BuyerInputs.FullName)}
					</Grid>
					<Grid item xs={4}>
						<CustomSelect
							id={BuyerInputs.DocumentType}
							label={i18n.t(`dialogBuyerEdit.${BuyerInputs.DocumentType}`)}
							value={buyerFormData.documentType}
							isDisabled={isLoadingUpdate || isLoadingZipCode}
							options={documentTypeOptions}
							onChange={handleSelectChange}
						/>
					</Grid>
					<Grid item xs={8}>
						{renderDocumentInput()}
					</Grid>
					<Grid item xs={6}>
						{renderInputField(BuyerInputs.Email)}
					</Grid>
					<Grid item xs={6}>
						{renderPhoneInput()}
					</Grid>
					<Grid item xs={12}>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={"pt-br"}
						>
							<DatePicker
								label={i18n
									.t(`dialogBuyerEdit.${BuyerInputs.DateOfBirth}`)
									.toString()}
								value={dateOfBirth}
								onChange={onChangeDate}
								disabled={isLoadingUpdate}
								sx={{
									width: "100%",
									".MuiInputBase-formControl": { height: "37px" },
								}}
							/>
						</LocalizationProvider>
					</Grid>
					{buyerFormData.documentType === NumericDocumentType.Cnpj && (
						<>
							<Grid item xs={6}>
								{renderInputField(BuyerInputs.CompanyName)}
							</Grid>
							<Grid item xs={6}>
								{renderInputField(BuyerInputs.StateInscription)}
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<Typography fontSize={16} sx={{ fontWeight: "bold" }}>
							{i18n.t("dialogBuyerEdit.BuyerAddress").toString()}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<ZipCodeInput
							inputName={BuyerInputs.ZipCode}
							zipCode={buyerFormData.zipCode}
							isLoadingZipCode={isLoadingZipCode}
							setIsLoadingZipCode={setIsLoadingZipCode}
							handleZipCodeChange={handleZipCodeChange}
							formErrorText={FormValidator.translateHelperTextList(
								formErrors,
								BuyerInputs.ZipCode
							)}
						/>
					</Grid>
					<Grid item xs={8}>
						{renderInputField(BuyerInputs.Street)}
					</Grid>
					<Grid item xs={3}>
						{renderInputField(BuyerInputs.Number)}
					</Grid>
					<Grid item xs={3}>
						{renderInputField(BuyerInputs.Complement)}
					</Grid>
					<Grid item xs={6}>
						{renderInputField(BuyerInputs.Neighborhood)}
					</Grid>
					<Grid item xs={12}>
						{renderInputField(BuyerInputs.Reference)}
					</Grid>
					<Grid item xs={6}>
						{renderInputField(BuyerInputs.City)}
					</Grid>
					<Grid item xs={6}>
						<StateAutocomplete
							id={BuyerInputs.State}
							currentState={currentState}
							setCurrentState={setCurrentState}
							handleAutocompleteChange={handleAutocompleteChange}
							stateCode={buyerFormData.state}
							disabled={isLoadingUpdate || isLoadingZipCode}
							error={FormValidator.hasInputError(formErrors, BuyerInputs.State)}
							helperText={FormValidator.translateHelperTextList(
								formErrors,
								BuyerInputs.State
							)}
						/>
					</Grid>
				</Grid>
			</CustomScrollbar>
		);
	};

	return (
		<CustomDialog
			isOpen={isDialogOpen}
			setIsOpen={setIsDialogOpen}
			closeCallback={onCloseCallback}
			dialogInfo={{
				title: i18n.t("dialogBuyerEdit.Title"),
			}}
			actionButton={{
				text: i18n.t(`buttons.General.BtnSave`),
				variant: "contained",
				color: "success",
				onClick: handleSubmit,
				isDisabled:
					isLoadingUpdate ||
					isLoadingZipCode ||
					FormValidator.hasError(formErrors),
				isLoading: isLoadingUpdate,
			}}
			dialogStyles={{
				titleVariant: "title",
				dialogTitleStyles: { pt: 2 },
				actionStyles: { pb: 2 },
				contentStyles: { pr: 0 },
			}}
		>
			{clientForm()}
			<Box pr={3} pt={2}>
				<FixedAlertComponent alertType={"warning"}>
					<Box>
						<ul style={{ margin: 0, fontSize: "8px", paddingLeft: "20px" }}>
							<li>
								<Typography fontSize={12}>
									{i18n.t("dialogBuyerEdit.OrderAlreadyInvoiced").toString()}
								</Typography>
							</li>
							<li>
								<Typography fontSize={12}>
									{i18n
										.t("dialogBuyerEdit.PostageLabelAlreadyGenerated")
										.toString()}
								</Typography>
							</li>
						</ul>
					</Box>
				</FixedAlertComponent>
			</Box>
		</CustomDialog>
	);
}
