import creditcard from "../../../assets/payments-flags/credit_card.svg";
import mastercard from "../../../assets/payments-flags/icon-master-24px.svg";
import visa from "../../../assets/payments-flags/icon-visa-24px.svg";
import elo from "../../../assets/payments-flags/icon-elo.svg";
import americanexpress from "../../../assets/payments-flags/icon-americanexpress-24px.svg";

export const getCardBrandIcon = (paymentBrand: string | null): string => {
	if (!paymentBrand) return creditcard;
	const brandIcons: { [key: string]: string } = {
		mastercard: mastercard,
		master: mastercard,
		visa: visa,
		hipercard: creditcard,
		elo: elo,
		amex: americanexpress,
		"american express": americanexpress,
		americanexpress: americanexpress,
		diners: creditcard,
		sorocred: creditcard,
	};
	return brandIcons[paymentBrand.toLowerCase()] || creditcard;
};
