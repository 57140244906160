import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { CpOrdersDto } from "../../domain/dtos/cpCommerceManagement/CpOrdersDto";
import { AddresseeUpdateModel } from "../../domain/models/commerce/AddresseeUpdateModel";
import { CustomDataModel } from "../../domain/models/commerce/CustomDataModel";
import { ActionLogModel } from "../../domain/models/commerce/ActionLogModel";
import { PickingModel } from "../../domain/models/commerce/PickingModel";
import { BuyerModel } from "../../domain/models/commerce/Buyer/BuyerModel";
import { LegacyOrderDto } from "../../domain/dtos/legacyCommerceManagement/LegacyOrderDto";
import { InvoiceModel } from "../../domain/models/commerce/InvoiceModel";

interface ICPCommerceManagement {
	getOrderById: (id: string) => Promise<LegacyOrderDto>;
	getOrders: (params: string) => Promise<CpOrdersDto>;
	updateAddressee: (
		orderId: string,
		data: AddresseeUpdateModel
	) => Promise<boolean>;
	upsertCustomData: (
		orderId: string,
		data: CustomDataModel[]
	) => Promise<boolean>;
	createActionLog: (
		orderId: string,
		data: Pick<ActionLogModel, "title" | "displayMessage">
	) => Promise<boolean>;
	updateOrderStatus: (
		orderId: string,
		newStatus: string,
		body: { tryToChangeSeller?: boolean; cancellationReason?: string } | null
	) => Promise<boolean>;
	upsertPicking: (
		orderId: string,
		pickingData: PickingModel
	) => Promise<boolean>;
	updateBuyer: (orderId: string, buyerData: BuyerModel) => Promise<boolean>;
	upsertInvoice: (
		orderId: string,
		invoiceData: { invoice: Omit<InvoiceModel, "totalAmount"> }
	) => Promise<boolean>;
}

class CPCommerceManagement extends BaseApi implements ICPCommerceManagement {
	async getOrderById(id: string): Promise<LegacyOrderDto> {
		let resp = await this.request<LegacyOrderDto>(
			`orders/${id}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get order");
		}

		return resp.data;
	}

	async getOrders(params: string): Promise<CpOrdersDto> {
		let resp = await this.request<CpOrdersDto>(
			`orders?${params}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get orders");
		}

		return resp.data;
	}

	async updateAddressee(
		orderId: string,
		data: AddresseeUpdateModel
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/deliveryAddress`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	async upsertCustomData(
		orderId: string,
		data: CustomDataModel[]
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/customData`,
			HttpMethod.Post,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	async createActionLog(
		orderId: string,
		data: Pick<ActionLogModel, "title" | "displayMessage">
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/actionLog`,
			HttpMethod.Post,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	async updateOrderStatus(
		orderId: string,
		newStatus: string,
		body: { tryToChangeSeller?: boolean; cancellationReason?: string } | null
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/status/${newStatus}`,
			HttpMethod.Put,
			body
		);

		return this.isResponseSuccessful(resp.status);
	}

	async upsertPicking(
		orderId: string,
		pickingData: PickingModel
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/picking`,
			HttpMethod.Post,
			pickingData
		);

		return this.isResponseSuccessful(resp.status);
	}

	async updateBuyer(orderId: string, buyerData: BuyerModel): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/buyer`,
			HttpMethod.Put,
			buyerData
		);

		return this.isResponseSuccessful(resp.status);
	}

	async upsertInvoice(
		orderId: string,
		invoiceData: { invoice: Omit<InvoiceModel, "totalAmount"> }
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/invoicedetails`,
			HttpMethod.Put,
			invoiceData
		);

		return this.isResponseSuccessful(resp.status);
	}
}

export const cpCommerceManagementApi: ICPCommerceManagement =
	new CPCommerceManagement("cp/management/commerce/");
